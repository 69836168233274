import { Reducer } from "redux";
import { ILinkSettingsState, LinkTypes } from "./interfaces";
import { reducerLinkCheckAvailabilityFailure, reducerLinkCheckAvailabilityRequest, reducerLinkCheckAvailabilitySuccess, reducerLinkSaveFeaturesFailure, reducerLinkSaveFeaturesRequest, reducerLinkSaveFeaturesSuccess, reducerLinkSaveOrderTypesFailure, reducerLinkSaveOrderTypesRequest, reducerLinkSaveOrderTypesSuccess, reducerLinkSaveSlugFailure, reducerLinkSaveSlugRequest, reducerLinkSaveSlugSuccess, reducerLinkSettingsFailure, reducerLinkSettingsRequest, reducerLinkSettingsSuccess } from "./reducerHandlers";

export const LINK_SETTINGS_INITIAL_STATE: ILinkSettingsState = {
  slug: "",
  isLoading: true,
  features: {
    delivered: false,
    finished: false,
    observations: false
  },
  orderTypes: {
    delivery: {
      active: false,
      time: 0
    },
    takeAway: {
      active: false,
      time: 0
    },
    table: {
      active: false,
      time: 0
    }
  }
}

const reducer: Reducer = (state = LINK_SETTINGS_INITIAL_STATE, action) => {
  switch (action.type) {
    // get link settings
    case LinkTypes.LINK_GET_SETTINGS_REQUEST:
      return reducerLinkSettingsRequest(state);
    case LinkTypes.LINK_GET_SETTINGS_SUCCESS:
      return reducerLinkSettingsSuccess(state, action.linkSettings);
    case LinkTypes.LINK_GET_SETTINGS_FAILURE:
      return reducerLinkSettingsFailure(state, action.error);

    // validate slug
    case LinkTypes.LINK_VALIDITY_SLUG_REQUEST:
      return reducerLinkCheckAvailabilityRequest(state);
    case LinkTypes.LINK_VALIDITY_SLUG_SUCCESS:
      return reducerLinkCheckAvailabilitySuccess(state);
    case LinkTypes.LINK_VALIDITY_SLUG_FAILURE:
      return reducerLinkCheckAvailabilityFailure(state, action.error);

    // save slug
    case LinkTypes.LINK_SAVE_SLUG_REQUEST:
      return reducerLinkSaveSlugRequest(state);
    case LinkTypes.LINK_SAVE_SLUG_SUCCESS:
      return reducerLinkSaveSlugSuccess(state, action.slug);
    case LinkTypes.LINK_SAVE_SLUG_FAILURE:
      return reducerLinkSaveSlugFailure(state, action.error);

    // save features
    case LinkTypes.LINK_SAVE_FEATURES_REQUEST:
      return reducerLinkSaveFeaturesRequest(state);
    case LinkTypes.LINK_SAVE_FEATURES_SUCCESS:
      return reducerLinkSaveFeaturesSuccess(state, action.features);
    case LinkTypes.LINK_SAVE_FEATURES_FAILURE:
      return reducerLinkSaveFeaturesFailure(state, action.error);

    // save order types
    case LinkTypes.LINK_SAVE_ORDER_TYPES_REQUEST:
      return reducerLinkSaveOrderTypesRequest(state);
    case LinkTypes.LINK_SAVE_ORDER_TYPES_SUCCESS:
      return reducerLinkSaveOrderTypesSuccess(state, action.orderTypes);
    case LinkTypes.LINK_SAVE_ORDER_TYPES_FAILURE:
      return reducerLinkSaveOrderTypesFailure(state, action.error);
    default:
      return state;
  }
}

export default reducer;
