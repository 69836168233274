export const ON_SUCCESS                 = "ON_SUCCESS";
export const ON_ERROR                   = "ON_ERROR";
export const ADD_CALLBACK               = "ADD_CALLBACK";
export const ON_CALLBACK                = "ON_CALLBACK";
export const REMOVE_CALLBACK            = "REMOVE_CALLBACK";
export const UPDATE_CALLBACK            = "UPDATE_CALLBACK";

export const onSuccess = (data) => {
    return {
        type: ON_SUCCESS,
        data
    }
}

export const onError = (data) => {
    return {
        type: ON_ERROR,
        data
    }
}

export const addCallback = (callback) => {
    return {
        type: ADD_CALLBACK,
        callback
    }
}

export const onCallback = (callback) => {
    return {
        type: ON_CALLBACK,
        callback
    }
}

export const removeCallback = (id) => {
    return {
        type: REMOVE_CALLBACK,
        id
    }
}

export const updateCallback = (callback) => {
    return {
        type: UPDATE_CALLBACK,
        callback
    }
}