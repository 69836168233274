import { SystemErrors } from "../..";
import { IOpeningHourDraft, IOpeningHours, OpeningHoursTypes } from "./interfaces";

// list request
export const actionOpeningHoursRequest = () => ({
  type: OpeningHoursTypes.OPENING_HOURS_REQUEST
})

export const actionOpeningHoursSuccess = (data: IOpeningHours[]) => ({
  type: OpeningHoursTypes.OPENING_HOURS_SUCCESS,
  data
})

export const actionOpeningHoursFailure = (error: SystemErrors) => ({
  type: OpeningHoursTypes.OPENING_HOURS_FAILURE,
  error
})

// start/stop editing
export const actionOpeningHoursStartEditing = (draft?: IOpeningHourDraft) => ({
  type: OpeningHoursTypes.OPENING_HOURS_START_EDITING,
  draft
})

export const actionOpeningHoursStopEditing = () => ({
  type: OpeningHoursTypes.OPENING_HOURS_STOP_EDITING
})

// toggle status
export const actionOpeningHoursToggleStatusRequest = (id: number) => ({
  type: OpeningHoursTypes.OPENING_HOURS_TOGGLING_STATUS_REQUEST,
  id
})

export const actionOpeningHoursToggleStatusSuccess = (data: IOpeningHours) => ({
  type: OpeningHoursTypes.OPENING_HOURS_TOGGLING_STATUS_SUCCESS,
  data
})

export const actionOpeningHoursToggleStatusFailure = (id: number, error: SystemErrors) => ({
  type: OpeningHoursTypes.OPENING_HOURS_TOGGLING_STATUS_FAILURE,
  id,
  error
})

// save
export const actionOpeningHoursSaveRequest = (draft: IOpeningHourDraft) => ({
  type: OpeningHoursTypes.OPENING_HOURS_SAVE_REQUEST,
  draft
})

export const actionOpeningHoursSaveSuccess = (data: IOpeningHours) => ({
  type: OpeningHoursTypes.OPENING_HOURS_SAVE_SUCCESS,
  data
})

export const actionOpeningHoursSaveFailure = (error: SystemErrors) => ({
  type: OpeningHoursTypes.OPENING_HOURS_SAVE_FAILURE,
  error
})

// delete
export const actionOpeningHoursDeleteRequest = (id: number) => ({
  type: OpeningHoursTypes.OPENING_HOURS_DELETE_REQUEST,
  id
})

export const actionOpeningHoursDeleteSuccess = (id: number) => ({
  type: OpeningHoursTypes.OPENING_HOURS_DELETE_SUCCESS,
  id
})

export const actionOpeningHoursDeleteFailure = (id: number, error: SystemErrors) => ({
  type: OpeningHoursTypes.OPENING_HOURS_DELETE_FAILURE,
  id,
  error
})

// set draft
export const actionOpeningHoursSetDraft = (draft: IOpeningHourDraft) => ({
  type: OpeningHoursTypes.OPENING_HOURS_SET_DRAFT,
  draft
})
