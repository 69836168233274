import { Reducer } from "redux";
import { IPaymentMethodsState, PaymentMethodsType } from "./interfaces";
import { reducerPaymentMethodToggleStatusFailure, reducerPaymentMethodToggleStatusRequest, reducerPaymentMethodToggleStatusSuccess } from "./reducerHandler";

export const PAYMENT_METHODS_INITIAL_STATE: IPaymentMethodsState = {
  data: [],
  isLoading: true,
}

const reducer: Reducer<IPaymentMethodsState> = (state = PAYMENT_METHODS_INITIAL_STATE, action) => {
  switch (action.type) {
    // list
    case PaymentMethodsType.PAYMENT_METHODS_REQUEST:
      return { ...state, isLoading: true, error: undefined };
    case PaymentMethodsType.PAYMENT_METHODS_SUCCESS:
      return { ...state, isLoading: false, data: action.paymentsMethods, error: undefined };
    case PaymentMethodsType.PAYMENT_METHODS_FAILURE:
      return { ...state, isLoading: false, error: action.error };

    // toggle status
    case PaymentMethodsType.PAYMENT_METHOD_TOGGLE_STATUS_REQUEST:
      return reducerPaymentMethodToggleStatusRequest(state, action.id);

    case PaymentMethodsType.PAYMENT_METHOD_TOGGLE_STATUS_SUCCESS:
      return reducerPaymentMethodToggleStatusSuccess(state, action.id, action.status);

    case PaymentMethodsType.PAYMENT_METHOD_TOGGLE_STATUS_FAILURE:
      return reducerPaymentMethodToggleStatusFailure(state, action.id, action.error);

    default:
      return state;
  }
}

export default reducer;
