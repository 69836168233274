import { SystemErrors } from "../..";

export enum OpeningHoursTypes {
  OPENING_HOURS_REQUEST = 'openingHours/REQUEST',
  OPENING_HOURS_SUCCESS = 'openingHours/SUCCESS',
  OPENING_HOURS_FAILURE = 'openingHours/FAILURE',

  OPENING_HOURS_START_EDITING = 'openingHours/START_EDITING',
  OPENING_HOURS_STOP_EDITING = 'openingHours/STOP_EDITING',

  OPENING_HOURS_TOGGLING_STATUS_REQUEST = 'openingHours/TOGGLING_STATUS_REQUEST',
  OPENING_HOURS_TOGGLING_STATUS_SUCCESS = 'openingHours/TOGGLING_STATUS_SUCCESS',
  OPENING_HOURS_TOGGLING_STATUS_FAILURE = 'openingHours/TOGGLING_STATUS_FAILURE',

  OPENING_HOURS_SET_DRAFT = 'openingHours/SET_DRAFT',

  OPENING_HOURS_SAVE_REQUEST = 'openingHours/SAVE_REQUEST',
  OPENING_HOURS_SAVE_SUCCESS = 'openingHours/SAVE_SUCCESS',
  OPENING_HOURS_SAVE_FAILURE = 'openingHours/SAVE_FAILURE',

  OPENING_HOURS_DELETE_REQUEST = 'openingHours/DELETE_REQUEST',
  OPENING_HOURS_DELETE_SUCCESS = 'openingHours/DELETE_SUCCESS',
  OPENING_HOURS_DELETE_FAILURE = 'openingHours/DELETE_FAILURE',
}

export interface IOpeningHours{
  id: number;
  week_day: number;
  opening: string;
  clousure: string;
  status: boolean;
  readonly date_created: string;
  readonly date_updated: string;
  isSaving?: boolean;
  isTogglingStatus?: boolean;
  isDeleting?: boolean;
  error?: SystemErrors;
}

export interface IOpeningHourDraft{
  id?: number;
  week_day?: number;
  opening?: string;
  clousure?: string;
  status?: boolean;
  date_created?: string;
  date_updated?: string;
  isSaving?: boolean;
  error?: SystemErrors;
}

export interface IOpeningHoursState {
  data: IOpeningHours[];
  isLoading: boolean;
  error?: SystemErrors;
  draft?: IOpeningHourDraft;
}


export interface IOpeningHoursResponse {
  status: boolean;
  data: IOpeningHours[];
  message?: string;
}

export interface IOpeningHoursToggleStatusResponse {
  status: boolean;
  data: IOpeningHours;
  message?: string;
}

export interface IOpeningHoursSaveResponse {
  status: boolean;
  data: IOpeningHours;
  message?: string;
}

export interface IOpeningHoursDeleteResponse{
  status: boolean;
  message?: string;
}
