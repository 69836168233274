import {toastr} from 'react-redux-toastr'

import * as UsersActions from '../actions/UsersActions';
import * as CallbacksActions from '../actions/CallbacksActions';

import UsersService from '../../services/UsersService';  

import {all, put, takeLatest, takeEvery} from 'redux-saga/effects';

// list
function* listAll(action) {
    let dataToSend = null;
    yield UsersService.list(action.filter)
    .then(result => {
        const { data } = result;
        if(data.status) {
            dataToSend = data.data;
        }
        else console.log(result)
    })
    .catch(err => {
        if(err.response && err.response.data){
            const data = err.response.data;
            
            toastr.error(data.msg);
        }
        else console.log(err)
    })
    if(dataToSend !== null) yield put(UsersActions.listResponse(dataToSend))
}

function* watchListAll(){
    yield takeLatest(UsersActions.USERS_LIST, listAll);
}


// store
function* store(action){
    let status = false;

    yield UsersService.store(action.user)
    .then(result => {
        status = result.data.status;
    })
    .catch(err => {
        if(err.response && err.response.data){
            const data = err.response.data;
            
            toastr.error(data.msg);
        }
    })
    
    let puts = [put(UsersActions.list())];

    if(action.callback !== null){
        if(status)
            puts.push(put(CallbacksActions.updateCallback({
                id: action.callback.id,
                status: true
            })))
        else    
            puts.push(put(CallbacksActions.removeCallback(action.callback.id)))

    }
    
    yield all(puts);
}

function* watchStore(){
    yield takeEvery(UsersActions.USERS_STORE, store);
}

// remove
function* remove(action){
    yield UsersService.remove(action.id)
    .then(result => {
        toastr.success('Usuário removido', {showCloseButton: true})
    })
    .catch(err => {
        if(err.response && err.response.data){
            const data = err.response.data;
            
            toastr.error(data.msg)
        }
    })
    
    yield put(UsersActions.list())
}

function* watchRemove(){
    yield takeEvery(UsersActions.USERS_REMOVE, remove);
}

export default function* UsersSaga(){
    yield all([
        watchListAll(),
        watchStore(),
        watchRemove()
    ])
}