import { AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { put, all, takeLatest } from "redux-saga/effects";
import { actionAppearanceFailure, actionAppearanceRemoveLogoFailure, actionAppearanceRemoveLogoSuccess, actionAppearanceSaveFailure, actionAppearanceSaveLogoFailure, actionAppearanceSaveLogoSuccess, actionAppearanceSaveSuccess, actionAppearanceSuccess } from "./actions";
import { AppearanceTypes, IAppearenceRemoveLogoResponse, IAppearenceResponse } from "./interfaces";
import { serviceAppearanceRemoveLogoRequest, serviceAppearanceRequest, serviceAppearanceSave, serviceAppearanceSaveLogo } from "./services";

// get appearance
function* sagaAppearanceRequest() {
  try{
    const appearanceResponse: AxiosResponse<IAppearenceResponse> = yield serviceAppearanceRequest();

    const response = appearanceResponse.data;

    if(response.status){
      yield put(actionAppearanceSuccess(response.data));
    }
    else {
      yield put(actionAppearanceFailure({
        message: response.message || "Erro inesperado ao buscar configurações de personalização",
      }))
    }

  }
  catch(error: any){
    yield put(actionAppearanceFailure({
      message: error?.response?.data?.message || "Erro inesperado ao buscar configurações de personalização",
    }))
  }
}

function* watchSagaAppearanceRequest() {
  yield takeLatest(AppearanceTypes.APPEARANCE_REQUEST, sagaAppearanceRequest);
}

// save appearance
function* sagaAppearanceSaveRequest(action: AnyAction) {
  try{
    const appearanceResponse: AxiosResponse<IAppearenceResponse> = yield serviceAppearanceSave(action.data);

    const response = appearanceResponse.data;

    if(response.status){
      yield put(actionAppearanceSaveSuccess(response.data));
    }
    else {
      yield put(actionAppearanceSaveFailure({
        message: response.message || "Erro inesperado ao salvar configurações de personalização",
      }))
    }

  }
  catch(error: any){
    yield put(actionAppearanceSaveFailure({
      message: error?.response?.data?.message || "Erro inesperado ao salvar configurações de personalização",
    }))
  }
}

function* watchSagaAppearanceSaveRequest() {
  yield takeLatest(AppearanceTypes.APPEARANCE_SAVE_REQUEST, sagaAppearanceSaveRequest);
}

// remove logo
function* sagaAppearanceRemoveLogoRequest(action: AnyAction) {
  try{
    const appearanceResponse: AxiosResponse<IAppearenceRemoveLogoResponse> = yield serviceAppearanceRemoveLogoRequest();

    const response = appearanceResponse.data;

    if(response.status){
      yield put(actionAppearanceRemoveLogoSuccess());
    }
    else {
      yield put(actionAppearanceRemoveLogoFailure({
        message: response.message || "Erro inesperado ao remover logo",
      }))
    }

  }
  catch(error: any){
    yield put(actionAppearanceRemoveLogoFailure({
      message: error?.response?.data?.message || "Erro inesperado ao remover logo",
    }))
  }
}

function* watchSagaAppearanceRemoveLogoRequest() {
  yield takeLatest(AppearanceTypes.APPEARANCE_REMOVE_LOGO_REQUEST, sagaAppearanceRemoveLogoRequest);
}

// save logo
function* sagaAppearanceSaveLogoRequest(action: AnyAction) {
  try{
    const appearanceResponse: AxiosResponse<IAppearenceRemoveLogoResponse> = yield serviceAppearanceSaveLogo(action.newLogo);

    const response = appearanceResponse.data;

    if(response.status){
      yield put(actionAppearanceSaveLogoSuccess());
    }
    else {
      yield put(actionAppearanceSaveLogoFailure({
        message: response.message || "Erro inesperado ao remover logo",
      }))
    }

  }
  catch(error: any){
    yield put(actionAppearanceSaveLogoFailure({
      message: error?.response?.data?.message || "Erro inesperado ao remover logo",
    }))
  }
}

function* watchSagaAppearancesaveLogoRequest() {
  yield takeLatest(AppearanceTypes.APPEARANCE_SAVE_LOGO_REQUEST, sagaAppearanceSaveLogoRequest);
}

export default all([
  watchSagaAppearanceRequest(),
  watchSagaAppearanceSaveRequest(),
  watchSagaAppearanceRemoveLogoRequest(),
  watchSagaAppearancesaveLogoRequest()
])
