import { AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { all, put, takeEvery, takeLatest } from "redux-saga/effects";
import { actionPaymentMethodsFailure, actionPaymentMethodsSuccess, actionPaymentMethodToggleStatusFailure, actionPaymentMethodToggleStatusSuccess } from "./actions";
import { IPaymentMethodResponse, IPaymentMethodToggleStatusResponse, PaymentMethodsType } from "./interfaces";
import { servicePaymentMethodsRequest, servicePaymentMethodsToggleStatusRequest } from "./services";

function* sagaPaymentMethodsRequest() {
  try {
    const response: AxiosResponse<IPaymentMethodResponse> = yield servicePaymentMethodsRequest();

    if(response.data.status){
      yield put(actionPaymentMethodsSuccess(response.data.data.map(payment => ({
        id: payment.id,
        name: payment.name,
        isActive: payment.is_active,
        type: payment.type,
        moneyChange: payment.money_change,
        icon: payment.icon,
      }))));
    }
    else {
      yield put(actionPaymentMethodsFailure({
        message: response.data.message || "Erro inesperado ao buscar métodos de pagamento",
      }))
    }
  }
  catch(error: any) {
    console.log(error);

    yield put(actionPaymentMethodsFailure({
      message: error?.response?.data?.message || "Erro inesperado ao buscar métodos de pagamento",
    }))
  }
}

function* watchSagaPaymentMethodsRequest() {
  yield takeLatest(PaymentMethodsType.PAYMENT_METHODS_REQUEST, sagaPaymentMethodsRequest);
}

function* sagaPaymentMethodsToggleStatusRequest(action: AnyAction) {
  try {
    const response: AxiosResponse<IPaymentMethodToggleStatusResponse> = yield servicePaymentMethodsToggleStatusRequest(action.id);

    if(response.data.status){
      yield put(actionPaymentMethodToggleStatusSuccess(response.data.data.id, response.data.data.is_active));
    }
    else {
      yield put(actionPaymentMethodToggleStatusFailure(action.id, {
        message: response.data.message || "Erro inesperado ao alterar status do método de pagamento",
      }))
    }
  }
  catch(error: any) {
    console.log(error);

    yield put(actionPaymentMethodToggleStatusFailure(action.id, {
      message: error?.response?.data?.message || "Erro inesperado ao alterar status do método de pagamento",
    }))
  }
}

function* watchSagaPaymentMethodsToggleStatusRequest() {
  yield takeEvery(PaymentMethodsType.PAYMENT_METHOD_TOGGLE_STATUS_REQUEST, sagaPaymentMethodsToggleStatusRequest);
}

export default all([
  watchSagaPaymentMethodsRequest(),
  watchSagaPaymentMethodsToggleStatusRequest()
])
