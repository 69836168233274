import { SystemErrors } from "../..";
import { ILinkSettings, ILinkSettingsState } from "./interfaces";

export const reducerLinkSettingsRequest = (state: ILinkSettingsState): ILinkSettingsState => {
  return {
    ...state,
    isLoading: true,
    error: undefined
  }
}

export const reducerLinkSettingsSuccess = (state: ILinkSettingsState, linkSettings: ILinkSettings): ILinkSettingsState => {
  return {
    ...state,
    isLoading: false,
    error: undefined,
    ...linkSettings
  }
}

export const reducerLinkSettingsFailure = (state: ILinkSettingsState, error: SystemErrors): ILinkSettingsState => {
  return {
    ...state,
    isLoading: false,
    error
  }
}

// slug availability
export const reducerLinkCheckAvailabilityRequest = (state: ILinkSettingsState): ILinkSettingsState => {
  return {
    ...state,
    isValidatingSlug: true,
    slugError: undefined
  }
}

export const reducerLinkCheckAvailabilitySuccess = (state: ILinkSettingsState): ILinkSettingsState => {
  return {
    ...state,
    isValidatingSlug: false,
    slugError: undefined,
    isSlugValid: true
  }
}

export const reducerLinkCheckAvailabilityFailure = (state: ILinkSettingsState, error: SystemErrors): ILinkSettingsState => {
  return {
    ...state,
    isValidatingSlug: false,
    slugError: error,
    isSlugValid: false
  }
}

// save slug
export const reducerLinkSaveSlugRequest = (state: ILinkSettingsState): ILinkSettingsState => {
  return {
    ...state,
    isSavingSlug: true,
    slugError: undefined
  }
}

export const reducerLinkSaveSlugSuccess = (state: ILinkSettingsState, slug: string): ILinkSettingsState => {
  return {
    ...state,
    isSavingSlug: false,
    slugError: undefined,
    slug
  }
}

export const reducerLinkSaveSlugFailure = (state: ILinkSettingsState, error: SystemErrors): ILinkSettingsState => {
  return {
    ...state,
    isSavingSlug: false,
    slugError: error
  }
}

// save features
export const reducerLinkSaveFeaturesRequest = (state: ILinkSettingsState): ILinkSettingsState => {
  return {
    ...state,
    isSavingFeatures: true,
    featuresError: undefined
  }
}

export const reducerLinkSaveFeaturesSuccess = (state: ILinkSettingsState, features: ILinkSettings['features']): ILinkSettingsState => {
  return {
    ...state,
    isSavingFeatures: false,
    featuresError: undefined,
    features
  }
}

export const reducerLinkSaveFeaturesFailure = (state: ILinkSettingsState, error: SystemErrors): ILinkSettingsState => {
  return {
    ...state,
    isSavingFeatures: false,
    featuresError: error
  }
}

// save orderTypes
export const reducerLinkSaveOrderTypesRequest = (state: ILinkSettingsState): ILinkSettingsState => {
  return {
    ...state,
    isSavingOrderTypes: true,
    orderTypesError: undefined
  }
}

export const reducerLinkSaveOrderTypesSuccess = (state: ILinkSettingsState, orderTypes: ILinkSettings['orderTypes']): ILinkSettingsState => {
  return {
    ...state,
    isSavingOrderTypes: false,
    orderTypesError: undefined,
    orderTypes
  }
}

export const reducerLinkSaveOrderTypesFailure = (state: ILinkSettingsState, error: SystemErrors): ILinkSettingsState => {
  return {
    ...state,
    isSavingOrderTypes: false,
    orderTypesError: error
  }
}
