import * as UsersActions from '../actions/UsersActions';
import auths from '../../services/auth';

const generateNavigation = () => {
    const nav = {
        items: []
    };

    nav.items.push({
        name: 'Relatórios',
        url: '/dashboard',
        icon: 'icon-speedometer',
    })


    if(auths.checkPermission('order_read')){
        nav.items.push({
                name: 'Listar pedidos',
                url: '/orders',
                icon: 'icon-magnifier'
            })
    }

    nav.items.push({
        title: true,
        name: 'Administração'
    })

    if(auths.checkPermission('users_read')){
        nav.items.push({
                name: 'Usuários',
                url: '/users',
                icon: 'icon-user'
            })
    }

    if(auths.checkAnyPermission(['products_store','products_categories_store'])){
        let childrens = []

        if(auths.checkPermission('products_store')){
            childrens.push({
                        name: 'Produtos',
                        url: '/products',
                        icon: 'fa fa-barcode'
                    })
        }

        if(auths.checkPermission('products_categories_store')){
            childrens.push({
                        name: 'Categorias',
                        url: '/products/categories',
                        icon: 'fa fa-object-group',
                    })
        }

        nav.items.push({
                name: 'Cardápio',
                url: '/cardapio',
                icon: 'fa fa-cutlery',
                // children: childrens
            })
    }

    if(auths.checkPermission('settings')){
        nav.items.push(
          {
            name: 'Horários',
            url: '/settings/openinghours',
            icon: 'fa fa-clock-o'
          },
          {
            name: 'Pagamentos',
            url: '/settings/paymentmethods',
            icon: 'fa fa-credit-card'
          },
          {
            name: 'Entregas',
            url: '/settings/delivery',
            icon: 'fa fa-truck'
          },
          {
            name: 'Aparência',
            url: '/settings/appearance',
            icon: 'fa fa-paint-brush'
          },
          {
            name: 'Link pedidos',
            url: '/settings/selfservice',
            icon: 'fa fa-link'
          })
    }

    return nav;
}

const AuthReducer = (auth = {}, action) => {
    switch(action.type){
        case UsersActions.USERS_NAVIGATION:
            return {
                ...auth,
                nav: generateNavigation(),
                user: auths.getUser()
            }

        default: return auth;
    }
}

export default  AuthReducer;
