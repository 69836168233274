import * as UsersActions from '../actions/UsersActions'

const UsersReducer = (usersList = [], action) => {
    switch(action.type){
        case UsersActions.USERS_LIST_RESPONSE:
            return action.usersList;

        default: return usersList;
    }
}

export default  UsersReducer;