import { AxiosResponse } from "axios"
import api from "../../services/api"
import auth from "../../services/auth"
import { IProductCategoriesRemoveResponse, IProductCategoriesRequestResponse, IProductCategoriesSaveResponse, IProductCategory, IProductCategoryOrderMap } from "./interfaces"

export const serviceProductCategoriesRequest = () => {
  return api.get<AxiosResponse<IProductCategoriesRequestResponse>,AxiosResponse>('productcategories/', {
    headers: {Authorization: `Bearer ${auth.getToken()}`}
})
}

export const serviceProductCategoriesSaveRequest = (category: IProductCategory) => {
  if(category.id){
    return api.put<AxiosResponse<IProductCategoriesSaveResponse>, AxiosResponse>(`productcategories/update/${category.id}`, {
      data: category
    }, {
      headers: { Authorization: `Bearer ${auth.getToken()}` }
    })
  }

  return api.post<AxiosResponse<IProductCategoriesSaveResponse>, AxiosResponse>(`productcategories/create`, {
    data: category
  }, {
    headers: { Authorization: `Bearer ${auth.getToken()}` }
  })
}

export const serviceProductCategoriesToggleStatusRequest = (category_id: number) => {
  return api.put<AxiosResponse<IProductCategoriesSaveResponse>, AxiosResponse>(`productcategories/togglestatus/${category_id}`, null, {
    headers: { Authorization: `Bearer ${auth.getToken()}` }
  })
}

export const serviceProductCategoryRemoveRequest = (category_id: number) => {
  return api.delete<AxiosResponse<IProductCategoriesRemoveResponse>,AxiosResponse>(`productcategories/delete/${category_id}`, {
    headers: {Authorization: `Bearer ${auth.getToken()}`}
})
}

export const serviceProductCategoryReorder = (data: IProductCategoryOrderMap[]) => {
  let ids = data.map(new_data => new_data.id);

  return api.put<AxiosResponse<IProductCategoriesRequestResponse>,AxiosResponse>(`productcategories/reorder`,{
    data: ids
  }, {
    headers: {Authorization: `Bearer ${auth.getToken()}`}
})
}
