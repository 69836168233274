import { SystemErrors } from "../..";
import { IPaymentMethod, PaymentMethodsType } from "./interfaces";

export const actionPaymentMethodsRequest = () => ({
  type: PaymentMethodsType.PAYMENT_METHODS_REQUEST
});

export const actionPaymentMethodsSuccess = (paymentsMethods: IPaymentMethod[]) => ({
  type: PaymentMethodsType.PAYMENT_METHODS_SUCCESS,
  paymentsMethods
});

export const actionPaymentMethodsFailure = (error: SystemErrors) => ({
  type: PaymentMethodsType.PAYMENT_METHODS_FAILURE,
  error
});

export const actionPaymentMethodToggleStatusRequest = (id: string) => ({
  type: PaymentMethodsType.PAYMENT_METHOD_TOGGLE_STATUS_REQUEST,
  id
});

export const actionPaymentMethodToggleStatusSuccess = (id: string, status: boolean) => ({
  type: PaymentMethodsType.PAYMENT_METHOD_TOGGLE_STATUS_SUCCESS,
  id,
  status
});

export const actionPaymentMethodToggleStatusFailure = (id:string, error: SystemErrors) => ({
  type: PaymentMethodsType.PAYMENT_METHOD_TOGGLE_STATUS_FAILURE,
  error,
  id
});
