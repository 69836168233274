import {combineReducers} from 'redux';

import OrdersReducer                        from './OrdersReducer';
import UsersReducer                         from './UsersReducer';
import ProductsReducer                      from '../../store/products';
import ProductsCategoriesReducer            from '../../store/productCategories';
import SystemReducer                        from '../../store/system';
import OpeningHoursReducer                  from '../../store/openingHours';
import DeliveryReducer                      from '../../store/delivery';
import AppearanceReducer                    from '../../store/appearance';
import LinkSettingsReducer                  from '../../store/link';
import PaymentsMethodsReducer               from '../../store/paymentsMethods';
import ProductsOptionalCategoriesReducer    from './ProductsOptionalCategoriesReducer';
import AuthReducer                          from './AuthReducer';
import SettingsReducer                      from './SettingsReducer';
import StatsReducer                         from './StatsReducer';
import Callbacks                            from './Callbacks';
import {reducer as toastrReducer}           from 'react-redux-toastr';

export default combineReducers({
    OrdersReducer,
    UsersReducer,
    toastr: toastrReducer,
    callbacks: Callbacks,
    // ProductsReducer,
    // productsCategories: ProductsCategoriesReducer,
    productsOptionalCategories: ProductsOptionalCategoriesReducer,
    auth: AuthReducer,
    settings: SettingsReducer,
    stats: StatsReducer,
    products: ProductsReducer,
    product_categories: ProductsCategoriesReducer,
    system: SystemReducer,
    openingHours: OpeningHoursReducer,
    delivery: DeliveryReducer,
    appearance: AppearanceReducer,
    linkSettings: LinkSettingsReducer,
    paymentsMethods: PaymentsMethodsReducer,
})
