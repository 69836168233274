import { AxiosResponse } from "axios"
import api from "../../services/api"
import auth from "../../services/auth"
import { IProductConfiguration, IProductConfigurationOptionDeleteResponse, IProductConfigurationOrderMap, IProductConfigurationRemoveResponse, IProductConfigurationSaveResponse, IProductConfigurationsReorderResponse, IProductDraft, IProductDuplicateResponse, IProductRemovePhotoResponse, IProductRemoveResponse, IProductSaveResponse, IProductsRequestResponse } from "./interfaces"

export const serviceProductsRequest = () => {
  return api.get<AxiosResponse<IProductsRequestResponse>, AxiosResponse>('products/', {
    headers: { Authorization: `Bearer ${auth.getToken()}` }
  })
}


export const serviceProductSave = (product: IProductDraft) => {

  if (product.id) {
    return api.put<AxiosResponse<IProductSaveResponse>, AxiosResponse>(`products/update/${product.id}`,
      {
        data: product
      },
      {
        headers: { Authorization: `Bearer ${auth.getToken()}` }
      })
  }
  else {
    return api.post<AxiosResponse<IProductSaveResponse>, AxiosResponse>('products/create',
      {
        data: product
      },
      {
        headers: { Authorization: `Bearer ${auth.getToken()}` }
      })
  }
}

export const serviceProductConfigurationsSave = (configuration: IProductConfiguration, product_id: number) => {

  if (configuration.id) {
    return api.put<AxiosResponse<IProductConfigurationSaveResponse>, AxiosResponse>(`productconfiguration/update/${configuration.id}`,
      {
        data: configuration
      },
      {
        headers: { Authorization: `Bearer ${auth.getToken()}` }
      })
  }
  else {
    return api.post<AxiosResponse<IProductConfigurationSaveResponse>, AxiosResponse>(`productconfiguration/create`,
      {
        data: configuration
      },
      {
        headers: { Authorization: `Bearer ${auth.getToken()}` }
      })
  }
}

export const serviceProductConfigurationRemove = (configuration_id: number) => {
  return api.delete<AxiosResponse<IProductConfigurationRemoveResponse>, AxiosResponse>(`productconfiguration/delete/${configuration_id}`,
    {
      headers: { Authorization: `Bearer ${auth.getToken()}` }
    })
}

export const serviceProductConfigurationOptionDelete = (option_id: number) => {
  return api.delete<AxiosResponse<IProductConfigurationOptionDeleteResponse>, AxiosResponse>(`productconfiguration/deleteoption/${option_id}`,
    {
      headers: { Authorization: `Bearer ${auth.getToken()}` }
    })
}

export const serviceProductRemove = (product_id: number) => {
  return api.delete<AxiosResponse<IProductRemoveResponse>, AxiosResponse>(`products/delete/${product_id}`,
    {
      headers: { Authorization: `Bearer ${auth.getToken()}` }
    })
}

export const serviceProductRemovePhoto = (product_id: number) => {
  return api.put<AxiosResponse<IProductRemovePhotoResponse>, AxiosResponse>(`products/removephoto/${product_id}`, null,
    {
      headers: { Authorization: `Bearer ${auth.getToken()}` }
    })
}

export const serviceProductToggleStatus = (product_id: number) => {
  return api.put<AxiosResponse<IProductRemovePhotoResponse>, AxiosResponse>(`products/togglestatus/${product_id}`, null,
    {
      headers: { Authorization: `Bearer ${auth.getToken()}` }
    })
}

export const serviceProductDuplicateSave = (product: IProductDraft) => {
  return api.post<AxiosResponse<IProductDuplicateResponse>, IProductDuplicateResponse>(`products/duplicate/${product.id}`,
    {
      data: product
    },
    {
      headers: { Authorization: `Bearer ${auth.getToken()}` }
    })
}

export const serviceProductConfigurationReorder = (data: IProductConfigurationOrderMap[], id: number) => {
  let ids = data.map(new_data => new_data.id);

  return api.put<AxiosResponse<IProductConfigurationsReorderResponse>,AxiosResponse>(`productconfiguration/reorderconfigurations/${id}`,{
    data: ids
  }, {
    headers: {Authorization: `Bearer ${auth.getToken()}`}
})
}
