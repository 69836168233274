import { serviceProductCategoriesRequest, serviceProductCategoriesSaveRequest, serviceProductCategoriesToggleStatusRequest, serviceProductCategoryRemoveRequest, serviceProductCategoryReorder } from "./services";
import { put, takeLatest, all, takeEvery } from 'redux-saga/effects'
import { actionProductCategoriesFailure, actionProductCategoriesRearrangingSaveFailure, actionProductCategoriesRearrangingSaveSuccess, actionProductCategoriesSuccess, actionProductCategoryToggleStatusFailure, actionProductCategoryToggleStatusSuccess, actionProductsCategoriesRemoveFailure, actionProductsCategoriesRemoveSuccess, actionProductsCategoriesSaveFailure, actionProductsCategoriesSaveSuccess, ProductCategoriesActions } from "./actions";
import { AnyAction } from "redux";
import { IProductCategoriesState } from "./interfaces";
import { selectState } from "../products/sagas";
import { IApplicationState } from "..";
import { SystemErrors } from "../..";

const getProductCategoriesState = (state: IApplicationState): IProductCategoriesState => state.product_categories;

function* sagaProductCategoriesRequest() {
  let data_response: any = false;
  let error: any = false;

  yield serviceProductCategoriesRequest()
    .then(({ data }) => {
      if (data.status) {
        if (Array.isArray(data.data)) {
          data_response = data.data
        }
        else data_response = [data.data];
      }
    })
    .catch(err => {
      if (err.response.data) {
        error = {
          message: err.response.data.message || "Erro inesperado ao carregar lista de categorias"
        }
      }
      else {
        error = {
          message: "Erro inesperado ao carregar lista de categorias"
        }
      }
    })

  if (data_response) yield put(actionProductCategoriesSuccess(data_response))
  if (error) yield put(actionProductCategoriesFailure(error));
}

function* watchProductCategoriesRequestSaga() {
  yield takeLatest(ProductCategoriesActions.PRODUCT_CATEGORIES_REQUEST, sagaProductCategoriesRequest);
}

// Save
function* sagaProductCategoriesSaveRequest(action: AnyAction) {
  let data_response: any = false;
  let error: SystemErrors | boolean = false;


  const productCategoriesState: IProductCategoriesState = yield selectState<IProductCategoriesState>(getProductCategoriesState);

  if (action.category !== null) {
    yield serviceProductCategoriesSaveRequest({
      ...action.category
    })
      .then(({ data }) => {
        if (data.status) {
          data_response = data.data;
        }
      })
      .catch(err => {
        if (err.response.data) {
          error = {
            message: err.response.data.message || "Erro inesperado ao salvar categoria"
          }
        }
        else {
          error = {
            message: "Erro inesperado ao salvar categoria"
          }
        }
      })

    if (data_response) yield put(actionProductsCategoriesSaveSuccess(data_response))
    if (error) yield put(actionProductsCategoriesSaveFailure(error, action.category.id));
  }
  else if (productCategoriesState.draft) {
    const { draft } = productCategoriesState;

    yield serviceProductCategoriesSaveRequest({
      name: draft.name,
      status: draft.status,
      order: productCategoriesState.data.length
    })
      .then(({ data }) => {
        if (data.status) {
          data_response = data.data;
        }
      })
      .catch(err => {
        if (err.response.data) {
          error = {
            message: err.response.data.message || "Erro inesperado ao salvar categoria"
          }
        }
        else {
          error = {
            message: "Erro inesperado ao salvar categoria"
          }
        }
      })

    if (data_response) yield put(actionProductsCategoriesSaveSuccess(data_response))
    if (error) yield put(actionProductsCategoriesSaveFailure(error));
  }

  else {
    error = {
      message: "Não existe nenhuma categoria sendo criada no momento"
    }

    yield put(actionProductsCategoriesSaveFailure(error));
  }
}

function* watchProductCategoriesSaveRequestSaga() {
  yield takeEvery(ProductCategoriesActions.PRODUCT_CATEGORIES_SAVE_REQUEST, sagaProductCategoriesSaveRequest);
}

// Save
function* sagaProductCategoriesRemoveRequest(action: AnyAction) {
  let data_response: any = false;
  let error: SystemErrors | boolean = false;

  yield serviceProductCategoryRemoveRequest(action.category_id)
    .then(({ data }) => {
      if (data.status) {
        data_response = true;
      }
    })
    .catch(err => {
      if (err.response.data) {
        error = {
          message: err.response.data.message || "Erro inesperado ao remover categoria"
        }
      }
      else {
        error = {
          message: "Erro inesperado ao remover categoria"
        }
      }
    })
  if (data_response) yield put(actionProductsCategoriesRemoveSuccess(action.category_id))
  if (error) yield put(actionProductsCategoriesRemoveFailure(error, action.category_id));

}

function* watchProductCategoriesRemoveRequestSaga() {
  yield takeEvery(ProductCategoriesActions.PRODUCT_CATEGORIES_REMOVE_REQUEST, sagaProductCategoriesRemoveRequest);
}


// Toggle Status
function* sagaProductCategoriesToggleStatusRequest(action: AnyAction) {
  let data_response: any = false;
  let error: any = false;

  yield serviceProductCategoriesToggleStatusRequest(action.category_id)
    .then(({ data }) => {
      if (data.status) {
        data_response = data.data
      }
    })
    .catch(err => {
      if (err.response.data) {
        error = {
          message: err.response.data.message || "Erro inesperado ao salvar categoria"
        }
      }
      else {
        error = {
          message: "Erro inesperado ao salvar categoria"
        }
      }
    })

  if (data_response) yield put(actionProductCategoryToggleStatusSuccess(data_response))
  if (error) yield put(actionProductCategoryToggleStatusFailure(action.category_id, error));
}

function* watchProductCategoriesToggleStatusRequestSaga() {
  yield takeEvery(ProductCategoriesActions.PRODUCT_CATEGORIES_TOGGLE_STATUS_REQUEST, sagaProductCategoriesToggleStatusRequest);
}

// Reorder
function* sagaProductCategoriesReorderSaveRequest(action: AnyAction) {
  let data_response: any = false;
  let error: any = false;

  const categoriesState: IProductCategoriesState = yield selectState<IProductCategoriesState>(getProductCategoriesState);

  if(categoriesState.rearranging){
    yield serviceProductCategoryReorder(categoriesState.rearranging.data)
      .then(({ data }) => {
        if (data.status) {
          data_response = data.data
        }
      })
      .catch(err => {
        if (err.response.data) {
          error = {
            message: err.response.data.message || "Erro inesperado ao salvar categoria"
          }
        }
        else {
          error = {
            message: "Erro inesperado ao salvar categoria"
          }
        }
      })

    if (data_response) yield put(actionProductCategoriesRearrangingSaveSuccess(data_response))
    if (error) yield put(actionProductCategoriesRearrangingSaveFailure(error));
  }
  else {
    yield put(actionProductCategoriesRearrangingSaveFailure({
      message: "Não foi possível reorganizar categorias. Atalize a página e tente novamente mais tarde!"
    }));
  }
}

function* watchProductCategoriesReorderRequestSaga() {
  yield takeLatest(ProductCategoriesActions.PRODUCT_CATEGORIES_REARRANGING_SAVE_REQUEST, sagaProductCategoriesReorderSaveRequest);
}

export default all([
  watchProductCategoriesRequestSaga(),
  watchProductCategoriesSaveRequestSaga(),
  watchProductCategoriesToggleStatusRequestSaga(),
  watchProductCategoriesRemoveRequestSaga(),
  watchProductCategoriesReorderRequestSaga()
])
