import * as StatsActions from '../actions/StatsActions'

const StatsReducer = (stats = {
    financial_history: [],
    financial: {},
    financialdetails: {},
    payment_methods: [],
    orders: {
        isLoading: false,
        error: null,
        data: {}
    }
}, action) => {
    switch(action.type){
        case StatsActions.STATS_FINANCIAL_RESPONSE:
            return {
                ...stats,
                financial: {
                    ...stats.financial,
                    ...action.financial
                }
            };
        case StatsActions.STATS_FINANCIAL_DETAILS_RESPONSE:
            return {
                ...stats,
                financialdetails: {
                    ...stats.financialdetails,
                    ...action.financialdetails
                }
            };
        case StatsActions.STATS_FINANCIAL_HISTORY_RESPONSE:
            return {
                ...stats,
                financial_history: [
                    ...action.history
                ]
            };
        case StatsActions.STATS_PAYMENT_METHODS_RESPONSE:
            return {
                ...stats,
                payment_methods: [
                    ...action.methods
                ]
            };

        case StatsActions.STATS_ORDERS_DETAIL: 
            return {
                ...stats,
                orders: {
                    ...stats.orders,
                    isLoading: true,
                    error: null
                }
            };

        case StatsActions.STATS_ORDERS_DETAIL_RESPONSE: 
            return {
                ...stats,
                orders: {
                    ...stats.orders,
                    isLoading: false,
                    error: null,
                    data: action.data
                }
            }
        
        case StatsActions.STATS_ORDERS_DETAIL_ERROR: 
            return {
                ...stats,
                orders: {
                    ...stats.orders,
                    isLoading: false,
                    error: action.error
                }
            }
    
        default: return stats;
    }
}

export default  StatsReducer;