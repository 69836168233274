import * as ProductsActions from '../actions/ProductsActions'

const ProductsOptionalCategoriesReducer = (optionalList = [], action) => {
    switch(action.type){
        case ProductsActions.PRODUCTS_OPTIONAL_LIST_RESPONSE:
            return action.optionalList;

        default: return optionalList;
    }
}

export default  ProductsOptionalCategoriesReducer;