import { AnyAction } from "redux";
import { SystemErrors } from "../..";
import { IProduct, IProductConfiguration, IProductConfigurationOrderMap, IProductConfigurationSaveResponse, IProductDuplicate, IProductDuplicateResponse, IProductsState } from "./interfaces";
import { v4 as uuidv4 } from 'uuid'

export const reducerProductSuccess = (state: IProductsState, data: IProduct[]): IProductsState => {
  let new_product;
  const new_state = {
    ...state,
    isLoading: false,
    data: data.map(product => {
      new_product = {
        ...product,
        optionals: product.optionals.map(optional => ({
          ...optional,
          uuid: optional.uuid || uuidv4()
        }))
      }

      return new_product;
    })
  }

  return new_state;

}

export const reducerProductRemoveRequest = (state: IProductsState, product_id: number): IProductsState => {
  return {
    ...state,
    data: state.data.map(product => {
      if(product.id !== product_id) return product;

      return {
        ...product,
        is_deleting: true,
        error: undefined
      }
    })
  }
}

export const reducerProductRemoveSuccess = (state: IProductsState, product_id: number): IProductsState => {
  return {
    ...state,
    data: state.data.filter(product => product.id !== product_id)
  }
}

export const reducerProductRemoveFailure = (state: IProductsState, product_id: number, error: SystemErrors): IProductsState => {
  return {
    ...state,
    data: state.data.map(product => {
      if(product.id !== product_id) return product;

      return {
        ...product,
        is_deleting: false,
        error
      }
    })
  }
}


export const reducerProductConfigurationCreateNew = (state: IProductsState): IProductsState => {
  if (!state.draft || !state.draft.id) return {
    ...state
  }

  return {
    ...state,
    draft: {
      ...state.draft,
      optionals: [...state.draft.optionals, {
        name: '',
        id_product: state.draft.id,
        min: 0,
        max: 0,
        optional: [],
        order: 0,
        status: true,
        uuid: uuidv4()
      }]
    }

  }
}

export const reducerProductConfigurationOptionCreateNew = (state: IProductsState, action: AnyAction): IProductsState => {
  if (!state.draft) return {
    ...state
  }

  return {
    ...state,
    draft: {
      ...state.draft,
      optionals: state.draft?.optionals.map((configuration, index) => {
        if (index !== action.configuration_index) return configuration;
        else return {
          ...configuration,
          is_changed: true,
          optional: [...configuration.optional, {
            name: '',
            price: 0,
            deletable: true,
            status: true
          }]
        }
      })

    }

  }
}

export const reducerProductConfigurationChange = (state: IProductsState, configuration: IProductConfiguration, configuration_index: number): IProductsState => {
  if(!state.draft) return {...state};

  return {
    ...state,
    draft: {
      ...state.draft,
      optionals: state.draft.optionals.map((opt, index) => {
        if(index === configuration_index) return {
          ...configuration,
          is_changed: true
        };
        else return opt;
      })
    }
  }
}

export const reducerProductConfigurationRemoveRequest = (state: IProductsState, configuration_id: number): IProductsState => {
  if (!state.draft || !state.draft.id) return {
    ...state
  }

  // Remove from draft and from data
  return {
    ...state,
    draft: {
      ...state.draft,
      optionals: state.draft.optionals.map((configuration, index) => {
        if(configuration_id !== configuration.id) return configuration;
        else return {
          ...configuration,
          is_deleting: true
        }
      })
    }
  }
}

export const reducerProductConfigurationRemoveSuccess = (state: IProductsState, configuration_id: number): IProductsState => {
  if (!state.draft || !state.draft.id) return {
    ...state
  }

  // Remove from draft and from data
  return {
    ...state,
    draft: {
      ...state.draft,
      optionals: state.draft.optionals.filter(configuration => configuration_id !== configuration.id)
    },
    data: state.data.map(product => {
      if(product.id !== state.draft?.id) return product;
      else {
        return {
          ...product,
          optionals: product.optionals.filter(configuration => configuration.id !== configuration_id)
        }
      }
    })

  }
}

export const reducerProductConfigurationRemoveFailure = (state: IProductsState, uuid: string, error: SystemErrors): IProductsState => {
  if (!state.draft || !state.draft.id) return {
    ...state
  }

  // Remove from draft and from data
  return {
    ...state,
    draft: {
      ...state.draft,
      optionals: state.draft.optionals.map((configuration, index) => {
        if(uuid !== configuration.uuid) return configuration;
        else return {
          ...configuration,
          is_deleting: false,
          error
        }
      })
    }
  }
}


// Configurations
export const reducerProductConfigurationDiscart = (state: IProductsState, configuration_index: number): IProductsState => {
  if (!state.draft || !state.draft.id) return {
    ...state
  }

  // Remove from draft
  return {
    ...state,
    draft: {
      ...state.draft,
      optionals: state.draft.optionals.filter((configuration, index) => configuration_index !== index)
    }
  }
}

export const reducerProductConfigurationDuplicate = (state: IProductsState, configuration_index: number): IProductsState => {
  if (!state.draft || !state.draft.id) return {
    ...state
  }

  // Remove from draft
  const [configuration_to_duplicate] = state.draft.optionals.filter((configuration, index) => index === configuration_index)

  if(!configuration_to_duplicate) return {...state}

  configuration_to_duplicate.optional = configuration_to_duplicate.optional.map(optional => {
    const  {id, ...rest} = optional;

    return {
      ...rest,
      deletable: true,
      is_deleting: false,
      error: undefined
    };
  })

  const configuration_duplicated: IProductConfiguration = {
    ...configuration_to_duplicate,
    uuid: uuidv4(),
    id:undefined,
    is_changed: true,
    name: `${configuration_to_duplicate.name} (Duplicado)`
  }

  return {
    ...state,
    draft: {
      ...state.draft,
      optionals: [...state.draft.optionals, configuration_duplicated]
    }
  }
}

export const reducerProductConfigurationSaveRequest = (state: IProductsState, configuration: IProductConfiguration, configuration_index: number): IProductsState => {
  if(!state.draft) return {
    ...state
  }

  return {
    ...state,
    draft: {
      ...state.draft,
      optionals: state.draft.optionals.map((configuration, index) => {
        if(index !== configuration_index) return configuration;
        else return {
          ...configuration,
          is_saving: true,
          is_changed: false
        }
      })
    }
  }
}

export const reducerProductConfigurationSaveSuccess = (state: IProductsState, configuration: IProductConfigurationSaveResponse, configuration_index: number): IProductsState => {
  if(!state.draft) return {
    ...state
  }

  let new_data: IProduct[] = state.data.map(product => {
    if(product.id !== configuration.id_product) return product;

    else {
      const has_configuration = product.optionals.find(prod_configuration => prod_configuration.id === configuration.id)
      const converted_configuration: IProductConfiguration = {
        ...configuration,
        is_changed: false,
        optional: configuration.optional.map(option => {
          if(option.status) return option.data;

          return {
            ...option.data,
            error: {
              message: option.message || "Erro inesperado"
            }
          }
        })
      }
      if(!has_configuration) {
        return {
          ...product,
          optionals: [...product.optionals, converted_configuration]
        }
      }
      else {
        return {
          ...product,
          optionals: product.optionals.map(prod_configuration => {
            if(prod_configuration.id !== configuration.id) return prod_configuration;

            return converted_configuration
          })
        }
      }
    }
  })

  return {
    ...state,
    data: new_data,
    draft: {
      ...state.draft,
      optionals: state.draft.optionals.map((actual_configuration, index) => {

        if(index !== configuration_index) return actual_configuration;
        else {
          const new_configuration: IProductConfiguration = {
            ...configuration,
            is_changed: false,
            optional: []
          }

          configuration.optional.forEach(option => {
            if(option.status) new_configuration.optional.push(option.data);
            else {
              new_configuration.is_changed = true;
              new_configuration.optional.push({
                ...option.data,
                error: {
                  message: option.message ||"Erro inesperado"
                }
              });
            }
          })

          return {
            ...new_configuration
          }
        }
      })
    }
  }
}


export const reducerProductConfigurationSaveFailure = (state: IProductsState, configuration_index: number, error: SystemErrors): IProductsState => {
  if(!state.draft) return {
    ...state
  }

  return {
    ...state,
    draft: {
      ...state.draft,
      optionals: state.draft.optionals.map((actual_configuration, index) => {

        if(index !== configuration_index) return actual_configuration;
        else {
          return {
            ...actual_configuration,
            is_saving: false,
            is_changed: true,
            error
          }
        }
      })
    }
  }
}


export const reducerProductConfigurationOptionDiscart = (state: IProductsState, configuration_index: number, option_index: number): IProductsState => {
  if(!state.draft) return {
    ...state
  }

  return {
    ...state,
    draft: {
      ...state.draft,
      optionals: state.draft.optionals.map((configuration, index) => {
        if(index !== configuration_index) return configuration;
        else return {
          ...configuration,
          optional: configuration.optional.filter((option, index) => index !== option_index)
        }
      })
    }
  }
}

export const reducerProductConfigurationOptionRemoveRequest = (state: IProductsState, configuration_index: number, option_id: number): IProductsState => {
  if(!state.draft) return {
    ...state
  }


  return {
    ...state,
    draft: {
      ...state.draft,
      optionals: state.draft.optionals.map((configuration, index) => {
        if(index !== configuration_index) return configuration;
        else return {
          ...configuration,
          optional: configuration.optional.map((option, index) => {
            if(option.id !== option_id) return option;

            return {
              ...option,
              is_deleting: true
            }
          })
        }
      })
    }
  }
}

export const reducerProductConfigurationOptionRemoveSuccess = (state: IProductsState, configuration_index: number, option_id: number): IProductsState => {
  if(!state.draft) return {
    ...state
  }

  return {
    ...state,
    draft: {
      ...state.draft,
      optionals: state.draft.optionals.map((configuration, index) => {
        if(index !== configuration_index) return configuration;
        else return {
          ...configuration,
          optional: configuration.optional.filter((option, index) => option.id !== option_id)
        }
      })
    }
  }
}

export const reducerProductConfigurationOptionRemoveFailure = (state: IProductsState, configuration_index: number, option_id: number, error: SystemErrors): IProductsState => {
  if(!state.draft) return {
    ...state
  }

  return {
    ...state,
    draft: {
      ...state.draft,
      optionals: state.draft.optionals.map((configuration, index) => {
        if(index !== configuration_index) return configuration;
        else return {
          ...configuration,
          optional: configuration.optional.map((option, index) => {
            if(option.id !== option_id) return option;

            return {
              ...option,
              is_deleting: false,
              error
            }
          })
        }
      })
    }
  }
}

//Remove photo
export const reducerProductRemovePhotoRequest = (state: IProductsState, product_id: number): IProductsState => {
  return {
    ...state,
    data: state.data.map(product => {
      if(product.id !== product_id) return product;

      return {
        ...product,
        is_deleting_photo: true
      }
    })
  }
}

export const reducerProductRemovePhotoSuccess = (state: IProductsState, product_id: number): IProductsState => {
  if(!state.draft) return {
    ...state,
    data: state.data.map(product => {
      if(product.id !== product_id) return product;

      return {
        ...product,
        is_deleting_photo: false,
        thumb: ''
      }
    }),
  }

  return {
    ...state,
    data: state.data.map(product => {
      if(product.id !== product_id) return product;

      return {
        ...product,
        is_deleting_photo: false,
        thumb: ''
      }
    }),
    draft: {
      ...state.draft,
      thumb: ''
    }
  }
}

export const reducerProductRemovePhotoFailure = (state: IProductsState, product_id: number, error: SystemErrors): IProductsState => {
  if(!state.draft) return {
    ...state,
  }

  return {
    ...state,
    data: state.data.map(product => {
      if(product.id !== product_id) return product;

      return {
        ...product,
        is_deleting_photo: false
      }
    }),
    error
  }
}

//Toggle status
export const reducerProductToggleStatusRequest = (state: IProductsState, product_id: number): IProductsState => {
  return {
    ...state,
    data: state.data.map(product => {
      if(product.id !== product_id) return product;

      return {
        ...product,
        is_toggling_status: true
      }
    })
  }
}

export const reducerProductToggleStatusSuccess = (state: IProductsState, product_id: number): IProductsState => {
  if(!state.draft) return {
    ...state,
    data: state.data.map(product => {
      if(product.id !== product_id) return product;

      return {
        ...product,
        is_toggling_status: false,
        status: !product.status
      }
    }),
  }

  return {
    ...state,
    data: state.data.map(product => {
      if(product.id !== product_id) return product;

      return {
        ...product,
        is_deleting_photo: false,
        status: !product.status
      }
    }),
    draft: {
      ...state.draft,
      status: !state.draft.status
    }
  }
}

export const reducerProductToggleStatusFailure = (state: IProductsState, product_id: number, error: SystemErrors): IProductsState => {
  if(!state.draft) return {
    ...state,
  }

  return {
    ...state,
    data: state.data.map(product => {
      if(product.id !== product_id) return product;

      return {
        ...product,
        is_toggling_status: false,
        error
      }
    })
  }
}

//Product Save
export const reducerProductSaveSuccess = (state: IProductsState, product: IProduct): IProductsState => {
  const new_product = {
    ...product,
    optionals: product.optionals.map(optional => ({
      ...optional,
      uuid: optional.uuid || uuidv4()
    }))
  }

  let hasProduct = false;

  const products_list = state.data.map(old_product => {
    if(old_product.id !== product.id) return old_product;

    hasProduct = true;
    return new_product
  })

  if(!state.draft) return {
    ...state,
    data: products_list,
    isSaving: false
  }

  if(!hasProduct) return {
    ...state,
    data: [...products_list, new_product],
    isSaving: false,
    draft: {
      ...new_product,
      is_changed: false
    }
  }

  return {
    ...state,
    data: products_list,
    isSaving: false,
    draft: {
      ...new_product,
      is_changed: false
    }
  }
}

// Duplicate
export const reducerProductStartDuplicate = (state: IProductsState, product: IProductDuplicate): IProductsState => {
  return {
    ...state,
    duplicate: product
  }
}

export const reducerProductStopDuplicate = (state: IProductsState): IProductsState => {
  return {
    ...state,
    duplicate: null
  }
}

export const reducerProductChangeDuplicate = (state: IProductsState, product: IProductDuplicate): IProductsState => {
  return {
    ...state,
    duplicate: product
  }
}

export const reducerProductSaveDuplicateRequest = (state: IProductsState, product_id: number): IProductsState => {
  if(!state.duplicate) return {...state}
  return {
    ...state,
    duplicate: {
      ...state.duplicate,
      isSaving: true,
      error: undefined
    }
  }
}

export const reducerProductSaveDuplicateSuccess = (state: IProductsState, product: IProductDuplicateResponse): IProductsState => {
  if(!state.duplicate || !product.status) return {...state}
  return {
    ...state,
    duplicate: null,
    data: [...state.data, {
      ...product.data,
      optionals: product.data.optionals.map(optional => {
        return {
          ...optional,
          optional: optional.optional.map(opt => ({...opt.data}))
        }
      })
    }]
  }
}

export const reducerProductSaveDuplicateFailure = (state: IProductsState, error: SystemErrors): IProductsState => {
  if(!state.duplicate) return {
    ...state,
  }

  return {
    ...state,
    duplicate: {
      ...state.duplicate,
      error,
      isSaving: false
    }
  }
}

//Rearranging
export const reducerProductConfigurationStartRearranging = (state: IProductsState): IProductsState => {
  if(!state.draft) return {...state}

  return {
    ...state,
    draft: {
      ...state.draft,
      rearranging:{
        data: state.draft.optionals.map(configuration => ({
          id: configuration.id || 0,
          description: configuration.name
        }) )
      }
    }
  }
}

export const reducerProductConfigurationUpdateRearranging = (state: IProductsState, data: IProductConfigurationOrderMap[]): IProductsState => {
  if(!state.draft) return {...state}

  return {
    ...state,
    draft: {
      ...state.draft,
      rearranging:{
        data: data
      }
    }
  }
}

export const reducerProductConfigurationStopRearranging = (state: IProductsState): IProductsState => {
  if(!state.draft) return {...state}

  return {
    ...state,
    draft: {
      ...state.draft,
      rearranging: undefined
    }
  }
}


export const reducerProductConfigurationRearrangingSaveRequest = (state: IProductsState): IProductsState => {
  if(!state.draft) return {...state}

  return {
    ...state,
    draft: {
      ...state.draft,
      isRearranging: true
    }
  }
}

export const reducerProductConfigurationRearrangingSaveSuccess = (state: IProductsState, configurations: IProductConfiguration[]): IProductsState => {
  if(!state.draft) return {...state}

  const products = state.data.map(old_product => {
    if(old_product.id !== state.draft?.id) return old_product;

    return {
      ...old_product,
      optionals: configurations
    }
  })

  return {
    ...state,
    data: products,
    draft: {
      ...state.draft,
      optionals: configurations,
      rearranging: undefined,
      isRearranging: false
    }
  }
}

export const reducerProductConfigurationRearrangingSaveFailure = (state: IProductsState, error: SystemErrors): IProductsState => {
  if(!state?.draft?.rearranging) return {...state}

  return {
    ...state,
    draft: {
      ...state.draft,
      isRearranging: false,
      rearranging: {
        ...state.draft.rearranging,
        error
      }
    }
  }
}
