import { SystemErrors } from "../..";

export enum LinkTypes {
  LINK_GET_SETTINGS_REQUEST = "link/GET_SETTINGS_REQUEST",
  LINK_GET_SETTINGS_SUCCESS = "link/GET_SETTINGS_SUCCESS",
  LINK_GET_SETTINGS_FAILURE = "link/GET_SETTINGS_FAILURE",

  LINK_VALIDITY_SLUG_REQUEST = "link/VALIDITY_SLUG_REQUEST",
  LINK_VALIDITY_SLUG_SUCCESS = "link/VALIDITY_SLUG_SUCCESS",
  LINK_VALIDITY_SLUG_FAILURE = "link/VALIDITY_SLUG_FAILURE",

  LINK_SAVE_SLUG_REQUEST = "link/SAVE_SLUG_REQUEST",
  LINK_SAVE_SLUG_SUCCESS = "link/SAVE_SLUG_SUCCESS",
  LINK_SAVE_SLUG_FAILURE = "link/SAVE_SLUG_FAILURE",

  LINK_SAVE_FEATURES_REQUEST = "link/SAVE_FEATURES_REQUEST",
  LINK_SAVE_FEATURES_SUCCESS = "link/SAVE_FEATURES_SUCCESS",
  LINK_SAVE_FEATURES_FAILURE = "link/SAVE_FEATURES_FAILURE",

  LINK_SAVE_ORDER_TYPES_REQUEST = "link/SAVE_ORDER_TYPES_REQUEST",
  LINK_SAVE_ORDER_TYPES_SUCCESS = "link/SAVE_ORDER_TYPES_SUCCESS",
  LINK_SAVE_ORDER_TYPES_FAILURE = "link/SAVE_ORDER_TYPES_FAILURE",
}

export interface ILinkSettings {
  slug: string;
  features: {
    delivered: boolean;
    finished: boolean;
    observations: boolean;
  }
  orderTypes: {
    delivery: {
      active: boolean;
      time: number;
    },
    takeAway: {
      active: boolean;
      time: number;
    },
    table: {
      active: boolean;
      time: number;
    }
  }
}

export interface ILinkSettingsState extends ILinkSettings {
  isValidatingSlug?: boolean;
  isSlugValid?: boolean;
  isSavingSlug?: boolean;
  slugError?: SystemErrors;
  isSavingFeatures?: boolean;
  featuresError?: SystemErrors;
  isLoading?: boolean;
  error?: SystemErrors;
  isSavingOrderTypes?: boolean;
  orderTypesError?: SystemErrors;
}

export type ILinkSettingsResponse = {
  status: true,
  data: {
    slug: string;
    features: {
      delivered: boolean;
      finished: boolean;
      observations: boolean;
    }
    orderTypes: {
      delivery: {
        active: boolean;
        time: number;
      },
      takeAway: {
        active: boolean;
        time: number;
      },
      table: {
        active: boolean;
        time: number;
      }
    }
  }
} | {
  status: false,
  message: string;
}

export interface IValiditySlugResquest {
  slug: string;
}

export type IValiditySlugResponse = {
  status: true
} | {
  status: false,
  message: string;
}

export interface ISaveSlugRequest {
  slug: string;
}

export type ISaveSlugResponse = {
  status: true;
  data: {
    slug: string;
  }
} | {
  status: false,
  message: string;
}

export interface ISaveFeaturesRequest {
  delivered: boolean;
  finished: boolean;
  observations: boolean;
}

export type ISaveFeaturesResponse = {
  status: true,
  data: {
    delivered: boolean;
    finished: boolean;
    observations: boolean;
  }
} | {
  status: false,
  message: string;
}

export interface ISaveOrderTypesRequest {
  delivery: {
    active: boolean;
    time: number;
  },
  takeAway: {
    active: boolean;
    time: number;
  },
  table: {
    active: boolean;
    time: number;
  }
}

export type ISaveOrderTypesResponse = {
  status: true,
  data: ISaveOrderTypesRequest
} | {
  status: false,
  message: string;
}
