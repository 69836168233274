import { Reducer } from "redux";
import { AppearanceTypes, IAppearanceState } from "./interfaces";
import { reducerAppearenceFailure, reducerAppearenceRemoveLogoFailure, reducerAppearenceRemoveLogoRequest, reducerAppearenceRemoveLogoSuccess, reducerAppearenceRequest, reducerAppearenceSaveFailure, reducerAppearenceSaveLogoFailure, reducerAppearenceSaveLogoRequest, reducerAppearenceSaveLogoSuccess, reducerAppearenceSaveRequest, reducerAppearenceSaveSuccess, reducerAppearenceSetTempLogo, reducerAppearenceStartUpdateLogo, reducerAppearenceStopUpdateLogo, reducerAppearenceSuccess } from "./reducerHandlers";

export const APPEARANCE_INITIAL_STATE: IAppearanceState = {
  data: {
    backgroundColor: "#2a2e3b",
    primaryColor: "#343b4a",
    primaryTextColor: "#FFF",
    secondaryTextColor: "#CCC",
    accentColor: "#FFF",
    accentTextColor: "#003557",
    logo: ""
  },
  isLoading: true,
}

const reducer: Reducer = (state = APPEARANCE_INITIAL_STATE, action) => {
  switch (action.type) {
    case AppearanceTypes.APPEARANCE_REQUEST:
      return reducerAppearenceRequest(state);
    case AppearanceTypes.APPEARANCE_SUCCESS:
      return reducerAppearenceSuccess(state, action.data);
    case AppearanceTypes.APPEARANCE_FAILURE:
      return reducerAppearenceFailure(state, action.error);

    //save
    case AppearanceTypes.APPEARANCE_SAVE_REQUEST:
      return reducerAppearenceSaveRequest(state);
    case AppearanceTypes.APPEARANCE_SAVE_SUCCESS:
      return reducerAppearenceSaveSuccess(state, action.data);
    case AppearanceTypes.APPEARANCE_SAVE_FAILURE:
      return reducerAppearenceSaveFailure(state, action.error);

    // logo
    case AppearanceTypes.APPEARANCE_START_UPDATE_LOGO:
      return reducerAppearenceStartUpdateLogo(state);
    case AppearanceTypes.APPEARANCE_STOP_UPDATE_LOGO:
      return reducerAppearenceStopUpdateLogo(state);
    case AppearanceTypes.APPEARANCE_SET_TEMP_LOGO:
      return reducerAppearenceSetTempLogo(state, action.tempLogo);

    // remove logo
    case AppearanceTypes.APPEARANCE_REMOVE_LOGO_REQUEST:
      return reducerAppearenceRemoveLogoRequest(state);
    case AppearanceTypes.APPEARANCE_REMOVE_LOGO_SUCCESS:
      return reducerAppearenceRemoveLogoSuccess(state);
    case AppearanceTypes.APPEARANCE_REMOVE_LOGO_FAILURE:
      return reducerAppearenceRemoveLogoFailure(state, action.error);

    // save logo
    case AppearanceTypes.APPEARANCE_SAVE_LOGO_REQUEST:
      return reducerAppearenceSaveLogoRequest(state);
    case AppearanceTypes.APPEARANCE_SAVE_LOGO_SUCCESS:
      return reducerAppearenceSaveLogoSuccess(state);
    case AppearanceTypes.APPEARANCE_SAVE_LOGO_FAILURE:
      return reducerAppearenceSaveLogoFailure(state, action.error);
    default:
      return state;
  }
}

export default reducer;
