import { Reducer } from 'redux'
import { IProductCategoriesState} from './interfaces'
import { ProductCategoriesActions } from './actions'
import { reducerProductCategoryChangeDraft, reducerProductCategoryChangeInfo, reducerProductCategoryRearrangingSaveFailure, reducerProductCategoryRearrangingSaveRequest, reducerProductCategoryRearrangingSaveSuccess, reducerProductCategoryRemoveFailure, reducerProductCategoryRemoveRequest, reducerProductCategoryRemoveSuccess, reducerProductCategorySaveFailure, reducerProductCategorySaveRequest, reducerProductCategorySaveSuccess, reducerProductCategoryStartRearranging, reducerProductCategoryStopRearranging, reducerProductCategoryToggleStatusFailure, reducerProductCategoryToggleStatusRequest, reducerProductCategoryToggleStatusSuccess, reducerProductCategoryUpdateRearranging } from './reducerHandler'


export const PRODUCT_CATEGORY_INITIAL_STATE: IProductCategoriesState = {
  data: [],
  isLoading: false,
  error: false,
  draft: null,
  isSaving: false
}

const reducer: Reducer<IProductCategoriesState> = (state = PRODUCT_CATEGORY_INITIAL_STATE, action) => {
  switch(action.type) {
    case ProductCategoriesActions.PRODUCT_CATEGORIES_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case ProductCategoriesActions.PRODUCT_CATEGORIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.data
      }
    case ProductCategoriesActions.PRODUCT_CATEGORIES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case ProductCategoriesActions.PRODUCT_CATEGORIES_START_CREATE:
      return {
        ...state,
        error: false,
        draft:{
          name: "",
          status: true
        }
      }
    case ProductCategoriesActions.PRODUCT_CATEGORIES_STOP_CREATE:
      return {
        ...state,
        draft:null
      }

    case ProductCategoriesActions.PRODUCT_CATEGORIES_CHANGE_DRAFT:
      return reducerProductCategoryChangeDraft(state, action.category)
    case ProductCategoriesActions.PRODUCT_CATEGORIES_CHANGE_INFO:
      return reducerProductCategoryChangeInfo(state, action.category)

    // Toggle Status
    case ProductCategoriesActions.PRODUCT_CATEGORIES_TOGGLE_STATUS_REQUEST:
      return reducerProductCategoryToggleStatusRequest(state, action.category_id)
    case ProductCategoriesActions.PRODUCT_CATEGORIES_TOGGLE_STATUS_SUCCESS:
      return reducerProductCategoryToggleStatusSuccess(state, action.category)
    case ProductCategoriesActions.PRODUCT_CATEGORIES_TOGGLE_STATUS_FAILURE:
      return reducerProductCategoryToggleStatusFailure(state, action.category_id, action.error)

    // Save
    case ProductCategoriesActions.PRODUCT_CATEGORIES_SAVE_REQUEST:
      return reducerProductCategorySaveRequest(state, action.category)
    case ProductCategoriesActions.PRODUCT_CATEGORIES_SAVE_SUCCESS:
      return reducerProductCategorySaveSuccess(state, action.category)
    case ProductCategoriesActions.PRODUCT_CATEGORIES_SAVE_FAILURE:
      return reducerProductCategorySaveFailure(state, action.error, action.category_id)

    // Remove
    case ProductCategoriesActions.PRODUCT_CATEGORIES_REMOVE_REQUEST:
      return reducerProductCategoryRemoveRequest(state, action.category_id)
    case ProductCategoriesActions.PRODUCT_CATEGORIES_REMOVE_SUCCESS:
      return reducerProductCategoryRemoveSuccess(state, action.category_id)
    case ProductCategoriesActions.PRODUCT_CATEGORIES_REMOVE_FAILURE:
      return reducerProductCategoryRemoveFailure(state, action.error, action.category_id)

    //Rearranging
    case ProductCategoriesActions.PRODUCT_CATEGORIES_START_REARRANGING:
      return reducerProductCategoryStartRearranging(state)
    case ProductCategoriesActions.PRODUCT_CATEGORIES_STOP_REARRANGING:
      return reducerProductCategoryStopRearranging(state)
    case ProductCategoriesActions.PRODUCT_CATEGORIES_UPDATE_REARRANGING:
      return reducerProductCategoryUpdateRearranging(state, action.data)

    case ProductCategoriesActions.PRODUCT_CATEGORIES_REARRANGING_SAVE_REQUEST:
      return reducerProductCategoryRearrangingSaveRequest(state)
    case ProductCategoriesActions.PRODUCT_CATEGORIES_REARRANGING_SAVE_SUCCESS:
      return reducerProductCategoryRearrangingSaveSuccess(state, action.categories)
    case ProductCategoriesActions.PRODUCT_CATEGORIES_REARRANGING_SAVE_FAILURE:
      return reducerProductCategoryRearrangingSaveFailure(state, action.error)
    default:
      return state;
  }
}

export default reducer;
