import {toastr} from 'react-redux-toastr'

import * as StatsActions from '../actions/StatsActions';

import StatsService from '../../services/StatsService';  

import {all, put, takeLatest, takeEvery} from 'redux-saga/effects';

// list
function* financial(action) {
    let dataToSend = null;
    yield StatsService.financial(action.period)
    .then(result => {
        const { data } = result;
        if(data.status) {
            dataToSend = data.data.financial;
        }
        else console.log(result)
    })
    .catch(err => {
        if(err.response && err.response.data){
            const data = err.response.data;
            
            toastr.error(data.msg);
        }
        else console.log(err)
    })
    if(dataToSend !== null) yield put(StatsActions.financialResponse(dataToSend))
}

function* watchFinancial(){
    yield takeEvery(StatsActions.STATS_FINANCIAL, financial);
}

// Details
function* financialDetails(action) {
    let dataToSend = null;
    yield StatsService.financialDetails(action.period)
    .then(result => {
        const { data } = result;
        if(data.status) {
            dataToSend = data.data.financialdetails;
        }
        else console.log(result)
    })
    .catch(err => {
        if(err.response && err.response.data){
            const data = err.response.data;
            
            toastr.error(data.msg);
        }
        else console.log(err)
    })
    if(dataToSend !== null) yield put(StatsActions.financialDetailsResponse(dataToSend))
}

function* watchFinancialDetails(){
    yield takeEvery(StatsActions.STATS_FINANCIAL_DETAILS, financialDetails);
}

// Financial History
function* financialHistory(action) {
    let dataToSend = null;
    yield StatsService.financialHistory(action.period)
    .then(result => {
        const { data } = result;
        if(data.status) {
            dataToSend = data.data.financialhistory;
        }
        else console.log(result)
    })
    .catch(err => {
        if(err.response && err.response.data){
            const data = err.response.data;
            
            toastr.error(data.msg);
        }
        else console.log(err)
    })
    if(dataToSend !== null) yield put(StatsActions.financialHistoryResponse(dataToSend))
}

function* watchFinancialHistory(){
    yield takeEvery(StatsActions.STATS_FINANCIAL_HISTORY, financialHistory);
}

// payment methods
function* paymentMethods(action) {
    let dataToSend = null;
    yield StatsService.paymentMethods(action.period)
    .then(result => {
        const { data } = result;
        if(data.status) {
            dataToSend = data.data.paymentmethods;
        }
        else console.log(result)
    })
    .catch(err => {
        if(err.response && err.response.data){
            const data = err.response.data;
            
            toastr.error(data.msg);
        }
        else console.log(err)
    })
    if(dataToSend !== null) yield put(StatsActions.paymentMethodsResponse(dataToSend))
}

function* watchPaymentMethods(){
    yield takeLatest(StatsActions.STATS_PAYMENT_METHODS, paymentMethods);
}

// orders details 
function* order_detail(action) {
    let data = null;
    let error = null;
    yield StatsService.orders_detail(action.filter)
    .then(details => {
        data = details.data;
    })
    .catch(err => {
        error = err.response.data || err.response;
    })
    
    if(data) yield put(StatsActions.get_orders_detail_response(data));
    if(error) yield put(StatsActions.get_orders_detail_error(error));
}

function* watch_order_detail() {
    yield takeLatest(StatsActions.STATS_ORDERS_DETAIL, order_detail);
}

export default function* StatsSaga(){
    yield all([
        watchFinancial(),
        watchFinancialHistory(),
        watchPaymentMethods(),
        watchFinancialDetails(),
        watch_order_detail()
    ])
}