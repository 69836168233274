export const STATS_FINANCIAL                    = "STATS_FINANCIAL";
export const STATS_FINANCIAL_RESPONSE           = "STATS_FINANCIAL_RESPONSE";
export const STATS_FINANCIAL_HISTORY            = "STATS_FINANCIAL_HISTORY";
export const STATS_FINANCIAL_HISTORY_RESPONSE   = "STATS_FINANCIAL_HISTORY_RESPONSE";
export const STATS_FINANCIAL_DETAILS            = "STATS_FINANCIAL_DETAILS";
export const STATS_FINANCIAL_DETAILS_RESPONSE   = "STATS_FINANCIAL_DETAILS_RESPONSE";
export const STATS_PAYMENT_METHODS              = "STATS_PAYMENT_METHODS";
export const STATS_PAYMENT_METHODS_RESPONSE     = "STATS_PAYMENT_METHODS_RESPONSE";

export const financial = (period) => {
    return {
        type: STATS_FINANCIAL,
        period
    }
}

export const financialResponse = (financialData) => {
    return {
        type: STATS_FINANCIAL_RESPONSE,
        financial: financialData
    }
}

export const financialHistory = (period) => {
    return {
        type: STATS_FINANCIAL_HISTORY,
        period
    }
}

export const financialDetails = (period) => {
    return {
        type: STATS_FINANCIAL_DETAILS,
        period
    }
}

export const financialDetailsResponse = (financialData) => {
    return {
        type: STATS_FINANCIAL_DETAILS_RESPONSE,
        financialdetails: financialData
    }
}

export const financialHistoryResponse = (financialData) => {
    return {
        type: STATS_FINANCIAL_HISTORY_RESPONSE,
        history: financialData
    }
}

export const paymentMethods = (period) => {
    return {
        type: STATS_PAYMENT_METHODS,
        period
    }
}

export const paymentMethodsResponse = (methods) => {
    return {
        type: STATS_PAYMENT_METHODS_RESPONSE,
        methods
    }
}

export const STATS_ORDERS_DETAIL = "STATS_ORDERS_DETAIL";
export const STATS_ORDERS_DETAIL_RESPONSE = "STATS_ORDERS_DETAIL_RESPONSE";
export const STATS_ORDERS_DETAIL_ERROR = "STATS_ORDERS_DETAIL_ERROR";

export const get_orders_detail = (filter = {}) => {
    return {
        type: STATS_ORDERS_DETAIL,
        filter
    }
}

export const get_orders_detail_response = (data = {}) => {
    return {
        type: STATS_ORDERS_DETAIL_RESPONSE,
        data
    }
}

export const get_orders_detail_error = (error = {}) => {
    return {
        type: STATS_ORDERS_DETAIL_ERROR,
        error
    }
}
