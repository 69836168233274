export const PRODUCTS_LIST                            = "PRODUCTS_LIST";
export const PRODUCTS_LIST_RESPONSE                   = "PRODUCTS_LIST_RESPONSE";
export const PRODUCTS_STORE                           = "PRODUCTS_STORE";
export const PRODUCTS_REMOVE                          = "PRODUCTS_REMOVE";
export const PRODUCTS_CATEGORIES_LIST                 = "PRODUCTS_CATEGORIES_LIST";
export const PRODUCTS_CATEGORIES_LIST_RESPONSE        = "PRODUCTS_CATEGORIES_LIST_RESPONSE";
export const PRODUCTS_CATEGORIES_STORE                = "PRODUCTS_CATEGORIES_STORE";
export const PRODUCTS_CATEGORIES_REMOVE               = "PRODUCTS_CATEGORIES_REMOVE";
export const PRODUCTS_OPTIONAL_LIST                   = "PRODUCTS_OPTIONAL_LIST";
export const PRODUCTS_OPTIONAL_LIST_RESPONSE          = "PRODUCTS_OPTIONAL_LIST_RESPONSE";
export const PRODUCTS_OPTIONAL_STORE                  = "PRODUCTS_OPTIONAL_STORE";
export const PRODUCTS_OPTIONAL_REMOVE                 = "PRODUCTS_OPTIONAL_REMOVE";

// products
export const list = (filter = {}) => {
    return {
        type: PRODUCTS_LIST,
        filter
    }
}

export const listResponse = (productsList) => {
    return {
        type: PRODUCTS_LIST_RESPONSE,
        productsList
    }
}

export const store = (product, callback = null) => {
    return {
        type: PRODUCTS_STORE,
        product ,
        callback
    }
}

export const remove = (id) => {
    return {
        type: PRODUCTS_REMOVE,
        id
    }
}

//categories
export const listCategories = (filter = {}) => {
    return {
        type: PRODUCTS_CATEGORIES_LIST,
        filter
    }
}

export const listCategoriesResponse = (categoriesList) => {
    return {
        type: PRODUCTS_CATEGORIES_LIST_RESPONSE,
        categoriesList
    }
}

export const storeCategory = (category, callback = null) => {
    return {
        type: PRODUCTS_CATEGORIES_STORE,
        category ,
        callback
    }
}

export const removeCategory = (id, callback = null) => {
    return {
        type: PRODUCTS_CATEGORIES_REMOVE,
        id,
        callback
    }
}

//optional
export const optionalCategories = () => {
    return {
        type: PRODUCTS_OPTIONAL_LIST
    }
}

export const listOptionalResponse = (optionalList) => {
    return {
        type: PRODUCTS_OPTIONAL_LIST_RESPONSE,
        optionalList
    }
}

export const storeOptional = (optinal, callback = null) => {
    return {
        type: PRODUCTS_OPTIONAL_STORE,
        optinal ,
        callback
    }
}

export const removeOptional = (id) => {
    return {
        type: PRODUCTS_OPTIONAL_REMOVE,
        id
    }
}