import { AxiosResponse } from "axios"
import api from "../../services/api"
import auth from "../../services/auth"
import { IAppearance, IAppearenceRemoveLogoResponse, IAppearenceResponse, IAppearenceSaveLogoResponse } from "./interfaces"

export const serviceAppearanceRequest = () => {
  return api.get<IAppearenceResponse, AxiosResponse<IAppearenceResponse>>('/settings/appearance', {
    headers: { Authorization: `Bearer ${auth.getToken()}` }
  })
}

export const serviceAppearanceRemoveLogoRequest = () => {
  return api.delete<IAppearenceRemoveLogoResponse, AxiosResponse<IAppearenceRemoveLogoResponse>>('/settings/appearance/remove-logo', {
    headers: { Authorization: `Bearer ${auth.getToken()}` }
  })
}

export const serviceAppearanceSave = (data: IAppearance) => {
  return api.post<IAppearenceResponse, AxiosResponse<IAppearenceResponse>>('/settings/appearance',
  data,
  {
    headers: { Authorization: `Bearer ${auth.getToken()}` }
  })
}

export const serviceAppearanceSaveLogo = (newLogo: string) => {
  return api.post<IAppearenceSaveLogoResponse, AxiosResponse<IAppearenceSaveLogoResponse>>('/settings/appearance/set-logo',
  {
    newLogo
  },
  {
    headers: { Authorization: `Bearer ${auth.getToken()}` }
  })
}
