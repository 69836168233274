import { Reducer } from 'redux'
import { IProductsState} from './interfaces'
import { ProductsActions } from './actions'
import { reducerProductChangeDuplicate, reducerProductConfigurationChange, reducerProductConfigurationCreateNew, reducerProductConfigurationDiscart, reducerProductConfigurationDuplicate, reducerProductConfigurationOptionCreateNew, reducerProductConfigurationOptionDiscart, reducerProductConfigurationOptionRemoveFailure, reducerProductConfigurationOptionRemoveRequest, reducerProductConfigurationOptionRemoveSuccess, reducerProductConfigurationRearrangingSaveFailure, reducerProductConfigurationRearrangingSaveRequest, reducerProductConfigurationRearrangingSaveSuccess, reducerProductConfigurationRemoveFailure, reducerProductConfigurationRemoveRequest, reducerProductConfigurationRemoveSuccess, reducerProductConfigurationSaveFailure, reducerProductConfigurationSaveRequest, reducerProductConfigurationSaveSuccess, reducerProductConfigurationStartRearranging, reducerProductConfigurationStopRearranging, reducerProductConfigurationUpdateRearranging, reducerProductRemoveFailure, reducerProductRemovePhotoFailure, reducerProductRemovePhotoRequest, reducerProductRemovePhotoSuccess, reducerProductRemoveRequest, reducerProductRemoveSuccess, reducerProductSaveDuplicateFailure, reducerProductSaveDuplicateRequest, reducerProductSaveDuplicateSuccess, reducerProductSaveSuccess, reducerProductStartDuplicate, reducerProductStopDuplicate, reducerProductSuccess, reducerProductToggleStatusFailure, reducerProductToggleStatusRequest, reducerProductToggleStatusSuccess } from './reducerHandlers'


export const PRODUCTS_INITIAL_STATE: IProductsState = {
  data: [],
  isLoading: false,
  isSaving: false,
  editingStep: 0,
  error: false,
  draft: null,
  duplicate: null
}

const reducer: Reducer<IProductsState> = (state = PRODUCTS_INITIAL_STATE, action) => {
  switch(action.type) {
    case ProductsActions.PRODUCTS_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case ProductsActions.PRODUCTS_SUCCESS:
      return reducerProductSuccess(state, action.data)
    case ProductsActions.PRODUCTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case ProductsActions.PRODUCTS_CREATE_NEW:
      return {
        ...state,
        draft: {
          cookable: false,
          id_category: action.id_category,
          name: '',
          optionals:[],
          price: 0,
          status: false
        },
        editingStep: 0
      }
    case ProductsActions.PRODUCTS_CHANGE_INFO:
      return {
        ...state,
        draft: action.product
      }
    case ProductsActions.PRODUCTS_START_EDIT:
      return {
        ...state,
        draft: action.product,
        editingStep: 0
      }
    case ProductsActions.PRODUCTS_STOP_EDIT:
      return {
        ...state,
        draft: null
      }

    case ProductsActions.PRODUCTS_SAVE_REQUEST:
      return {
        ...state,
        isSaving: true,
        error: false
      }

    case ProductsActions.PRODUCTS_SAVE_SUCCESS:
      return reducerProductSaveSuccess(state, action.product)

    case ProductsActions.PRODUCTS_SAVE_FAILURE:
      return {
        ...state,
        isSaving: false,
        error: action.error
      }

    case ProductsActions.PRODUCTS_SET_STEP:
      return {
        ...state,
        editingStep: action.step
      }

    // Remove
    case ProductsActions.PRODUCTS_REMOVE_REQUEST:
      return reducerProductRemoveRequest(state, action.product_id)

    case ProductsActions.PRODUCTS_REMOVE_SUCCESS:
      return reducerProductRemoveSuccess(state, action.product_id)

    case ProductsActions.PRODUCTS_REMOVE_FAILURE:
      return reducerProductRemoveFailure(state, action.product_id, action.error)

    // Remove photo
    case ProductsActions.PRODUCTS_REMOVE_PHOTO_REQUEST:
      return reducerProductRemovePhotoRequest(state, action.product_id)

    case ProductsActions.PRODUCTS_REMOVE_PHOTO_SUCCESS:
      return reducerProductRemovePhotoSuccess(state, action.product_id)

    case ProductsActions.PRODUCTS_REMOVE_PHOTO_FAILURE:
      return reducerProductRemovePhotoFailure(state, action.product_id, action.error)

    // Remove photo
    case ProductsActions.PRODUCTS_TOGGLE_STAUTS_REQUEST:
      return reducerProductToggleStatusRequest(state, action.product_id)

    case ProductsActions.PRODUCTS_TOGGLE_STAUTS_SUCCESS:
      return reducerProductToggleStatusSuccess(state, action.product_id)

    case ProductsActions.PRODUCTS_TOGGLE_STAUTS_FAILURE:
      return reducerProductToggleStatusFailure(state, action.product_id, action.error)


    // configuration
    case ProductsActions.PRODUCTS_CONFIGS_CREATE_NEW:
      return reducerProductConfigurationCreateNew(state)

    case ProductsActions.PRODUCTS_CONFIGS_OPTION_CREATE_NEW:
      return reducerProductConfigurationOptionCreateNew(state, action)

    case ProductsActions.PRODUCTS_CONFIGS_CHANGE_INFO:
      return reducerProductConfigurationChange(state, action.configuration, action.configuration_index)

    case ProductsActions.PRODUCTS_CONFIGS_DISCART:
      return reducerProductConfigurationDiscart(state, action.configuration_index)

    case ProductsActions.PRODUCTS_CONFIGS_DUPLICATE:
      return reducerProductConfigurationDuplicate(state, action.configuration_index)

    case ProductsActions.PRODUCTS_CONFIGS_REMOVE_REQUEST:
      return reducerProductConfigurationRemoveRequest(state, action.configuration_id)

    case ProductsActions.PRODUCTS_CONFIGS_REMOVE_SUCCESS:
      return reducerProductConfigurationRemoveSuccess(state, action.configuration_id)

    case ProductsActions.PRODUCTS_CONFIGS_REMOVE_FAILURE:
      return reducerProductConfigurationRemoveFailure(state, action.uuid, action.error)

    case ProductsActions.PRODUCTS_CONFIGS_SAVE_REQUEST:
      return reducerProductConfigurationSaveRequest(state, action.configuration, action.configuration_index)

    case ProductsActions.PRODUCTS_CONFIGS_SAVE_SUCCESS:
      return reducerProductConfigurationSaveSuccess(state, action.configuration, action.configuration_index)

    case ProductsActions.PRODUCTS_CONFIGS_SAVE_FAILURE:
      return reducerProductConfigurationSaveFailure(state, action.configuration_index, action.error)

    // configuration options
    case ProductsActions.PRODUCTS_CONFIGS_OPTION_DISCART:
      return reducerProductConfigurationOptionDiscart(state, action.configuration_index, action.option_index)

    case ProductsActions.PRODUCTS_CONFIGS_OPTION_REMOVE_REQUEST:
      return reducerProductConfigurationOptionRemoveRequest(state, action.configuration_index, action.option_id)

    case ProductsActions.PRODUCTS_CONFIGS_OPTION_REMOVE_SUCCESS:
      return reducerProductConfigurationOptionRemoveSuccess(state, action.configuration_index, action.option_id)

    case ProductsActions.PRODUCTS_CONFIGS_OPTION_REMOVE_FAILURE:
      return reducerProductConfigurationOptionRemoveFailure(state, action.configuration_index, action.option_id, action.error)

    // Duplicate
    case ProductsActions.PRODUCTS_START_DUPLICATE:
      return reducerProductStartDuplicate(state, action.product)
    case ProductsActions.PRODUCTS_STOP_DUPLICATE:
      return reducerProductStopDuplicate(state)
    case ProductsActions.PRODUCTS_CHANGE_DUPLICATE:
      return reducerProductChangeDuplicate(state, action.product)
    case ProductsActions.PRODUCTS_SAVE_DUPLICATE_REQUEST:
      return reducerProductSaveDuplicateRequest(state, action.product)
    case ProductsActions.PRODUCTS_SAVE_DUPLICATE_SUCCESS:
      return reducerProductSaveDuplicateSuccess(state, action.product)
    case ProductsActions.PRODUCTS_SAVE_DUPLICATE_FAILURE:
      return reducerProductSaveDuplicateFailure(state, action.error)


    //Rearranging
    case ProductsActions.PRODUCTS_START_REARRANGING:
      return reducerProductConfigurationStartRearranging(state)
    case ProductsActions.PRODUCTS_STOP_REARRANGING:
      return reducerProductConfigurationStopRearranging(state)
    case ProductsActions.PRODUCTS_UPDATE_REARRANGING:
      return reducerProductConfigurationUpdateRearranging(state, action.data)

    case ProductsActions.PRODUCTS_REARRANGING_SAVE_REQUEST:
      return reducerProductConfigurationRearrangingSaveRequest(state)
    case ProductsActions.PRODUCTS_REARRANGING_SAVE_SUCCESS:
      return reducerProductConfigurationRearrangingSaveSuccess(state, action.configurations)
    case ProductsActions.PRODUCTS_REARRANGING_SAVE_FAILURE:
      return reducerProductConfigurationRearrangingSaveFailure(state, action.error)
    default:
      return state;
  }
}

export default reducer;
