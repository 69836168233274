import { SystemErrors } from "../..";

export enum DeliveryTypes {
  DELIVERY_CITIES_REQUEST                 = "deliveryCities/REQUEST",
  DELIVERY_CITIES_SUCCESS                 = "deliveryCities/SUCCESS",
  DELIVERY_CITIES_FAILURE                 = "deliveryCities/FAILURE",

  DELIVERY_CITIES_TOGGLING_STATUS_REQUEST = "deliveryCities/TOGGLING_STATUS_REQUEST",
  DELIVERY_CITIES_TOGGLING_STATUS_SUCCESS = "deliveryCities/TOGGLING_STATUS_SUCCESS",
  DELIVERY_CITIES_TOGGLING_STATUS_FAILURE = "deliveryCities/TOGGLING_STATUS_FAILURE",

  DELIVERY_CITIES_START_EDITING           = "deliveryCities/START_CREATE",
  DELIVERY_CITIES_STOP_EDITING            = "deliveryCities/STOP_EDITING",
  DELIVERY_CITIES_EDIT_CHANGE             = "deliveryCities/EDIT_CHANGE",

  DELIVERY_CITIES_SAVE_REQUEST            = "deliveryCities/SAVE_REQUEST",
  DELIVERY_CITIES_SAVE_SUCCESS            = "deliveryCities/SAVE_SUCCESS",
  DELIVERY_CITIES_SAVE_FAILURE            = "deliveryCities/SAVE_FAILURE",

  DELIVERY_CITIES_DELETE_REQUEST          = "deliveryCities/DELETE_REQUEST",
  DELIVERY_CITIES_DELETE_SUCCESS          = "deliveryCities/DELETE_SUCCESS",
  DELIVERY_CITIES_DELETE_FAILURE          = "deliveryCities/DELETE_FAILURE",

  // Neighborhoods
  DELIVERY_NEIGHBORHOODS_ADD_FIELD        = "deliveryNeighborhoods/ADD_FIELD",
  DELIVERY_NEIGHBORHOODS_CHANGE_FIELD     = "deliveryNeighborhoods/CHANGE_FIELD",
  DELIVERY_NEIGHBORHOODS_REQUEST          = "deliveryNeighborhoods/REQUEST",
  DELIVERY_NEIGHBORHOODS_SUCCESS          = "deliveryNeighborhoods/SUCCESS",
  DELIVERY_NEIGHBORHOODS_FAILURE          = "deliveryNeighborhoods/FAILURE",

  DELIVERY_NEIGHBORHOODS_DISCART          = "deliveryNeighborhoods/DISCART",

  DELIVERY_NEIGHBORHOODS_DELETE_REQUEST   = "deliveryNeighborhoods/DELETE_REQUEST",
  DELIVERY_NEIGHBORHOODS_DELETE_SUCCESS   = "deliveryNeighborhoods/DELETE_SUCCESS",
  DELIVERY_NEIGHBORHOODS_DELETE_FAILURE   = "deliveryNeighborhoods/DELETE_FAILURE",

  DELIVERY_NEIGHBORHOODS_SAVE_REQUEST     = "deliveryNeighborhoods/SAVE_REQUEST",
  DELIVERY_NEIGHBORHOODS_SAVE_SUCCESS     = "deliveryNeighborhoods/SAVE_SUCCESS",
  DELIVERY_NEIGHBORHOODS_SAVE_FAILURE     = "deliveryNeighborhoods/SAVE_FAILURE",
}

export interface IDeliveryCity {
  id?: number;
  name: string;
  status: boolean;
  isTogglingStatus?: boolean;
  isDeleting?: boolean;
  isSaving?: boolean;
  isLoading?: boolean;
  isSavingNeighborhoods?: boolean;
  error?: SystemErrors;
  neighborhoods?: IDeliveryNeighborhood[];
}

export interface IDeliveryCityDraft {
  id?: number;
  name: string;
  status: boolean;
  isSaving?: boolean;
  error?: SystemErrors;
  isChanged?: boolean
}

export interface IDeliveryNeighborhood {
  id?: number;
  id_city: number;
  name: string;
  price: number;
  status: boolean;
  isTogglingStatus?: boolean;
  isChanged?: boolean;
  isDeleting?: boolean;
  isSaving?: boolean;
  error?: SystemErrors;
}

export interface IDeliveryState {
  data: IDeliveryCity[];
  isLoading: boolean;
  error?: SystemErrors;
  deliveryCityDraft?: IDeliveryCityDraft;
  cityDraft?: IDeliveryCityDraft
}

export interface IDeliveryCitiesListResponse{
  data?: IDeliveryCity[];
  status: boolean;
  message?: string;
}

export type IDeliveryCitiesSaveResponse = {
  data: IDeliveryCity;
  status: true;
} | {
  status: false;
  message: string;
}

export type IDeliveryCitiesDeleteResponse = {
  status: true;
} | {
  status: false;
  message: string;
}

export type IDeliveryCitiesToggleStatusResponse = {
  status: true;
  data: IDeliveryCity;
} | {
  status: false;
  message: string;
}

export interface IDeliveryNeighborhoodsListResponse{
  data?: IDeliveryNeighborhood[];
  status: boolean;
  message?: string;
}

export interface IDeliveryNeighborhoodsToggleStatusResponse {
  status: boolean;
  data?: IDeliveryNeighborhood;
  message?: string;
}

export interface IDeliveryNeighborhoodsDeleteResponse {
  status: boolean;
  data?: boolean;
  message?: string;
}

export type IDeliveryNeighborhoodsSaveRequest = {
  id?: number;
  name: string;
  price: number;
  status: boolean;
}[]

export type IDeliveryNeighborhoodsSaveResponse = {
  status: true;
  data: {
    id?: number,
    name: string,
    price: number,
    status: boolean,
    error?: SystemErrors
  }[]
}|{
  status: false;
  message: string;
}
