import { SystemErrors } from "../..";
import { IOpeningHourDraft, IOpeningHours, IOpeningHoursState } from "./interfaces";

//Toggle status
export const reducerOpeningHourToggleStatusRequest = (state: IOpeningHoursState, openingId: number): IOpeningHoursState => {
  return {
    ...state,
    data: state.data.map(openingHour => {
      if(openingHour.id !== openingId) return openingHour;

      return {
        ...openingHour,
        isTogglingStatus: true,
        error: undefined
      }
    })
  }
}

export const reducerOpeningHourToggleStatusSuccess = (state: IOpeningHoursState, newOpeningHour: IOpeningHours): IOpeningHoursState => {
  if(!state.draft) return {
    ...state,
    data: state.data.map(openingHour => {
      if(openingHour.id !== newOpeningHour.id) return openingHour;

      return {
        ...openingHour,
        isTogglingStatus: false,
        status: newOpeningHour.status,
        error: undefined
      }
    }),
  }

  return {
    ...state,
    data: state.data.map(openingHour => {
      if(openingHour.id !== newOpeningHour.id) return openingHour;

      return {
        ...openingHour,
        isTogglingStatus: false,
        status: newOpeningHour.status
      }
    }),
    draft: {
      ...state.draft,
      status: !state.draft.status
    }
  }
}

export const reducerOpeningHourToggleStatusFailure = (state: IOpeningHoursState, openingId: number, error: SystemErrors): IOpeningHoursState => {
  return {
    ...state,
    data: state.data.map(openingHour => {
      if(openingHour.id !== openingId) return openingHour;

      return {
        ...openingHour,
        isTogglingStatus: false,
        error
      }
    })
  }
}

// Set draft
export const reducerOpeningHourSetDraft = (state: IOpeningHoursState, draft: IOpeningHourDraft): IOpeningHoursState => {
  return {
    ...state,
    draft
  }
}

// save
export const reducerOpeningHourSaveRequest = (state: IOpeningHoursState, draft: IOpeningHourDraft): IOpeningHoursState => {
  return {
    ...state,
    draft: {
      ...state.draft,
      isSaving: true,
      error: undefined
    }
  }
}

export const reducerOpeningHourSaveSuccess = (state: IOpeningHoursState, newOpeningHour: IOpeningHours): IOpeningHoursState => {
  let newData = state.data.filter(openingHour => openingHour.id !== newOpeningHour.id);
  newData.push(newOpeningHour);

  newData = newData.sort((a, b) => {
    if(a.week_day < b.week_day) return -1;
    if(a.week_day > b.week_day) return 1;
    return 0;
  });

  return {
    ...state,
    data: newData,
    draft: undefined
  }
}

export const reducerOpeningHourSaveFailure = (state: IOpeningHoursState, error: SystemErrors): IOpeningHoursState => {
  return {
    ...state,
    draft: {
      ...state.draft,
      isSaving: false,
      error
    }
  }
}

// delete
export const reducerOpeningHourDeleteRequest = (state: IOpeningHoursState, openingId: number): IOpeningHoursState => {
  return {
    ...state,
    data: state.data.map(openingHour => {
      if(openingHour.id !== openingId) return openingHour;

      return {
        ...openingHour,
        isDeleting: true,
        error: undefined
      }
    })
  }
}

export const reducerOpeningHourDeleteSuccess = (state: IOpeningHoursState, openingId: number): IOpeningHoursState => {
  return {
    ...state,
    data: state.data.filter(openingHour => openingHour.id !== openingId)
  }
}

export const reducerOpeningHourDeleteFailure = (state: IOpeningHoursState, openingId: number, error: SystemErrors): IOpeningHoursState => {
  return {
    ...state,
    data: state.data.map(openingHour => {
      if(openingHour.id !== openingId) return openingHour;

      return {
        ...openingHour,
        isDeleting: false,
        error
      }
    })
  }
}
