import { serviceProductConfigurationOptionDelete, serviceProductConfigurationsSave, serviceProductConfigurationRemove, serviceProductRemove, serviceProductSave, serviceProductsRequest, serviceProductRemovePhoto, serviceProductToggleStatus, serviceProductDuplicateSave, serviceProductConfigurationReorder } from "./services";
import { put, takeLatest, takeEvery, all, select, SelectEffect } from 'redux-saga/effects'
import { actionProductsFailure, actionProductsSuccess, ProductsActions, actionProductsSaveSuccess, actionProductsSaveFailure, actionProductEditingSetStep, actionProductConfigurationSaveFailure, actionProductConfigurationSaveSuccess, actionProductConfigurationOptionRemoveSuccess, actionProductConfigurationOptionRemoveFailure, actionProductRemoveSuccess, actionProductRemoveFailure, actionProductConfigurationRemoveSuccess, actionProductConfigurationRemoveFailure, actionProductRemovePhotoSuccess, actionProductRemovePhotoFailure, actionProductToggleStatusSuccess, actionProductToggleStatusFailure, actionProductSaveDuplicateSuccess, actionProductSaveDuplicateFailure, actionProductConfigurationRearrangingSaveFailure, actionProductConfigurationRearrangingSaveSuccess } from "./actions";
import { IApplicationState } from "..";
import { IProductDuplicateResponse, IProductsState } from "./interfaces";
import { AnyAction } from "redux";
import { AxiosResponse } from "axios";

export function selectState<T>(selector: (s: IApplicationState) => T): SelectEffect {
  return select(selector);
}

const getProductsState = (state: IApplicationState): IProductsState => state.products;

// Request
function* sagaProductsRequest() {
  let data_response: any = false;
  let error: any = false;

  yield serviceProductsRequest()
    .then(({ data }) => {
      if (data.status) {
        if (Array.isArray(data.data)) {
          data_response = data.data
        }
        else data_response = [data.data];
      }
    })
    .catch(err => {
      console.log('err',err);

      if (err.response.data) {
        error = {
          message: err.response.data.message || "Erro inesperado ao carregar lista de produtos"
        }
      }
    })

  if (data_response) yield put(actionProductsSuccess(data_response))
  if (error) yield put(actionProductsFailure(error));
}

function* watchProductsRequestSaga() {
  yield takeLatest(ProductsActions.PRODUCTS_REQUEST, sagaProductsRequest);
}

// Save
function* sagaProductSave(action: AnyAction) {
  let data_response: any = false;
  let error: any = false;

  const productState: IProductsState = yield selectState<IProductsState>(getProductsState);

  if (productState.draft) {
    yield serviceProductSave(productState.draft)
      .then(({ data }) => {
        if (data.status) {

          data_response = data.data

        }
      })
      .catch(err => {
        if (err.response) {
          error = {
            message: err.response.data.message || "Erro inesperado ao salvar produto"
          }
        }
      })

      if (data_response) {

      const productState: IProductsState = yield selectState<IProductsState>(getProductsState);

      if (productState.editingStep === 0) {
        yield all([
          put(actionProductsSaveSuccess(data_response)),
          put(actionProductEditingSetStep(1))
        ])
      }
      else {
        yield all([
          put(actionProductsSaveSuccess(data_response))
        ])
      }
    }
    if (error) yield put(actionProductsSaveFailure(error));
  }

  else yield put(actionProductsSaveFailure({
    message: "Nenhum dado para ser salvo"
  }));

}

function* watchProductSaveSaga() {
  yield takeEvery(ProductsActions.PRODUCTS_SAVE_REQUEST, sagaProductSave);
}

// Remove
function* sagaProductRemove(action: AnyAction) {
  let data_response: any = false;
  let error: any = false;

  yield serviceProductRemove(action.product_id)
  .then(({ data }) => {
    if (data.status) {

      data_response = data

    }
  })
  .catch(err => {
    if (err.response) {
      error = {
        message: err.response.data.message || "Erro inesperado ao salvar produto"
      }
    }
  })

  if (data_response) yield put(actionProductRemoveSuccess(action.product_id))
  if (error) yield put(actionProductRemoveFailure(action.product_id, error));
}

function* watchProductRemoveSaga() {
  yield takeEvery(ProductsActions.PRODUCTS_REMOVE_REQUEST, sagaProductRemove);
}

// Remove Photo
function* sagaProductRemovePhoto(action: AnyAction) {
  let data_response: any = false;
  let error: any = false;

  yield serviceProductRemovePhoto(action.product_id)
  .then(({ data }) => {
    if (data.status) {

      data_response = data

    }
  })
  .catch(err => {
    if (err.response) {
      error = {
        message: err.response.data.message || "Erro inesperado ao salvar produto"
      }
    }
  })

  if (data_response) yield put(actionProductRemovePhotoSuccess(action.product_id))
  if (error) yield put(actionProductRemovePhotoFailure(action.product_id, error));
}

function* watchProductRemovePhotoSaga() {
  yield takeLatest(ProductsActions.PRODUCTS_REMOVE_PHOTO_REQUEST, sagaProductRemovePhoto);
}

// Toggle Status
function* sagaProductToggleStatus(action: AnyAction) {
  let data_response: any = false;
  let error: any = false;

  yield serviceProductToggleStatus(action.product_id)
  .then(({ data }) => {
    if (data.status) {

      data_response = data

    }
  })
  .catch(err => {
    if (err.response) {
      error = {
        message: err.response.data.message || "Erro inesperado ao salvar produto"
      }
    }
  })

  if (data_response) yield put(actionProductToggleStatusSuccess(action.product_id))
  if (error) yield put(actionProductToggleStatusFailure(action.product_id, error));
}

function* watchProductToggleStatusSaga() {
  yield takeEvery(ProductsActions.PRODUCTS_TOGGLE_STAUTS_REQUEST, sagaProductToggleStatus);
}

// product configuration
// Save
function* sagaProductConfigurationSave(action: AnyAction) {
  let data_response: any = false;
  let error: any = false;

  const productState: IProductsState = yield selectState<IProductsState>(getProductsState);

  if (productState.draft?.id) {
    yield serviceProductConfigurationsSave(action.configuration, productState.draft.id)
      .then(({ data }) => {
        if (data.status) {
          data_response = data.data
        }
      })
      .catch(err => {
        if (err.response) {
          error = {
            message: err.response.data.message || "Erro inesperado ao salvar configuração"
          }
        }
      })

    if (data_response) {

      yield put(actionProductConfigurationSaveSuccess(data_response, action.configuration_index))
    }
    if (error) yield put(actionProductConfigurationSaveFailure(action.configuration_index, error));
  }

  else yield put(actionProductConfigurationSaveFailure(action.configuration_index, {
    message: "Nenhum dado para ser salvo"
  }));

}

function* watchProductConfigurationSaveSaga() {
  yield takeEvery(ProductsActions.PRODUCTS_CONFIGS_SAVE_REQUEST, sagaProductConfigurationSave);
}

// product configuration delete
function* sagaProductConfigurationDeleteRequest(action: AnyAction) {
  let data_response: any = false;
  let error: any = false;

  const productState: IProductsState = yield selectState<IProductsState>(getProductsState);

  if (productState.draft?.id) {
    yield serviceProductConfigurationRemove(action.configuration_id)
      .then(({ data }) => {
        if (data.status) {
          data_response = data
        }
      })
      .catch(err => {
        if (err.response) {
          error = {
            message: err.response.data.message || "Erro inesperado ao remover opção"
          }
        }
      })

    if (data_response) {
      yield put(actionProductConfigurationRemoveSuccess(action.configuration_id))
    }
    if (error) yield put(actionProductConfigurationRemoveFailure(action.uuid, error));
  }

  else yield put(actionProductConfigurationRemoveFailure(action.uuid, {
    message: "Erro inesperado ao tentar remover configuração"
  }));

}

function* watchProductConfigurationDeleteSaga() {
  yield takeEvery(ProductsActions.PRODUCTS_CONFIGS_REMOVE_REQUEST, sagaProductConfigurationDeleteRequest);
}

// option delete
function* sagaProductConfigurationOptionDeleteRequest(action: AnyAction) {
  let data_response: any = false;
  let error: any = false;

  const productState: IProductsState = yield selectState<IProductsState>(getProductsState);

  if (productState.draft?.id) {
    yield serviceProductConfigurationOptionDelete(action.option_id)
      .then(({ data }) => {
        if (data.status) {
          data_response = data.data
        }
      })
      .catch(err => {
        if (err.response) {
          error = {
            message: err.response.data.message || "Erro inesperado ao remover opção"
          }
        }
      })

    if (data_response) {
      yield put(actionProductConfigurationOptionRemoveSuccess(action.configuration_index, action.option_id))
    }
    if (error) yield put(actionProductConfigurationOptionRemoveFailure(action.configuration_index, action.option_id, error));
  }

  else yield put(actionProductConfigurationOptionRemoveFailure(action.configuration_index, action.option_id, {
    message: "Nenhuma opção para remover"
  }));

}

function* watchProductConfigurationOptionDeleteSaga() {
  yield takeEvery(ProductsActions.PRODUCTS_CONFIGS_OPTION_REMOVE_REQUEST, sagaProductConfigurationOptionDeleteRequest);
}

// Duplicate Save
function* sagaProductDuplicateSaveRequest(action: AnyAction) {
  try{
    const productState: IProductsState = yield selectState<IProductsState>(getProductsState);

    if (productState.duplicate?.id) {
      const duplicateResponse: AxiosResponse<IProductDuplicateResponse> = yield serviceProductDuplicateSave(action.product);

      const response = duplicateResponse.data;

      if(response.status){
        yield put(actionProductSaveDuplicateSuccess(response));
      }
      else {
        yield put(actionProductSaveDuplicateFailure({
          message: response.message || "Erro inesperado ao duplicar este produto",
        }))
      }
    }
    else yield put(actionProductSaveDuplicateFailure({
      message: "Nenhuma produto para duplicar"
    }));
  }
  catch(error: any){
    yield put(actionProductSaveDuplicateFailure({
      message: error?.response?.data?.message || "Erro inesperado ao duplicar este produto",
    }))
  }
}

function* watchProductDuplicateSaveSaga() {
  yield takeEvery(ProductsActions.PRODUCTS_SAVE_DUPLICATE_REQUEST, sagaProductDuplicateSaveRequest);
}

// Reorder
function* sagaProductConfigurationsReorderSaveRequest(action: AnyAction) {
  let data_response: any = false;
  let error: any = false;

  const productState: IProductsState = yield selectState<IProductsState>(getProductsState);

  if(productState?.draft?.rearranging && productState.draft.id){
    yield serviceProductConfigurationReorder(productState?.draft?.rearranging?.data, productState.draft.id)
      .then(({ data }) => {
        if (data.status) {
          data_response = data.data
        }
      })
      .catch(err => {
        if (err.response.data) {
          error = {
            message: err.response.data.message || "Erro inesperado ao salvar ordem de configurações"
          }
        }
        else {
          error = {
            message: "Erro inesperado ao salvar ordem de configurações"
          }
        }
      })

    if (data_response) yield put(actionProductConfigurationRearrangingSaveSuccess(data_response))
    if (error) yield put(actionProductConfigurationRearrangingSaveFailure(error));
  }
  else {
    yield put(actionProductConfigurationRearrangingSaveFailure({
      message: "Não foi possível reorganizar categorias. Atalize a página e tente novamente mais tarde!"
    }));
  }
}

function* watchProductConfigurationsReorderRequestSaga() {
  yield takeLatest(ProductsActions.PRODUCTS_REARRANGING_SAVE_REQUEST, sagaProductConfigurationsReorderSaveRequest);
}

export default all([
  watchProductsRequestSaga(),
  watchProductSaveSaga(),
  watchProductRemoveSaga(),
  watchProductConfigurationSaveSaga(),
  watchProductConfigurationDeleteSaga(),
  watchProductConfigurationOptionDeleteSaga(),
  watchProductRemovePhotoSaga(),
  watchProductToggleStatusSaga(),
  watchProductDuplicateSaveSaga(),
  watchProductConfigurationsReorderRequestSaga()
])
