import { SystemErrors } from "../..";
import { DeliveryTypes, IDeliveryCity, IDeliveryCityDraft, IDeliveryNeighborhood, IDeliveryNeighborhoodsSaveRequest } from "./interfaces";

export const actionStartEditingDeliveryCity = (city: IDeliveryCityDraft) => ({
  type: DeliveryTypes.DELIVERY_CITIES_START_EDITING,
  city
})

export const actionEditingChangeDeliveryCity = (city?: IDeliveryCityDraft) => ({
  type: DeliveryTypes.DELIVERY_CITIES_EDIT_CHANGE,
  city
})

export const actionStopEditingDeliveryCity = () => ({
  type: DeliveryTypes.DELIVERY_CITIES_STOP_EDITING
})

// list delivery cities
export const actionListDeliveryCitiesRequest = () => ({
  type: DeliveryTypes.DELIVERY_CITIES_REQUEST
})

export const actionListDeliveryCitiesSuccess = (data: IDeliveryCity[]) => ({
  type: DeliveryTypes.DELIVERY_CITIES_SUCCESS,
  data
})

export const actionListDeliveryCitiesFailure = (error: SystemErrors) => ({
  type: DeliveryTypes.DELIVERY_CITIES_FAILURE,
  error
})

// save delivery city
export const actionSaveDeliveryCitiesRequest = (city: IDeliveryCityDraft) => ({
  type: DeliveryTypes.DELIVERY_CITIES_SAVE_REQUEST,
  city
})

export const actionSaveDeliveryCitiesSuccess = (city: IDeliveryCity) => ({
  type: DeliveryTypes.DELIVERY_CITIES_SAVE_SUCCESS,
  city
})

export const actionSaveDeliveryCitiesFailure = (error: SystemErrors) => ({
  type: DeliveryTypes.DELIVERY_CITIES_SAVE_FAILURE,
  error
})

// toggle status delivery cities
export const actionToggleStatusDeliveryCitiesRequest = (idCity: number) => ({
  type: DeliveryTypes.DELIVERY_CITIES_TOGGLING_STATUS_REQUEST,
  idCity
})

export const actionToggleStatusDeliveryCitiesSuccess = (idCity:number, status: boolean) => ({
  type: DeliveryTypes.DELIVERY_CITIES_TOGGLING_STATUS_SUCCESS,
  status,
  idCity
})

export const actionToggleStatusDeliveryCitiesFailure = (idCity:number, error: SystemErrors) => ({
  type: DeliveryTypes.DELIVERY_CITIES_TOGGLING_STATUS_FAILURE,
  idCity,
  error
})

// delete delivery cities
export const actionDeleteDeliveryCitiesRequest = (idCity: number) => ({
  type: DeliveryTypes.DELIVERY_CITIES_DELETE_REQUEST,
  idCity
})

export const actionDeleteDeliveryCitiesSuccess = (idCity:number) => ({
  type: DeliveryTypes.DELIVERY_CITIES_DELETE_SUCCESS,
  idCity
})

export const actionDeleteDeliveryCitiesFailure = (idCity:number, error: SystemErrors) => ({
  type: DeliveryTypes.DELIVERY_CITIES_DELETE_FAILURE,
  idCity,
  error
})

// list delivery neighborhoods
export const actionListDeliveryNeighborhoodsRequest = (idCity: number) => ({
  type: DeliveryTypes.DELIVERY_NEIGHBORHOODS_REQUEST,
  idCity
})

export const actionListDeliveryNeighborhoodsSuccess = (idCity:number, data: IDeliveryNeighborhood[]) => ({
  type: DeliveryTypes.DELIVERY_NEIGHBORHOODS_SUCCESS,
  idCity,
  data
})

export const actionListDeliveryNeighborhoodsFailure = (idCity:number, error: SystemErrors) => ({
  type: DeliveryTypes.DELIVERY_NEIGHBORHOODS_FAILURE,
  idCity,
  error
})

// delivery neighborhoods fields
export const actiondeliveryNeighborhoosAddField = (idCity: number) => ({
  type: DeliveryTypes.DELIVERY_NEIGHBORHOODS_ADD_FIELD,
  idCity
})

export const actiondeliveryNeighborhoosChangeField = (idCity: number, index: number, neighborhood: IDeliveryNeighborhood) => ({
  type: DeliveryTypes.DELIVERY_NEIGHBORHOODS_CHANGE_FIELD,
  idCity,
  index,
  neighborhood
})

// delete delivery neighborhoods
export const actionDiscartDeliveryNeighborhoods = (idCity: number, index: number) => ({
  type: DeliveryTypes.DELIVERY_NEIGHBORHOODS_DISCART,
  idCity,
  index
})

export const actionDeleteDeliveryNeighborhoodsRequest = (idCity: number, idNeighborhood: number) => ({
  type: DeliveryTypes.DELIVERY_NEIGHBORHOODS_DELETE_REQUEST,
  idCity,
  idNeighborhood
})

export const actionDeleteDeliveryNeighborhoodsSuccess = (idCity:number, idNeighborhood:number) => ({
  type: DeliveryTypes.DELIVERY_NEIGHBORHOODS_DELETE_SUCCESS,
  idCity,
  idNeighborhood
})

export const actionDeleteDeliveryNeighborhoodsFailure = (idCity:number, idNeighborhood:number, error: SystemErrors) => ({
  type: DeliveryTypes.DELIVERY_NEIGHBORHOODS_DELETE_FAILURE,
  error,
  idCity,
  idNeighborhood
})

// save delivery neighborhoods
export const actionSaveDeliveryNeighborhoodsRequest = (idCity: number, neighborhoods: IDeliveryNeighborhoodsSaveRequest) => ({
  type: DeliveryTypes.DELIVERY_NEIGHBORHOODS_SAVE_REQUEST,
  idCity,
  neighborhoods
})

export const actionSaveDeliveryNeighborhoodsSuccess = (idCity:number, neighborhoods: IDeliveryNeighborhood[]) => ({
  type: DeliveryTypes.DELIVERY_NEIGHBORHOODS_SAVE_SUCCESS,
  idCity,
  neighborhoods
})

export const actionSaveDeliveryNeighborhoodsFailure = (idCity:number, error: SystemErrors) => ({
  type: DeliveryTypes.DELIVERY_NEIGHBORHOODS_SAVE_FAILURE,
  error,
  idCity
})
