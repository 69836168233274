export const NumberFormat = (origin_number: string | number, decimals: number, dec_point: string, thousands_sep: string) => {

  origin_number = (origin_number + '').replace(/[^0-9+\-Ee.]/g, '');
  var n = !isFinite(+origin_number) ? 0 : +origin_number,
    prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
    sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
    dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
    s = [],
    toFixedFix = function (n: number, prec: number) {
      var k = Math.pow(10, prec);
      return '' + Math.round(n * k) / k;
    };
  // Fix for IE parseFloat(0.55).toFixed(0) = 0;
  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || '').length < prec) {
    s[1] = s[1] || '';
    s[1] += new Array(prec - s[1].length + 1).join('0');
  }
  return s.join(dec);
}

export const getWeekDayName = (weekDay: number) => {
  const weekDayNames = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'];
  return weekDayNames[weekDay];
}

export const timeMask = (v: string) => {
  var r = v.replace(/\D/g, "");
  // r = r.replace(/^0/,"");
  r = r.substring(0, 4);
  if (r.length > 3) {
    r = r.replace(/^([0-1][0-9]|2[0-3])([0-5][0-9])/, "$1:$2");
  }
  else if (r.length > 2) {
    r = r.replace(/^([0-1][0-9]|2[0-3])([0-5])/, "$1:$2");
  }
  else {
    r = r.replace(/^([0-1][0-9]|2[0-3])/, "$1");
  }
  return r;
}
