import * as OrdersActions from '../actions/OrdersActions';
import OrdersService from '../../services/OrdersService';  

import {put, takeLatest} from 'redux-saga/effects';


function* listAll() {
    let data = null;
    yield OrdersService.list()
    .then(result => {
        data = result.data;

        if(data.status) data = data.data;
        
    })
    yield put(OrdersActions.listResponse(data))
}

function* watchListAll(){
    yield takeLatest(OrdersActions.ORDER_LIST, listAll);
}

export default function* OrdersSaga(){
    yield watchListAll()
}