import { AxiosResponse } from "axios"
import api from "../../services/api"
import auth from "../../services/auth"
import { IPaymentMethodResponse, IPaymentMethodToggleStatusResponse } from "./interfaces"

export const servicePaymentMethodsRequest = () => {
  return api.get<IPaymentMethodResponse, AxiosResponse<IPaymentMethodResponse>>('/settings/payment-methods', {
    headers: { Authorization: `Bearer ${auth.getToken()}` }
  })
}

export const servicePaymentMethodsToggleStatusRequest = (idPayment: string) => {
  return api.get<IPaymentMethodToggleStatusResponse, AxiosResponse<IPaymentMethodToggleStatusResponse>>(`/settings/payment-methods/toggle-status/${idPayment}`, {
    headers: { Authorization: `Bearer ${auth.getToken()}` }
  })
}
