import { SystemErrors } from "../..";
import { ILinkSettings, LinkTypes } from "./interfaces";

export const actionGetLinkSettingsRequest = () => ({
  type: LinkTypes.LINK_GET_SETTINGS_REQUEST
})

export const actionGetLinkSettingsSuccess = (linkSettings: ILinkSettings) => ({
  type: LinkTypes.LINK_GET_SETTINGS_SUCCESS,
  linkSettings
})

export const actionGetLinkSettingsFailure = (error: SystemErrors) => ({
  type: LinkTypes.LINK_GET_SETTINGS_FAILURE,
  error
})

// slug availability
export const actionValidateSlugRequest = (slug: string) => ({
  type: LinkTypes.LINK_VALIDITY_SLUG_REQUEST,
  slug
})

export const actionValidateSlugSuccess = () => ({
  type: LinkTypes.LINK_VALIDITY_SLUG_SUCCESS
})

export const actionValidateSlugFailure = (error: SystemErrors) => ({
  type: LinkTypes.LINK_VALIDITY_SLUG_FAILURE,
  error
})

// save slug
export const actionSaveSlugRequest = (slug: string) => ({
  type: LinkTypes.LINK_SAVE_SLUG_REQUEST,
  slug
})

export const actionSaveSlugSuccess = (slug: string) => ({
  type: LinkTypes.LINK_SAVE_SLUG_SUCCESS,
  slug
})

export const actionSaveSlugFailure = (error: SystemErrors) => ({
  type: LinkTypes.LINK_SAVE_SLUG_FAILURE,
  error
})

// save features
export const actionSaveFeaturesRequest = (features: ILinkSettings['features']) => ({
  type: LinkTypes.LINK_SAVE_FEATURES_REQUEST,
  features
})

export const actionSaveFeaturesSuccess = (features: ILinkSettings['features']) => ({
  type: LinkTypes.LINK_SAVE_FEATURES_SUCCESS,
  features
})

export const actionSaveFeaturesFailure = (error: SystemErrors) => ({
  type: LinkTypes.LINK_SAVE_FEATURES_FAILURE,
  error
})

// order types
export const actionSaveOrderTypesRequest = (orderTypes: ILinkSettings['orderTypes']) => ({
  type: LinkTypes.LINK_SAVE_ORDER_TYPES_REQUEST,
  orderTypes
})

export const actionSaveOrderTypesSuccess = (orderTypes: ILinkSettings['orderTypes']) => ({
  type: LinkTypes.LINK_SAVE_ORDER_TYPES_SUCCESS,
  orderTypes
})

export const actionSaveOrderTypesFailure = (error: SystemErrors) => ({
  type: LinkTypes.LINK_SAVE_ORDER_TYPES_FAILURE,
  error
})
