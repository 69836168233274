import { SystemErrors } from "../..";
import { AppearanceTypes, IAppearance } from "./interfaces";

export const actionAppearanceRequest = () => ({
  type: AppearanceTypes.APPEARANCE_REQUEST
});

export const actionAppearanceSuccess = (data: IAppearance) => ({
  type: AppearanceTypes.APPEARANCE_SUCCESS,
  data
});

export const actionAppearanceFailure = (error: SystemErrors) => ({
  type: AppearanceTypes.APPEARANCE_FAILURE,
  error
});

// save
export const actionAppearanceSaveRequest = (data: IAppearance) => ({
  type: AppearanceTypes.APPEARANCE_SAVE_REQUEST,
  data
});

export const actionAppearanceSaveSuccess = (data: IAppearance) => ({
  type: AppearanceTypes.APPEARANCE_SAVE_SUCCESS,
  data
});

export const actionAppearanceSaveFailure = (error: SystemErrors) => ({
  type: AppearanceTypes.APPEARANCE_SAVE_FAILURE,
  error
});

// logo
export const actionAppearanceStartUpdateLogoLogo = () => ({
  type: AppearanceTypes.APPEARANCE_START_UPDATE_LOGO
});

export const actionAppearanceStopUpdateLogoLogo = () => ({
  type: AppearanceTypes.APPEARANCE_STOP_UPDATE_LOGO
});

export const actionAppearanceSetTempLogo = (tempLogo: string) => ({
  type: AppearanceTypes.APPEARANCE_SET_TEMP_LOGO,
  tempLogo
});

// remove logo
export const actionAppearanceRemoveLogoRequest = () => ({
  type: AppearanceTypes.APPEARANCE_REMOVE_LOGO_REQUEST
});

export const actionAppearanceRemoveLogoSuccess = () => ({
  type: AppearanceTypes.APPEARANCE_REMOVE_LOGO_SUCCESS
});

export const actionAppearanceRemoveLogoFailure = (error: SystemErrors) => ({
  type: AppearanceTypes.APPEARANCE_REMOVE_LOGO_FAILURE,
  error
});

// save logo
export const actionAppearanceSaveLogoRequest = (newLogo: string) => ({
  type: AppearanceTypes.APPEARANCE_SAVE_LOGO_REQUEST,
  newLogo
});

export const actionAppearanceSaveLogoSuccess = () => ({
  type: AppearanceTypes.APPEARANCE_SAVE_LOGO_SUCCESS
});

export const actionAppearanceSaveLogoFailure = (error: SystemErrors) => ({
  type: AppearanceTypes.APPEARANCE_SAVE_LOGO_FAILURE,
  error
});
