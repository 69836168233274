import { SystemErrors } from "../..";
import { IProductCategoriesState, IProductCategory, IProductCategoryDraft, IProductCategoryOrderMap } from "./interfaces";

//Toggle status
export const reducerProductCategoryToggleStatusRequest = (state: IProductCategoriesState, category_id: number): IProductCategoriesState => {
  return {
    ...state,
    data: state.data.map(category => {
      if(category.id !== category_id) return category;

      return {
        ...category,
        isTogglingStatus: true
      }
    })
  }
}

export const reducerProductCategoryToggleStatusSuccess = (state: IProductCategoriesState, category: IProductCategory): IProductCategoriesState => {
  return {
    ...state,
    data: state.data.map(old_category => {
      if(old_category.id !== category.id) return old_category;

      return {
        ...old_category,
        isTogglingStatus: false,
        status: category.status
      }
    }),
  }
}

export const reducerProductCategoryToggleStatusFailure = (state: IProductCategoriesState, category_id: number, error: SystemErrors): IProductCategoriesState => {
  return {
    ...state,
    data: state.data.map(category => {
      if(category.id !== category_id) return category;

      return {
        ...category,
        isTogglingStatus: false,
        error
      }
    })
  }
}

export const reducerProductCategoryChangeDraft = (state: IProductCategoriesState, category: IProductCategoryDraft): IProductCategoriesState => {
  return {
    ...state,
    draft: {
      ...category,
      isChanged: true
    }
  }
}

export const reducerProductCategoryChangeInfo = (state: IProductCategoriesState, category: IProductCategory): IProductCategoriesState => {
  if(!category.id) return {...state};

  return {
    ...state,
    data: state.data.map(old_category => {
      if(old_category.id !== category.id) return old_category;

      return {
        ...category,
        isChanged: true
      }
    })
  }
}

export const reducerProductCategorySaveRequest = (state: IProductCategoriesState, category?: IProductCategory): IProductCategoriesState => {
  if(!category) return {
    ...state,
    isSaving: true
  }

  return {
    ...state,
    data: state.data.map(old_category => {
      if(old_category.id !== category.id) return old_category;

      return {
        ...old_category,
        isSaving: true,
        error: undefined
      }
    })
  }
}

export const reducerProductCategorySaveSuccess = (state: IProductCategoriesState, category: IProductCategory): IProductCategoriesState => {
  const [selected_category] = state.data.filter(old_category => old_category.id === category.id)

  if(!selected_category) return {
    ...state,
    isSaving: false,
    draft: null,
    data: [...state.data, category]
  }

  return {
    ...state,
    data: state.data.map(old_category => {
      if(old_category.id !== category.id) return old_category;

      return {
        ...category,
        isSaving: false,
        isChanged: false,
        error: undefined
      }
    })
  }
}

export const reducerProductCategorySaveFailure = (state: IProductCategoriesState, error: SystemErrors, category_id?: number): IProductCategoriesState => {
  if(!category_id) return {
    ...state,
    isSaving: false,
    error: error
  }

  return {
    ...state,
    data: state.data.map(old_category => {
      if(old_category.id !== category_id) return old_category;

      return {
        ...old_category,
        isSaving: false,
        error
      }
    })
  }
}


export const reducerProductCategoryRemoveRequest = (state: IProductCategoriesState, category_id:number): IProductCategoriesState => {
  return {
    ...state,
    data: state.data.map(old_category => {
      if(old_category.id !== category_id) return old_category;

      return {
        ...old_category,
        isDeleting: true,
        error: undefined
      }
    })
  }
}

export const reducerProductCategoryRemoveSuccess = (state: IProductCategoriesState, category_id: number): IProductCategoriesState => {
  return {
    ...state,
    data: state.data.filter(old_category => old_category.id !== category_id)
  }
}

export const reducerProductCategoryRemoveFailure = (state: IProductCategoriesState, error: SystemErrors, category_id: number): IProductCategoriesState => {
  return {
    ...state,
    data: state.data.map(old_category => {
      if(old_category.id !== category_id) return old_category;

      return {
        ...old_category,
        isDeleting: false,
        error
      }
    })
  }
}

//Rearranging
export const reducerProductCategoryStartRearranging = (state: IProductCategoriesState): IProductCategoriesState => {
  return {
    ...state,
    rearranging:{
      data: state.data.map(category => ({
        id: category.id || 0,
        description: category.name
      }) )
    }
  }
}

export const reducerProductCategoryUpdateRearranging = (state: IProductCategoriesState, data: IProductCategoryOrderMap[]): IProductCategoriesState => {
  return {
    ...state,
    rearranging:{
      data: data
    }
  }
}

export const reducerProductCategoryStopRearranging = (state: IProductCategoriesState): IProductCategoriesState => {
  return {
    ...state,
    rearranging:undefined
  }
}


export const reducerProductCategoryRearrangingSaveRequest = (state: IProductCategoriesState): IProductCategoriesState => {
  return {
    ...state,
    isRearranging: true
  }
}

export const reducerProductCategoryRearrangingSaveSuccess = (state: IProductCategoriesState, categories: IProductCategory[]): IProductCategoriesState => {
  return {
    ...state,
    data: categories,
    isRearranging: false,
    rearranging: undefined
  }
}

export const reducerProductCategoryRearrangingSaveFailure = (state: IProductCategoriesState, error: SystemErrors): IProductCategoriesState => {
  if(!state.rearranging) return {...state}

  return {
    ...state,
    isRearranging: false,
    rearranging: {
      ...state.rearranging,
      error: error
    }
  }
}
