import { AxiosResponse } from "axios"
import api from "../../services/api"
import auth from "../../services/auth"
import { ILinkSettingsResponse, ISaveFeaturesRequest, ISaveFeaturesResponse, ISaveOrderTypesRequest, ISaveOrderTypesResponse, ISaveSlugResponse } from "./interfaces"

export const serviceLinkSettingsRequest = () => {
  return api.get<ILinkSettingsResponse, AxiosResponse<ILinkSettingsResponse>>('/settings/link-settings', {
    headers: { Authorization: `Bearer ${auth.getToken()}` }
  })
}

export const serviceLinkCheckSlugAvailabilityRequest = (slug: string) => {
  return api.get<ILinkSettingsResponse, AxiosResponse<ILinkSettingsResponse>>(`/settings/link-settings/slug-availability?slug=${slug}`, {
    headers: { Authorization: `Bearer ${auth.getToken()}` }
  })
}

export const serviceLinkSaveSlugRequest = (slug: string) => {
  return api.put<ILinkSettingsResponse, AxiosResponse<ISaveSlugResponse>>(`/settings/link-settings/slug`,
  {
    slug
  },
  {
    headers: { Authorization: `Bearer ${auth.getToken()}` }
  })
}

export const serviceLinkSaveFeaturesRequest = (data: ISaveFeaturesRequest) => {
  return api.put<ISaveFeaturesResponse, AxiosResponse<ISaveFeaturesResponse>>(`/settings/link-settings/features`,
  {
    ...data
  },
  {
    headers: { Authorization: `Bearer ${auth.getToken()}` }
  })
}

export const serviceLinkSaveOrderTypesRequest = (data: ISaveOrderTypesRequest) => {
  return api.put<ISaveOrderTypesResponse, AxiosResponse<ISaveOrderTypesResponse>>(`/settings/link-settings/orders-types`,
  {
    ...data
  },
  {
    headers: { Authorization: `Bearer ${auth.getToken()}` }
  })
}
