import { all } from "@redux-saga/core/effects";
import productsSaga from './products/sagas';
import productCategoriesSaga from './productCategories/sagas';
import openingHoursSaga from './openingHours/sagas'
import deliveryCitiesSaga from './delivery/sagas';
import appearanceSaga from './appearance/sagas';
import linkSaga from './link/sagas';
import paymentMethodsSaga from './paymentsMethods/sagas';

export default function* rootSaga(): any {
  return yield all([
    productsSaga,
    productCategoriesSaga,
    openingHoursSaga,
    deliveryCitiesSaga,
    appearanceSaga,
    linkSaga,
    paymentMethodsSaga
  ])
}
