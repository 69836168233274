import * as OrdersActions from '../actions/OrdersActions'

const OrdersReducer = (ordersList = [], action) => {
    switch(action.type){
        case OrdersActions.ORDER_LIST_RESPONSE:
            return action.orderList;

        default: return ordersList;
    }
}

export default  OrdersReducer;