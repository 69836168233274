import { SystemErrors } from "../..";
import { IPaymentMethodsState } from "./interfaces"

// toggle payment status
export const reducerPaymentMethodToggleStatusRequest = (state: IPaymentMethodsState, id: string): IPaymentMethodsState => {
  return {
    ...state,
    data: state.data.map(paymentMethod => {
      if(paymentMethod.id !== id) return paymentMethod;
      return {
        ...paymentMethod,
        isTogglingStatus: true,
      }
    }),
    error: undefined
  }
}

export const reducerPaymentMethodToggleStatusSuccess = (state: IPaymentMethodsState, id: string, status: boolean): IPaymentMethodsState => {
  return {
    ...state,
    data: state.data.map(paymentMethod => {
      if(paymentMethod.id !== id) return paymentMethod;
      return {
        ...paymentMethod,
        isTogglingStatus: false,
        isActive: status,
      }
    }),
    error: undefined
  }
}

export const reducerPaymentMethodToggleStatusFailure = (state: IPaymentMethodsState, id:string,  error: SystemErrors): IPaymentMethodsState => {
  return {
    ...state,
    data: state.data.map(paymentMethod => {
      if(paymentMethod.id !== id) return paymentMethod;
      return {
        ...paymentMethod,
        isTogglingStatus: false
      }
    }),
    error
  }
}
