import { SystemErrors } from "../..";
import { IAppearance, IAppearanceState } from "./interfaces";

// get appearance
export const reducerAppearenceRequest = (state: IAppearanceState) : IAppearanceState => {
  return {
    ...state,
    error: undefined,
    isLoading: true
  };
}

export const reducerAppearenceSuccess = (state: IAppearanceState, appearance: IAppearance) : IAppearanceState => {
  return {
    ...state,
    data: appearance,
    error: undefined,
    isLoading: false
  };
}

export const reducerAppearenceFailure = (state: IAppearanceState, error: SystemErrors) : IAppearanceState => {
  return {
    ...state,
    error,
    isLoading: false
  };
}

// save appearance
export const reducerAppearenceSaveRequest = (state: IAppearanceState) : IAppearanceState => {
  return {
    ...state,
    saveError: undefined,
    isSaving: true
  };
}

export const reducerAppearenceSaveSuccess = (state: IAppearanceState, appearance: IAppearance) : IAppearanceState => {
  return {
    ...state,
    data: appearance,
    saveError: undefined,
    isSaving: false
  };
}

export const reducerAppearenceSaveFailure = (state: IAppearanceState, error: SystemErrors) : IAppearanceState => {
  return {
    ...state,
    saveError: error,
    isSaving: false
  };
}

// logo actions
export const reducerAppearenceStartUpdateLogo = (state: IAppearanceState) : IAppearanceState => {
  return {
    ...state,
    data: {
      ...state.data,
    },
    isUpdatingLogo: true
  };
}

export const reducerAppearenceStopUpdateLogo = (state: IAppearanceState) : IAppearanceState => {
  return {
    ...state,
    data: {
      ...state.data,
    },
    isUpdatingLogo: false
  };
}

export const reducerAppearenceSetTempLogo = (state: IAppearanceState, tempLogo: string) : IAppearanceState => {
  return {
    ...state,
    data: {
      ...state.data,
      tmpLogo: tempLogo
    }
  };
}

// removeLogo
export const reducerAppearenceRemoveLogoRequest = (state: IAppearanceState) : IAppearanceState => {
  return {
    ...state,
    logoError: undefined,
    isDeletingLogo: true
  };
}

export const reducerAppearenceRemoveLogoSuccess = (state: IAppearanceState) : IAppearanceState => {
  return {
    ...state,
    data: {
      ...state.data,
      logo: undefined
    },
    logoError: undefined,
    isDeletingLogo: false,
    isUpdatingLogo: false
  };
}

export const reducerAppearenceRemoveLogoFailure = (state: IAppearanceState, error: SystemErrors) : IAppearanceState => {
  return {
    ...state,
    logoError: error,
    isDeletingLogo: false
  };
}

// save logo
export const reducerAppearenceSaveLogoRequest = (state: IAppearanceState) : IAppearanceState => {
  return {
    ...state,
    logoError: undefined,
    isSavingLogo: true
  };
}

export const reducerAppearenceSaveLogoSuccess = (state: IAppearanceState) : IAppearanceState => {
  return {
    ...state,
    data: {
      ...state.data,
      logo: state.data.tmpLogo,
      tmpLogo: undefined
    },
    logoError: undefined,
    isSavingLogo: false,
    isUpdatingLogo: false
  };
}

export const reducerAppearenceSaveLogoFailure = (state: IAppearanceState, error: SystemErrors) : IAppearanceState => {
  return {
    ...state,
    logoError: error,
    isSavingLogo: false
  };
}
