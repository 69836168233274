import { AxiosResponse } from "axios"
import api from "../../services/api"
import auth from "../../services/auth"
import { IDeliveryCitiesDeleteResponse, IDeliveryCitiesListResponse, IDeliveryCitiesSaveResponse, IDeliveryCitiesToggleStatusResponse, IDeliveryCityDraft, IDeliveryNeighborhoodsListResponse, IDeliveryNeighborhoodsSaveRequest, IDeliveryNeighborhoodsSaveResponse, IDeliveryNeighborhoodsToggleStatusResponse } from "./interfaces"

export const serviceDeliveryCitiesListRequest = () => {
  return api.get<IDeliveryCitiesListResponse, AxiosResponse<IDeliveryCitiesListResponse>>('/settings/delivery/cities', {
    headers: { Authorization: `Bearer ${auth.getToken()}` }
  })
}

export const serviceDeliveryCitiesSaveRequest = (city: IDeliveryCityDraft) => {
  if(city.id) return api.put<IDeliveryCitiesSaveResponse, AxiosResponse<IDeliveryCitiesSaveResponse>>(`/settings/delivery/cities/${city.id}`, {
    data: {
      name: city.name,
      status: city.status
    }
  }, {
    headers: { Authorization: `Bearer ${auth.getToken()}` }
  })

  return api.post<IDeliveryCitiesSaveResponse, AxiosResponse<IDeliveryCitiesSaveResponse>>(`/settings/delivery/cities`, {
    data: {
      name: city.name,
      status: city.status
    }
  }, {
    headers: { Authorization: `Bearer ${auth.getToken()}` }
  })

}

export const serviceDeliveryCitiesToggleStatusRequest = (idCity: number) => {
  return api.put<IDeliveryCitiesToggleStatusResponse, AxiosResponse<IDeliveryCitiesToggleStatusResponse>>(`/settings/delivery/cities/togglestatus/${idCity}`, null, {
    headers: { Authorization: `Bearer ${auth.getToken()}` }
  })
}

export const serviceDeliveryCitiesDeleteRequest = (idCity: number) => {
  return api.delete<IDeliveryCitiesDeleteResponse, AxiosResponse<IDeliveryCitiesDeleteResponse>>(`/settings/delivery/cities/${idCity}`, {
    headers: { Authorization: `Bearer ${auth.getToken()}` }
  })
}

export const serviceDeliveryNeighborhoodsListRequest = (idCity: number) => {
  return api.get<IDeliveryNeighborhoodsListResponse, AxiosResponse<IDeliveryNeighborhoodsListResponse>>(`/settings/delivery/neighborhoods/${idCity}`, {
    headers: { Authorization: `Bearer ${auth.getToken()}` }
  })
}

export const serviceDeliveryNeighborhoodsToggleStatusRequest = (idNeighborhood: number) => {
  return api.put<IDeliveryNeighborhoodsToggleStatusResponse, AxiosResponse<IDeliveryNeighborhoodsToggleStatusResponse>>(`/settings/delivery/neighborhoods/togglestatus/${idNeighborhood}`, null, {
    headers: { Authorization: `Bearer ${auth.getToken()}` }
  })
}

export const serviceDeliveryNeighborhoodsDeleteRequest = (idNeighborhood: number) => {
  return api.delete<IDeliveryNeighborhoodsToggleStatusResponse, AxiosResponse<IDeliveryNeighborhoodsToggleStatusResponse>>(`/settings/delivery/neighborhoods/${idNeighborhood}`, {
    headers: { Authorization: `Bearer ${auth.getToken()}` }
  })
}

export const serviceDeliveryNeighborhoodsSaveRequest = (idCity: number, neighborhoods: IDeliveryNeighborhoodsSaveRequest) => {
  return api.post<IDeliveryNeighborhoodsSaveResponse, AxiosResponse<IDeliveryNeighborhoodsSaveResponse>>(`/settings/delivery/neighborhoods/${idCity}`,
  neighborhoods,
  {
    headers: { Authorization: `Bearer ${auth.getToken()}` }
  })
}
