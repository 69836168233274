import { SystemErrors } from "../.."
import { IProductCategory, IProductCategoryDraft, IProductCategoryOrderMap } from "./interfaces"

export enum ProductCategoriesActions {
  PRODUCT_CATEGORIES_REQUEST = "product_categories/REQUEST",
  PRODUCT_CATEGORIES_SUCCESS = "product_categories/SUCCESS",
  PRODUCT_CATEGORIES_FAILURE = "product_categories/FAILURE",

  PRODUCT_CATEGORIES_SAVE_REQUEST = "product_categories/SAVE_REQUEST",
  PRODUCT_CATEGORIES_SAVE_SUCCESS = "product_categories/SAVE_SUCCESS",
  PRODUCT_CATEGORIES_SAVE_FAILURE = "product_categories/SAVE_FAILURE",

  PRODUCT_CATEGORIES_REMOVE_REQUEST = "product_categories/REMOVE_REQUEST",
  PRODUCT_CATEGORIES_REMOVE_SUCCESS = "product_categories/REMOVE_SUCCESS",
  PRODUCT_CATEGORIES_REMOVE_FAILURE = "product_categories/REMOVE_FAILURE",

  PRODUCT_CATEGORIES_TOGGLE_STATUS_REQUEST = "product_categories/TOGGLE_STATUS_REQUEST",
  PRODUCT_CATEGORIES_TOGGLE_STATUS_SUCCESS = "product_categories/TOGGLE_STATUS_SUCCESS",
  PRODUCT_CATEGORIES_TOGGLE_STATUS_FAILURE = "product_categories/TOGGLE_STATUS_FAILURE",

  PRODUCT_CATEGORIES_START_CREATE = "product_categories/START_CREATE",
  PRODUCT_CATEGORIES_STOP_CREATE = "product_categories/STOP_CREATE",
  PRODUCT_CATEGORIES_CHANGE_DRAFT = "product_categories/CHANGE_DRAFT",
  PRODUCT_CATEGORIES_CHANGE_INFO = "product_categories/CHANGE_INFO",

  PRODUCT_CATEGORIES_START_REARRANGING = "product_categories/START_REARRANGING",
  PRODUCT_CATEGORIES_STOP_REARRANGING = "product_categories/STOP_REARRANGING",
  PRODUCT_CATEGORIES_UPDATE_REARRANGING = "product_categories/UPDATE_REARRANGING",
  PRODUCT_CATEGORIES_REARRANGING_SAVE_REQUEST = "product_categories/REARRANGING_SAVE_REQUEST",
  PRODUCT_CATEGORIES_REARRANGING_SAVE_SUCCESS = "product_categories/REARRANGING_SAVE_SUCCESS",
  PRODUCT_CATEGORIES_REARRANGING_SAVE_FAILURE = "product_categories/REARRANGING_SAVE_FAILURE"
}


export const actionProductCategoriesrRequest = () => ({
  type: ProductCategoriesActions.PRODUCT_CATEGORIES_REQUEST
})


export const actionProductCategoriesSuccess = (data: IProductCategory[]) => ({
  type: ProductCategoriesActions.PRODUCT_CATEGORIES_SUCCESS,
  data
})

export const actionProductCategoriesFailure = (error: any) => ({
  type: ProductCategoriesActions.PRODUCT_CATEGORIES_FAILURE,
  error
})

export const actionProductCategoriesStartCreate = () => ({
  type: ProductCategoriesActions.PRODUCT_CATEGORIES_START_CREATE
})

export const actionProductCategoriesStopCreate = () => ({
  type: ProductCategoriesActions.PRODUCT_CATEGORIES_STOP_CREATE
})

export const actionProductCategoriesChangeDraft = (category: IProductCategoryDraft) => ({
  type: ProductCategoriesActions.PRODUCT_CATEGORIES_CHANGE_DRAFT,
  category
})

export const actionProductCategoriesChangeInfo = (category: IProductCategory) => ({
  type: ProductCategoriesActions.PRODUCT_CATEGORIES_CHANGE_INFO,
  category
})

//Save
export const actionProductsCategoriesSaveRequest = (category: IProductCategoryDraft | IProductCategory | null = null) => ({
  type: ProductCategoriesActions.PRODUCT_CATEGORIES_SAVE_REQUEST,
  category
})


export const actionProductsCategoriesSaveSuccess = (category: IProductCategory[]) => ({
  type: ProductCategoriesActions.PRODUCT_CATEGORIES_SAVE_SUCCESS,
  category
})

export const actionProductsCategoriesSaveFailure = (error: any, category_id?: number) => ({
  type: ProductCategoriesActions.PRODUCT_CATEGORIES_SAVE_FAILURE,
  error,
  category_id
})

//Remove
export const actionProductsCategoriesRemoveRequest = (category_id: number) => ({
  type: ProductCategoriesActions.PRODUCT_CATEGORIES_REMOVE_REQUEST,
  category_id
})


export const actionProductsCategoriesRemoveSuccess = (category_id: number) => ({
  type: ProductCategoriesActions.PRODUCT_CATEGORIES_REMOVE_SUCCESS,
  category_id
})

export const actionProductsCategoriesRemoveFailure = (error: any, category_id: number) => ({
  type: ProductCategoriesActions.PRODUCT_CATEGORIES_REMOVE_FAILURE,
  error,
  category_id
})

// Toggle category status
export const actionProductCategoryToggleStatusRequest = (category_id: number) => ({
  type: ProductCategoriesActions.PRODUCT_CATEGORIES_TOGGLE_STATUS_REQUEST,
  category_id
})

export const actionProductCategoryToggleStatusSuccess = (category: IProductCategory) => ({
  type: ProductCategoriesActions.PRODUCT_CATEGORIES_TOGGLE_STATUS_SUCCESS,
  category
})

export const actionProductCategoryToggleStatusFailure = (category_id: number, error: SystemErrors) => ({
  type: ProductCategoriesActions.PRODUCT_CATEGORIES_TOGGLE_STATUS_FAILURE,
  category_id,
  error
})

// rearranging
export const actionProductCategoriesStartRearranging = () => ({
  type: ProductCategoriesActions.PRODUCT_CATEGORIES_START_REARRANGING
})

export const actionProductCategoriesStopRearranging = () => ({
  type: ProductCategoriesActions.PRODUCT_CATEGORIES_STOP_REARRANGING
})

export const actionProductCategoriesUpdateRearranging = (data: IProductCategoryOrderMap[]) => ({
  type: ProductCategoriesActions.PRODUCT_CATEGORIES_UPDATE_REARRANGING,
  data
})

export const actionProductCategoriesRearrangingSaveRequest = () => ({
  type: ProductCategoriesActions.PRODUCT_CATEGORIES_REARRANGING_SAVE_REQUEST
})

export const actionProductCategoriesRearrangingSaveSuccess = (categories: IProductCategory[]) => ({
  type: ProductCategoriesActions.PRODUCT_CATEGORIES_REARRANGING_SAVE_SUCCESS,
  categories
})

export const actionProductCategoriesRearrangingSaveFailure = (error: SystemErrors) => ({
  type: ProductCategoriesActions.PRODUCT_CATEGORIES_REARRANGING_SAVE_FAILURE,
  error
})
