// Payments
export const SETTINGS_PAYMENTS_LIST                 = 'SETTINGS_PAYMENTS_LIST';
export const SETTINGS_PAYMENTS_LIST_RESPONSE        = 'SETTINGS_PAYMENTS_LIST_RESPONSE';
export const SETTINGS_PAYMENTS_STORE                = 'SETTINGS_PAYMENTS_STORE';
export const SETTINGS_PAYMENTS_ERROR                = 'SETTINGS_PAYMENTS_ERROR';
export const SETTINGS_PAYMENTS_STORE_RESPONSE       = 'SETTINGS_PAYMENTS_STORE_RESPONSE';

export const paymentsList = () => {
    return {
        type: SETTINGS_PAYMENTS_LIST,
    }
}

export const paymentsListResponse = (paymentsList) => {
    return {
        type: SETTINGS_PAYMENTS_LIST_RESPONSE,
        paymentsList
    }
}

export const paymentsError = (error) => {
    return {
        type: SETTINGS_PAYMENTS_ERROR,
        error
    }
}

export const paymentStore = (payment) => {
    return {
        type: SETTINGS_PAYMENTS_STORE,
        payment
    }
}

export const paymentStoreResponse = (payment) => {
    return {
        type: SETTINGS_PAYMENTS_STORE_RESPONSE,
        payment
    }
}

// Styles
export const SETTINGS_STYLES_LIST                   = 'SETTINGS_STYLES_LIST';
export const SETTINGS_STYLES_LIST_RESPONSE          = 'SETTINGS_STYLES_LIST_RESPONSE';
export const SETTINGS_STYLES_STORE                  = 'SETTINGS_STYLES_STORE';
export const SETTINGS_STYLES_ERROR                  = 'SETTINGS_STYLES_ERROR';

export const getStyles = () => {
    return {
        type: SETTINGS_STYLES_LIST
    }
}

export const setStyles = (actions) => {
    return {
        type: SETTINGS_STYLES_STORE,
        ...actions
    }
}

export const stylesResponse = (styles) => {
    return {
        type: SETTINGS_STYLES_LIST_RESPONSE,
        styles
    }
}

export const stylesError = (error) => {
    return {
        type: SETTINGS_STYLES_ERROR,
        error
    }
}

// Settings
export const SETTINGS_LIST                          = 'SETTINGS_LIST';
export const SETTINGS_LIST_RESPONSE                 = 'SETTINGS_LIST_RESPONSE';
export const SETTINGS_STORE                         = 'SETTINGS_STORE';
export const SETTINGS_LIST_ERROR                    = 'SETTINGS_LIST_ERROR';

export const getSettings = () => {
    return {
        type: SETTINGS_LIST
    }
}

export const setSettings = (actions) => {
    return {
        type: SETTINGS_STORE,
        ...actions
    }
}

export const settingsResponse = (settings) => {
    return {
        type: SETTINGS_LIST_RESPONSE,
        settings
    }
}

// Delivery
export const SETTINGS_DELIVERY                      = 'SETTINGS_DELIVERY';
export const SETTINGS_DELIVERY_RESPONSE             = 'SETTINGS_DELIVERY_RESPONSE';
export const SETTINGS_DELIVERY_STORE                = 'SETTINGS_DELIVERY_STORE';
export const SETTINGS_DELIVERY_STORE_RESPONSE       = 'SETTINGS_DELIVERY_STORE_RESPONSE';
export const SETTINGS_DELIVERY_ERROR                = 'SETTINGS_DELIVERY_ERROR';

export const getDeliveryCharges = () => {
    return {
        type: SETTINGS_DELIVERY
    }
}

export const getDeliveryChargesResponse = (delivery) => {
    return {
        type: SETTINGS_DELIVERY_RESPONSE,
        delivery
    }
}

export const getDeliveryChargesError = (error) => {
    return {
        type: SETTINGS_DELIVERY_ERROR,
        error
    }
}

export const deliveryStore = (delivery) => {
    return {
        type: SETTINGS_DELIVERY_STORE,
        delivery
    }
}

export const deliveryError = (error) => {
    return {
        type: SETTINGS_DELIVERY_ERROR,
        error
    }
}

export const deliveryStoreResponse = (delivery, error = false) => {
    return {
        type: SETTINGS_DELIVERY_STORE_RESPONSE,
        delivery,
        error
    }
}


// Opening
export const SETTINGS_OPENING                      = 'SETTINGS_OPENING';
export const SETTINGS_OPENING_RESPONSE             = 'SETTINGS_OPENING_RESPONSE';
export const SETTINGS_OPENING_STORE                = 'SETTINGS_OPENING_STORE';
export const SETTINGS_OPENING_STORE_RESPONSE       = 'SETTINGS_OPENING_STORE_RESPONSE';
export const SETTINGS_OPENING_ERROR                = 'SETTINGS_OPENING_ERROR';

export const getOpeningHours = () => {
    return {
        type: SETTINGS_OPENING
    }
}

export const getOpeningHoursResponse = (opening) => {
    return {
        type: SETTINGS_OPENING_RESPONSE,
        opening
    }
}

export const getOpeningHoursError = (error) => {
    return {
        type: SETTINGS_OPENING_ERROR,
        error
    }
}

export const openingStore = (opening) => {
    return {
        type: SETTINGS_OPENING_STORE,
        opening
    }
}

export const openingError = (error) => {
    return {
        type: SETTINGS_OPENING_ERROR,
        error
    }
}

export const openingStoreResponse = (opening) => {
    return {
        type: SETTINGS_OPENING_STORE_RESPONSE,
        opening
    }
}



// Self service
export const SETTINGS_SELF_SERVICE                      = 'SETTINGS_SELF_SERVICE';
export const SETTINGS_SELF_SERVICE_RESPONSE             = 'SETTINGS_SELF_SERVICE_RESPONSE';
export const SETTINGS_SELF_SERVICE_STORE                = 'SETTINGS_SELF_SERVICE_STORE';
export const SETTINGS_SELF_SERVICE_STORE_RESPONSE       = 'SETTINGS_SELF_SERVICE_STORE_RESPONSE';
export const SETTINGS_SELF_SERVICE_ERROR                = 'SETTINGS_SELF_SERVICE_ERROR';

export const getSelfService = () => {
    return {
        type: SETTINGS_SELF_SERVICE
    }
}

export const getSelfServiceResponse = (self_service) => {
    return {
        type: SETTINGS_SELF_SERVICE_RESPONSE,
        self_service
    }
}

export const getSelfServiceError = (error) => {
    return {
        type: SETTINGS_SELF_SERVICE_ERROR,
        error
    }
}

export const selfServiceStore = (self_service) => {
    return {
        type: SETTINGS_SELF_SERVICE_STORE,
        self_service
    }
}

export const selfServiceError = (error) => {
    return {
        type: SETTINGS_SELF_SERVICE_ERROR,
        error
    }
}

export const selfServiceStoreResponse = (self_service) => {
    return {
        type: SETTINGS_SELF_SERVICE_STORE_RESPONSE,
        self_service
    }
}