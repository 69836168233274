import { Reducer } from "redux";
import { DeliveryTypes, IDeliveryState } from "./interfaces";
import { reducerDeliveryCitiesDeletingFailure, reducerDeliveryCitiesDeletingRequest, reducerDeliveryCitiesDeletingSuccess, reducerDeliveryCityDraftChange, reducerDeliveryCitySaveFailure, reducerDeliveryCitySaveRequest, reducerDeliveryCitySaveSuccess, reducerDeliveryCityStartEditing, reducerDeliveryCityStopEditing, reducerDeliveryCityTogglingStatusFailure, reducerDeliveryCityTogglingStatusRequest, reducerDeliveryCityTogglingStatusSuccess, reducerDeliveryNeighborhoodAddField, reducerDeliveryNeighborhoodChangeField, reducerDeliveryNeighborhoodsDeletingFailure, reducerDeliveryNeighborhoodsDeletingRequest, reducerDeliveryNeighborhoodsDeletingSuccess, reducerDeliveryNeighborhoodsDiscart, reducerDeliveryNeighborhoodsFailure, reducerDeliveryNeighborhoodsRequest, reducerDeliveryNeighborhoodsSaveFailure, reducerDeliveryNeighborhoodsSaveRequest, reducerDeliveryNeighborhoodsSaveSuccess, reducerDeliveryNeighborhoodsSuccess } from "./reducerHandlers";

export const DELIVERY_CITIES_INITIAL_STATE: IDeliveryState = {
  data: [],
  isLoading: false,
}

const reducer: Reducer = (state = DELIVERY_CITIES_INITIAL_STATE, action) => {
  switch (action.type) {
    // city draft
    case DeliveryTypes.DELIVERY_CITIES_START_EDITING:
      return reducerDeliveryCityStartEditing(state, action.city)

    case DeliveryTypes.DELIVERY_CITIES_EDIT_CHANGE:
      return reducerDeliveryCityDraftChange(state, action.city)

    case DeliveryTypes.DELIVERY_CITIES_STOP_EDITING:
      return reducerDeliveryCityStopEditing(state)

    // list cities
    case DeliveryTypes.DELIVERY_CITIES_REQUEST:
      return {
        ...state, isLoading: true
      };
    case DeliveryTypes.DELIVERY_CITIES_SUCCESS:
      return {
        ...state, data: action.data, isLoading: false
      };
    case DeliveryTypes.DELIVERY_CITIES_FAILURE:
      return {
        ...state, error: action.error, isLoading: false
      };
    // save delivery city
    case DeliveryTypes.DELIVERY_CITIES_SAVE_REQUEST:
      return reducerDeliveryCitySaveRequest(state)
    case DeliveryTypes.DELIVERY_CITIES_SAVE_SUCCESS:
      return reducerDeliveryCitySaveSuccess(state, action.city)
    case DeliveryTypes.DELIVERY_CITIES_SAVE_FAILURE:
      return reducerDeliveryCitySaveFailure(state, action.error)

    // toggle city status
    case DeliveryTypes.DELIVERY_CITIES_TOGGLING_STATUS_REQUEST:
      return reducerDeliveryCityTogglingStatusRequest(state, action.idCity)
    case DeliveryTypes.DELIVERY_CITIES_TOGGLING_STATUS_SUCCESS:
      return reducerDeliveryCityTogglingStatusSuccess(state, action.idCity, action.status)
    case DeliveryTypes.DELIVERY_CITIES_TOGGLING_STATUS_FAILURE:
      return reducerDeliveryCityTogglingStatusFailure(state, action.idCity, action.error)

    // delete delivery city
    case DeliveryTypes.DELIVERY_CITIES_DELETE_REQUEST:
      return reducerDeliveryCitiesDeletingRequest(state, action.idCity)
    case DeliveryTypes.DELIVERY_CITIES_DELETE_SUCCESS:
      return reducerDeliveryCitiesDeletingSuccess(state, action.idCity)
    case DeliveryTypes.DELIVERY_CITIES_DELETE_FAILURE:
      return reducerDeliveryCitiesDeletingFailure(state, action.idCity, action.error)

    // list neighborhoods
    case DeliveryTypes.DELIVERY_NEIGHBORHOODS_REQUEST:
      return reducerDeliveryNeighborhoodsRequest(state, action.idCity);
    case DeliveryTypes.DELIVERY_NEIGHBORHOODS_SUCCESS:
      return reducerDeliveryNeighborhoodsSuccess(state, action.idCity, action.data);
    case DeliveryTypes.DELIVERY_NEIGHBORHOODS_FAILURE:
      return reducerDeliveryNeighborhoodsFailure(state, action.idCity, action.error);

    // neighborhoods fields
    case DeliveryTypes.DELIVERY_NEIGHBORHOODS_ADD_FIELD:
      return reducerDeliveryNeighborhoodAddField(state, action.idCity)
    case DeliveryTypes.DELIVERY_NEIGHBORHOODS_CHANGE_FIELD:
      return reducerDeliveryNeighborhoodChangeField(state, action.idCity, action.index, action.neighborhood)

    // delete neighborhood
    case DeliveryTypes.DELIVERY_NEIGHBORHOODS_DISCART:
      return reducerDeliveryNeighborhoodsDiscart(state, action.idCity, action.index)
    case DeliveryTypes.DELIVERY_NEIGHBORHOODS_DELETE_REQUEST:
      return reducerDeliveryNeighborhoodsDeletingRequest(state, action.idCity, action.idNeighborhood);
    case DeliveryTypes.DELIVERY_NEIGHBORHOODS_DELETE_SUCCESS:
      return reducerDeliveryNeighborhoodsDeletingSuccess(state, action.idCity, action.idNeighborhood);
    case DeliveryTypes.DELIVERY_NEIGHBORHOODS_DELETE_FAILURE:
      return reducerDeliveryNeighborhoodsDeletingFailure(state, action.idCity, action.idNeighborhood, action.error);

    // save neighborhood
    case DeliveryTypes.DELIVERY_NEIGHBORHOODS_SAVE_REQUEST:
      return reducerDeliveryNeighborhoodsSaveRequest(state, action.idCity);
    case DeliveryTypes.DELIVERY_NEIGHBORHOODS_SAVE_SUCCESS:
      return reducerDeliveryNeighborhoodsSaveSuccess(state, action.idCity, action.neighborhoods);
    case DeliveryTypes.DELIVERY_NEIGHBORHOODS_SAVE_FAILURE:
      return reducerDeliveryNeighborhoodsSaveFailure(state, action.idCity, action.error);
    default:
      return state;
  }
}

export default reducer;
