import { AxiosResponse } from "axios"
import api from "../../services/api"
import auth from "../../services/auth"
import { timeMask } from "../../services/utilFunctions"
import { IOpeningHourDraft, IOpeningHoursDeleteResponse, IOpeningHoursResponse, IOpeningHoursSaveResponse, IOpeningHoursToggleStatusResponse } from "./interfaces"

export const serviceOpeningHoursRequest = () => {
  return api.get<IOpeningHoursResponse, AxiosResponse<IOpeningHoursResponse>>('/settings/openinghours', {
    headers: { Authorization: `Bearer ${auth.getToken()}` }
  })
}

export const serviceOpeningHoursToggleStatusRequest = (id: number) => {
  return api.put<IOpeningHoursToggleStatusResponse, AxiosResponse<IOpeningHoursToggleStatusResponse>>(`/settings/openinghours/togglestatus/${id}`, null, {
    headers: { Authorization: `Bearer ${auth.getToken()}` }
  })
}

export const serviceOpeningHoursSaveRequest = (data: IOpeningHourDraft) => {
  if(data.id) {
    return api.put<IOpeningHoursSaveResponse, AxiosResponse<IOpeningHoursSaveResponse>>(`/settings/openinghours/${data.id}`, {
      data: {
        week_day: data.week_day,
        opening: timeMask(String(data.opening)),
        clousure: timeMask(String(data.clousure)),
        status: data.status
      }
    }, {
      headers: { Authorization: `Bearer ${auth.getToken()}` }
    })
  }

  return api.post<IOpeningHoursSaveResponse, AxiosResponse<IOpeningHoursSaveResponse>>('/settings/openinghours', {
    data: {
      week_day: data.week_day,
      opening: timeMask(String(data.opening)),
      clousure: timeMask(String(data.clousure)),
      status: data.status
    }
  }, {
    headers: { Authorization: `Bearer ${auth.getToken()}` }
  })
}

export const serviceOpeningHoursDeleteRequest = (id: number) => {
  return api.delete<IOpeningHoursDeleteResponse, AxiosResponse<IOpeningHoursDeleteResponse>>(`/settings/openinghours/${id}`, {
    headers: { Authorization: `Bearer ${auth.getToken()}` }
  })
}
