import { SystemErrors } from "../..";
import { IDeliveryCity, IDeliveryCityDraft, IDeliveryNeighborhood, IDeliveryState } from "./interfaces";

// delivery city modal
export const reducerDeliveryCityStartEditing = (state: IDeliveryState, city: IDeliveryCityDraft) : IDeliveryState => {
  return {
    ...state,
    cityDraft: city
  };
}

export const reducerDeliveryCityDraftChange = (state: IDeliveryState, city: IDeliveryCityDraft) : IDeliveryState => {
  return {
    ...state,
    cityDraft: {
      ...city,
      isChanged: true
    }
  };
}

export const reducerDeliveryCityStopEditing = (state: IDeliveryState) : IDeliveryState => {
  return {
    ...state,
    cityDraft: undefined
  };
}

// delivery city save
export const reducerDeliveryCitySaveRequest = (state: IDeliveryState) : IDeliveryState => {
  if(!state.cityDraft) return {
    ...state
  }

  return {
    ...state,
    cityDraft: {
      ...state.cityDraft,
      isSaving: true
    }
  };
}

export const reducerDeliveryCitySaveSuccess = (state: IDeliveryState, city: IDeliveryCity) : IDeliveryState => {
  const newData = state.data?.filter(oldCities => oldCities.id !== city.id)
  return {
    ...state,
    cityDraft: undefined,
    data: [
      ...newData,
      city
    ].sort((a,b) => a.name.localeCompare(b.name))
  };
}

export const reducerDeliveryCitySaveFailure = (state: IDeliveryState, error: SystemErrors) : IDeliveryState => {
  if(!state.cityDraft) return {
    ...state
  }

  return {
    ...state,
    cityDraft: {
      ...state.cityDraft,
      isSaving: false,
      error
    }
  };
}

// toggle delivery city status
export const reducerDeliveryCityTogglingStatusRequest = (state: IDeliveryState, idCity: number) : IDeliveryState => {
  return {
    ...state,
    data: state.data.map(city => {
      if(city.id !== idCity) return city;

      return {
        ...city,
        isTogglingStatus: true
      }
    })
  };
}

export const reducerDeliveryCityTogglingStatusSuccess = (state: IDeliveryState, idCity: number, status: boolean) : IDeliveryState => {
  return {
    ...state,
    data: state.data.map(city => {
      if(city.id !== idCity) return city;

      return {
        ...city,
        isTogglingStatus: false,
        status
      }
    })
  };
}

export const reducerDeliveryCityTogglingStatusFailure = (state: IDeliveryState, idCity: number, error: SystemErrors) : IDeliveryState => {
  return {
    ...state,
    data: state.data.map(city => {
      if(city.id !== idCity) return city;

      return {
        ...city,
        isTogglingStatus: false,
        error
      }
    })
  };
}

export const reducerDeliveryCitiesDeletingRequest = (state: IDeliveryState, idCity: number) : IDeliveryState => {
  return {
    ...state,
    data: state.data.map(city => {
      if(city.id !== idCity) return city;

      return {
        ...city,
        isDeleting: true
      }
    })
  };
}

export const reducerDeliveryCitiesDeletingSuccess = (state: IDeliveryState, idCity: number) : IDeliveryState => {
  return {
    ...state,
    data: state.data.filter(city => city.id !== idCity)
  };
}

export const reducerDeliveryCitiesDeletingFailure = (state: IDeliveryState, idCity: number, error: SystemErrors) : IDeliveryState => {
  return {
    ...state,
    data: state.data.map(city => {
      if(city.id !== idCity) return city;

      return {
        ...city,
        isDeleting: false,
        error
      }
    })
  };
}


// neighborhoods fields
export const reducerDeliveryNeighborhoodAddField = (state: IDeliveryState, idCity: number): IDeliveryState => {
  return {
    ...state,
    data: state.data.map(city => {
      if(city.id !== idCity) return city;

      return {
        ...city,
        neighborhoods: [
          ...city.neighborhoods || [],
          {
            id_city: idCity,
            name: "",
            price: 0,
            status: true,
          }
        ]
      }
    })
  }
}

export const reducerDeliveryNeighborhoodChangeField = (state: IDeliveryState, idCity: number, index: number, neighborhood: IDeliveryNeighborhood): IDeliveryState => {
  return {
    ...state,
    data: state.data.map(city => {
      if(city.id !== idCity) return city;

      return {
        ...city,
        neighborhoods: city.neighborhoods?.map((neigh, neighIndex) => {
          if(neighIndex !== index) return neigh;

          return {
            ...neighborhood,
            isChanged: true
          }
        })
      }
    })
  }
}

// list neighborhoods
export const reducerDeliveryNeighborhoodsRequest = (state: IDeliveryState, idCity: number) : IDeliveryState => {
  return {
    ...state,
    data: state.data.map(city => {
      if(city.id !== idCity) return city;

      return {
        ...city,
        isLoading: true,
        error: undefined
      }
    })
  };
}

export const reducerDeliveryNeighborhoodsSuccess = (state: IDeliveryState, idCity: number, data: IDeliveryNeighborhood[]) : IDeliveryState => {
  return {
    ...state,
    data: state.data.map(city => {
      if(city.id !== idCity) return city;

      return {
        ...city,
        isLoading: false,
        neighborhoods: data
      }
    })
  };
}

export const reducerDeliveryNeighborhoodsFailure = (state: IDeliveryState, idCity: number, error: SystemErrors) : IDeliveryState => {
  return {
    ...state,
    data: state.data.map(city => {
      if(city.id !== idCity) return city;

      return {
        ...city,
        isLoading: false,
        error
      }
    })
  };
}

// delete neighborhood
export const reducerDeliveryNeighborhoodsDiscart = (state: IDeliveryState, idCity: number, index: number) : IDeliveryState => {
  return {
    ...state,
    data: state.data.map(city => {
      if(city.id !== idCity) return city;

      return {
        ...city,
        neighborhoods: city?.neighborhoods?.filter((neigh, neighIndex) => neighIndex !== index)
      }
    })
  };
}

export const reducerDeliveryNeighborhoodsDeletingRequest = (state: IDeliveryState, idCity: number, idNeighborhood: number) : IDeliveryState => {
  return {
    ...state,
    data: state.data.map(city => {
      if(city.id !== idCity) return city;

      return {
        ...city,
        neighborhoods: city?.neighborhoods?.map(neighborhood => {
          if(neighborhood.id !== idNeighborhood) return neighborhood;

          return {
            ...neighborhood,
            isDeleting: true,
            error: undefined
          }
        })
      }
    })
  };
}

export const reducerDeliveryNeighborhoodsDeletingSuccess = (state: IDeliveryState, idCity: number, idNeighborhood: number) : IDeliveryState => {
  return {
    ...state,
    data: state.data.map(city => {
      if(city.id !== idCity) return city;

      return {
        ...city,
        neighborhoods: city?.neighborhoods?.filter(neighborhood => neighborhood.id !== idNeighborhood)
      }
    })
  };
}

export const reducerDeliveryNeighborhoodsDeletingFailure = (state: IDeliveryState, idCity: number, idNeighborhood: number, error: SystemErrors) : IDeliveryState => {
  return {
    ...state,
    data: state.data.map(city => {
      if(city.id !== idCity) return city;

      return {
        ...city,
        neighborhoods: city?.neighborhoods?.map(neighborhood => {
          if(neighborhood.id !== idNeighborhood) return neighborhood;

          return {
            ...neighborhood,
            isDeleting: false,
            error
          }
        })
      }
    })
  };
}

// save neighborhoods
export const reducerDeliveryNeighborhoodsSaveRequest = (state: IDeliveryState, idCity: number) : IDeliveryState => {
  return {
    ...state,
    data: state.data.map(city => {
      if(city.id !== idCity) return city;

      return {
        ...city,
        isSavingNeighborhoods: true,
        error: undefined
      }
    })
  };
}

export const reducerDeliveryNeighborhoodsSaveSuccess = (state: IDeliveryState, idCity: number, neighborhoods: IDeliveryNeighborhood[]) : IDeliveryState => {
  return {
    ...state,
    data: state.data.map(city => {
      if(city.id !== idCity) return city;

      const someError = neighborhoods?.find(neighborhood => neighborhood.error);

      return {
        ...city,
        isSavingNeighborhoods: false,
        neighborhoods: neighborhoods.sort((a, b) => a.name.localeCompare(b.name)),
        error: someError ? {
          message: "Não foi possível salvar as informações de um ou mais bairros. Por favor, verifique os dados e tente novamente."
        } : undefined
      }
    })
  };
}

export const reducerDeliveryNeighborhoodsSaveFailure = (state: IDeliveryState, idCity: number, error: SystemErrors) : IDeliveryState => {
  return {
    ...state,
    data: state.data.map(city => {
      if(city.id !== idCity) return city;

      return {
        ...city,
        isSavingNeighborhoods: false,
        error
      }
    })
  };
}
