import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr'

import { Provider } from 'react-redux';
import rootReducer from './data/reducers';
import root_sagas from './store/rootSagas'
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

import AuthContext from './Contexts/AuthContext';

// Sagas
import OrdersSaga 		from './data/sagas/OrdersSaga'
import UsersSaga 		from './data/sagas/UsersSaga'
// import ProductsSaga 	from './data/sagas/ProductsSaga'
import SettingsSaga 	from './data/sagas/SettingsSaga'
import StatsSaga 		from './data/sagas/StatsSaga'
import './App.scss';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'

import auth from './services/auth';

const sagaMiddleware = createSagaMiddleware();

const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));

// run sagas
sagaMiddleware.run(OrdersSaga);
sagaMiddleware.run(UsersSaga);
// sagaMiddleware.run(ProductsSaga);
sagaMiddleware.run(SettingsSaga);
sagaMiddleware.run(StatsSaga);
sagaMiddleware.run(root_sagas);

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;
// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));
// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));

class App extends Component {

	render() {
		return (
			<Provider store={store}>
				<AuthContext.Provider value={auth}>
					<HashRouter>
						<React.Suspense fallback={loading()}>
							<Switch>
								<Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
								<Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
								<Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
								<Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
								<Route path="/" name="Home" render={props => <DefaultLayout {...props} />} />
							</Switch>
						</React.Suspense>
					</HashRouter>
				</AuthContext.Provider>

				<ReduxToastr
					timeOut={4000}
					newestOnTop={false}
					preventDuplicates
					position="top-right"
					getState={(state) => state.toastr} // This is the default
					transitionIn="fadeIn"
					transitionOut="fadeOut"
					progressBar
					closeOnToastrClick/>
			</Provider>
		);
	}
}

export default App;
