import api from './api';
import auth from './auth';
import querystring from 'query-string'

export default {
    financial: (period) => {
        return api.get(`/stats?type=financial&period=${period}`, {
            headers: {Authorization: `Bearer ${auth.getToken()}`}
        });
    },
    financialHistory: (period) => {
        return api.get(`/stats?type=financialhistory&period=${period}`, {
            headers: {Authorization: `Bearer ${auth.getToken()}`}
        });
    },
    financialDetails: (period) => {
        return api.get(`/stats?type=financialdetails&period=${period}`, {
            headers: {Authorization: `Bearer ${auth.getToken()}`}
        });
    },
    paymentMethods: (period) => {
        return api.get(`/stats?type=paymentmethods&period=${period}`, {
            headers: {Authorization: `Bearer ${auth.getToken()}`}
        });
    },

    orders_detail: (filter = {}) => {
        console.log('service.filter', filter)
        let default_filter = {
            type: 'orders_details',
            ...filter
        }
        return api.get(`/stats?${querystring.stringify(default_filter)}`, {
            headers: {Authorization: `Bearer ${auth.getToken()}`}
        });
    }
}
