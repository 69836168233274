import { Reducer } from "redux";
import { IOpeningHoursState, OpeningHoursTypes } from "./interfaces";
import { reducerOpeningHourDeleteFailure, reducerOpeningHourDeleteRequest, reducerOpeningHourDeleteSuccess, reducerOpeningHourSaveFailure, reducerOpeningHourSaveRequest, reducerOpeningHourSaveSuccess, reducerOpeningHourSetDraft, reducerOpeningHourToggleStatusFailure, reducerOpeningHourToggleStatusRequest, reducerOpeningHourToggleStatusSuccess } from "./reducerHandlers";

export const OPENING_HOURS_INITIAL_STATE: IOpeningHoursState ={
  data: [],
  isLoading: false,
}

const reducer: Reducer<IOpeningHoursState> = (state = OPENING_HOURS_INITIAL_STATE, action) => {
  switch (action.type) {
    // list
    case OpeningHoursTypes.OPENING_HOURS_REQUEST:
      return { ...state, isLoading: true };
    case OpeningHoursTypes.OPENING_HOURS_SUCCESS:
      return { ...state, isLoading: false, data: action.data };
    case OpeningHoursTypes.OPENING_HOURS_FAILURE:
      return { ...state, isLoading: false, error: action.error };

    // start/stop editing
    case OpeningHoursTypes.OPENING_HOURS_START_EDITING:
      return { ...state, draft: action.draft };
    case OpeningHoursTypes.OPENING_HOURS_STOP_EDITING:
      return { ...state, draft: undefined };

    // toggle status
    case OpeningHoursTypes.OPENING_HOURS_TOGGLING_STATUS_REQUEST:
      return reducerOpeningHourToggleStatusRequest(state, action.id);
    case OpeningHoursTypes.OPENING_HOURS_TOGGLING_STATUS_SUCCESS:
      return reducerOpeningHourToggleStatusSuccess(state, action.data);
    case OpeningHoursTypes.OPENING_HOURS_TOGGLING_STATUS_FAILURE:
      return reducerOpeningHourToggleStatusFailure(state, action.id, action.error);

    // set draft
    case OpeningHoursTypes.OPENING_HOURS_SET_DRAFT:
      return reducerOpeningHourSetDraft(state, action.draft);

    // save
    case OpeningHoursTypes.OPENING_HOURS_SAVE_REQUEST:
      return reducerOpeningHourSaveRequest(state, action.draft);
    case OpeningHoursTypes.OPENING_HOURS_SAVE_SUCCESS:
      return reducerOpeningHourSaveSuccess(state, action.data);
    case OpeningHoursTypes.OPENING_HOURS_SAVE_FAILURE:
      return reducerOpeningHourSaveFailure(state, action.error);

    // delete
    case OpeningHoursTypes.OPENING_HOURS_DELETE_REQUEST:
      return reducerOpeningHourDeleteRequest(state, action.id);
    case OpeningHoursTypes.OPENING_HOURS_DELETE_SUCCESS:
      return reducerOpeningHourDeleteSuccess(state, action.id);
    case OpeningHoursTypes.OPENING_HOURS_DELETE_FAILURE:
      return reducerOpeningHourDeleteFailure(state, action.id, action.error);
    default:
      return state;
  }
}

export default reducer;
