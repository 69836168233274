import api from './api';
import auth from './auth';

export const SettingsService = {
    paymentsList: () => {
        return api.get('/settings/payments', {
            headers: {Authorization: `Bearer ${auth.getToken()}`}
        });
    },

    paymentStore: (payment) => {

        if(payment.id){
            return api.put('/settings/payments/store', 
            {
                data: payment
            },
            {
                headers: {Authorization: `Bearer ${auth.getToken()}`}
            });
        }
        else{
            return api.post('/settings/payments/store', 
            {
                data: payment
            },
            {
                headers: {Authorization: `Bearer ${auth.getToken()}`}
            });
        }
    },

    getStyles: () => {
        return api.get('/settings/styles', {
            headers: {Authorization: `Bearer ${auth.getToken()}`}
        });
    },

    setStyles: (styles) => {
        return api.put('/settings/styles', {
            data: {
                ...styles
            }
        }, {
            headers: {Authorization: `Bearer ${auth.getToken()}`}
        });
    },

    getPrinters: () => {
        return api.get('/settings/printers', {
            headers: {Authorization: `Bearer ${auth.getToken()}`}
        });
    },

    

    getSettings: () => {
        return api.get('/settings', {
            headers: {Authorization: `Bearer ${auth.getToken()}`}
        });
    },

    setSettings: (settings) => {
        return api.put('/settings', {
            data: {
                ...settings
            }
        }, {
            headers: {Authorization: `Bearer ${auth.getToken()}`}
        });
    },

    // Delivery
    deliveryChargesStore: (delivery) => {
        if(delivery.id){
            return api.put('/settings/deliverycharges/store', 
            {
                data: delivery
            },
            {
                headers: {Authorization: `Bearer ${auth.getToken()}`}
            });
        }
        else{
            return api.post('/settings/deliverycharges/store', 
            {
                data: delivery
            },
            {
                headers: {Authorization: `Bearer ${auth.getToken()}`}
            });
        }
    },

    getDeliveryCharges: () => {
        return api.get('/settings/deliverycharges', {
            headers: {Authorization: `Bearer ${auth.getToken()}`}
        });
    },

    // Opening
    openingStore: (opening) => {
        if(opening.id){
            return api.put('/settings/opening/store', 
            {
                data: opening
            },
            {
                headers: {Authorization: `Bearer ${auth.getToken()}`}
            });
        }
        else{
            return api.post('/settings/opening/store', 
            {
                data: opening
            },
            {
                headers: {Authorization: `Bearer ${auth.getToken()}`}
            });
        }
    },

    getOpening: () => {
        return api.get('/settings/opening', {
            headers: {Authorization: `Bearer ${auth.getToken()}`}
        });
    },

    // Opening
    selfServiceStore: (self_service) => {
        if(self_service.id){
            return api.put('/settings/selfservice/store', 
            {
                data: self_service
            },
            {
                headers: {Authorization: `Bearer ${auth.getToken()}`}
            });
        }
        else{
            return api.post('/settings/selfservice/store', 
            {
                data: self_service
            },
            {
                headers: {Authorization: `Bearer ${auth.getToken()}`}
            });
        }
    },

    getSelfService: () => {
        return api.get('/settings/selfservice', {
            headers: {Authorization: `Bearer ${auth.getToken()}`}
        });
    }
}
