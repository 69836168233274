import { SystemErrors } from "../.."
import { IProduct, IProductConfiguration, IProductConfigurationOrderMap, IProductDraft, IProductDuplicate, IProductDuplicateResponse } from "./interfaces"

export enum ProductsActions {
  PRODUCTS_REQUEST = "products/REQUEST",
  PRODUCTS_SUCCESS = "products/SUCCESS",
  PRODUCTS_FAILURE = "products/FAILURE",
  PRODUCTS_SAVE_REQUEST = "products/SAVE_REQUEST",
  PRODUCTS_SAVE_SUCCESS = "products/SAVE_SUCCESS",
  PRODUCTS_SAVE_FAILURE = "products/SAVE_FAILURE",

  PRODUCTS_REMOVE_REQUEST = "products/REMOVE_REQUEST",
  PRODUCTS_REMOVE_SUCCESS = "products/REMOVE_SUCCESS",
  PRODUCTS_REMOVE_FAILURE = "products/REMOVE_FAILURE",

  PRODUCTS_REMOVE_PHOTO_REQUEST = "products/REMOVE_PHOTO_REQUEST",
  PRODUCTS_REMOVE_PHOTO_SUCCESS = "products/REMOVE_PHOTO_SUCCESS",
  PRODUCTS_REMOVE_PHOTO_FAILURE = "products/REMOVE_PHOTO_FAILURE",

  PRODUCTS_TOGGLE_STAUTS_REQUEST = "products/TOGGLE_STAUTS_REQUEST",
  PRODUCTS_TOGGLE_STAUTS_SUCCESS = "products/TOGGLE_STAUTS_SUCCESS",
  PRODUCTS_TOGGLE_STAUTS_FAILURE = "products/TOGGLE_STAUTS_FAILURE",

  PRODUCTS_CREATE_NEW = "products/CREATE_NEW",
  PRODUCTS_START_EDIT = "products/START_EDIT",
  PRODUCTS_STOP_EDIT = "products/STOP_EDIT",
  PRODUCTS_CHANGE_INFO = "products/CHANGE_INFO",
  PRODUCTS_SET_STEP = "products/SET_STEP",

  PRODUCTS_START_DUPLICATE = "products/START_DUPLICATE",
  PRODUCTS_STOP_DUPLICATE = "products/STOP_DUPLICATE",
  PRODUCTS_CHANGE_DUPLICATE = "products/CHANGE_DUPLICATE",

  PRODUCTS_SAVE_DUPLICATE_REQUEST = "products/SAVE_DUPLICATE_REQUEST",
  PRODUCTS_SAVE_DUPLICATE_SUCCESS = "products/SAVE_DUPLICATE_SUCCESS",
  PRODUCTS_SAVE_DUPLICATE_FAILURE = "products/SAVE_DUPLICATE_FAILURE",

  PRODUCTS_CONFIGS_CREATE_NEW = "products/CONFIGS_CREATE_NEW",
  PRODUCTS_CONFIGS_OPTION_CREATE_NEW = "products/CONFIGS_OPTION_CREATE_NEW",
  PRODUCTS_CONFIGS_CHANGE_INFO = "products/CONFIGS_CHANGE_INFO",
  PRODUCTS_CONFIGS_REMOVE_REQUEST = "products/CONFIGS_REMOVE_REQUEST",
  PRODUCTS_CONFIGS_REMOVE_SUCCESS = "products/CONFIGS_REMOVE_SUCCESS",
  PRODUCTS_CONFIGS_REMOVE_FAILURE = "products/CONFIGS_REMOVE_FAILURE",
  PRODUCTS_CONFIGS_DISCART = "products/CONFIGS_DISCART",
  PRODUCTS_CONFIGS_DUPLICATE = "products/CONFIGS_DUPLICATE",
  PRODUCTS_CONFIGS_SAVE_REQUEST = "products/CONFIGS_SAVE_REQUEST",
  PRODUCTS_CONFIGS_SAVE_SUCCESS = "products/CONFIGS_SAVE_SUCCESS",
  PRODUCTS_CONFIGS_SAVE_FAILURE = "products/CONFIGS_SAVE_FAILURE",
  PRODUCTS_CONFIGS_OPTION_REMOVE_REQUEST = "products/CONFIGS_OPTION_REMOVE_REQUEST",
  PRODUCTS_CONFIGS_OPTION_REMOVE_SUCCESS = "products/CONFIGS_OPTION_REMOVE_SUCCESS",
  PRODUCTS_CONFIGS_OPTION_REMOVE_FAILURE = "products/CONFIGS_OPTION_REMOVE_FAILURE",
  PRODUCTS_CONFIGS_OPTION_DISCART = "products/CONFIGS_OPTION_DISCART",

  PRODUCTS_START_REARRANGING = "products/START_REARRANGING",
  PRODUCTS_STOP_REARRANGING = "products/STOP_REARRANGING",
  PRODUCTS_UPDATE_REARRANGING = "products/UPDATE_REARRANGING",
  PRODUCTS_REARRANGING_SAVE_REQUEST = "products/REARRANGING_SAVE_REQUEST",
  PRODUCTS_REARRANGING_SAVE_SUCCESS = "products/REARRANGING_SAVE_SUCCESS",
  PRODUCTS_REARRANGING_SAVE_FAILURE = "products/REARRANGING_SAVE_FAILURE"
}


export const actionProductsRequest = () => ({
  type: ProductsActions.PRODUCTS_REQUEST
})


export const actionProductsSuccess = (data: IProduct[]) => ({
  type: ProductsActions.PRODUCTS_SUCCESS,
  data
})

export const actionProductsFailure = (error: any) => ({
  type: ProductsActions.PRODUCTS_FAILURE,
  error
})


export const actionProductsSaveRequest = (autoClose: boolean = false) => ({
  type: ProductsActions.PRODUCTS_SAVE_REQUEST,
  autoClose
})


export const actionProductsSaveSuccess = (product: IProduct[]) => ({
  type: ProductsActions.PRODUCTS_SAVE_SUCCESS,
  product
})

export const actionProductsSaveFailure = (error: any) => ({
  type: ProductsActions.PRODUCTS_SAVE_FAILURE,
  error
})

export const actionProductCreateNew = (id_category: number) => ({
  type: ProductsActions.PRODUCTS_CREATE_NEW,
  id_category
})

export const actionProductChangeInfo = (product: IProductDraft) => ({
  type: ProductsActions.PRODUCTS_CHANGE_INFO,
  product
})

export const actionProductStartEditing = (product: IProductDraft | null) => ({
  type: ProductsActions.PRODUCTS_START_EDIT,
  product
})

export const actionProductStopEditing = () => ({
  type: ProductsActions.PRODUCTS_STOP_EDIT
})

export const actionProductEditingSetStep = (step: number) => ({
  type: ProductsActions.PRODUCTS_SET_STEP,
  step
})

// Remove product
export const actionProductRemoveRequest = (product_id: number) => ({
  type: ProductsActions.PRODUCTS_REMOVE_REQUEST,
  product_id
})

export const actionProductRemoveSuccess = (product_id: number) => ({
  type: ProductsActions.PRODUCTS_REMOVE_SUCCESS,
  product_id
})

export const actionProductRemoveFailure = (product_id: number, error: SystemErrors) => ({
  type: ProductsActions.PRODUCTS_REMOVE_FAILURE,
  product_id,
  error
})

// Remove product photo
export const actionProductRemovePhotoRequest = (product_id: number) => ({
  type: ProductsActions.PRODUCTS_REMOVE_PHOTO_REQUEST,
  product_id
})

export const actionProductRemovePhotoSuccess = (product_id: number) => ({
  type: ProductsActions.PRODUCTS_REMOVE_PHOTO_SUCCESS,
  product_id
})

export const actionProductRemovePhotoFailure = (product_id: number, error: SystemErrors) => ({
  type: ProductsActions.PRODUCTS_REMOVE_PHOTO_FAILURE,
  product_id,
  error
})

// Toggle product status
export const actionProductToggleStatusRequest = (product_id: number) => ({
  type: ProductsActions.PRODUCTS_TOGGLE_STAUTS_REQUEST,
  product_id
})

export const actionProductToggleStatusSuccess = (product_id: number) => ({
  type: ProductsActions.PRODUCTS_TOGGLE_STAUTS_SUCCESS,
  product_id
})

export const actionProductToggleStatusFailure = (product_id: number, error: SystemErrors) => ({
  type: ProductsActions.PRODUCTS_TOGGLE_STAUTS_FAILURE,
  product_id,
  error
})


// Configurations
export const actionProductConfigurationCreateNew = () => ({
  type: ProductsActions.PRODUCTS_CONFIGS_CREATE_NEW
})

export const actionProductConfigurationOptionCreateNew = (configuration_index: number) => ({
  type: ProductsActions.PRODUCTS_CONFIGS_OPTION_CREATE_NEW,
  configuration_index
})

export const actionProductConfigurationChange = (configuration: IProductConfiguration, configuration_index: number) => ({
  type: ProductsActions.PRODUCTS_CONFIGS_CHANGE_INFO,
  configuration,
  configuration_index
})

export const actionProductConfigurationRemoveRequest = (configuration_id: number, uuid?: string) => ({
  type: ProductsActions.PRODUCTS_CONFIGS_REMOVE_REQUEST,
  configuration_id,
  uuid
})

export const actionProductConfigurationRemoveSuccess = (configuration_id: number) => ({
  type: ProductsActions.PRODUCTS_CONFIGS_REMOVE_SUCCESS,
  configuration_id
})

export const actionProductConfigurationRemoveFailure = (uuid: string, error: SystemErrors) => ({
  type: ProductsActions.PRODUCTS_CONFIGS_REMOVE_FAILURE,
  uuid,
  error
})

export const actionProductConfigurationDiscart = (configuration_index: number) => ({
  type: ProductsActions.PRODUCTS_CONFIGS_DISCART,
  configuration_index
})

export const actionProductConfigurationDuplicate = (configuration_index: number) => ({
  type: ProductsActions.PRODUCTS_CONFIGS_DUPLICATE,
  configuration_index
})


export const actionProductConfigurationSaveRequest = (configuration: IProductConfiguration, configuration_index: number) => ({
  type: ProductsActions.PRODUCTS_CONFIGS_SAVE_REQUEST,
  configuration,
  configuration_index
})

export const actionProductConfigurationSaveSuccess = (configuration: IProductConfiguration, configuration_index: number) => ({
  type: ProductsActions.PRODUCTS_CONFIGS_SAVE_SUCCESS,
  configuration,
  configuration_index
})

export const actionProductConfigurationSaveFailure = (configuration_index: number, error: SystemErrors) => ({
  type: ProductsActions.PRODUCTS_CONFIGS_SAVE_FAILURE,
  configuration_index,
  error
})

export const actionProductConfigurationOptionDiscart = (configuration_index: number, option_index: number) => ({
  type: ProductsActions.PRODUCTS_CONFIGS_OPTION_DISCART,
  configuration_index,
  option_index
})


export const actionProductConfigurationOptionRemoveRequest = (configuration_index: number, option_id: number) => ({
  type: ProductsActions.PRODUCTS_CONFIGS_OPTION_REMOVE_REQUEST,
  configuration_index,
  option_id
})

export const actionProductConfigurationOptionRemoveSuccess = (configuration_index: number, option_id: number) => ({
  type: ProductsActions.PRODUCTS_CONFIGS_OPTION_REMOVE_SUCCESS,
  configuration_index,
  option_id
})

export const actionProductConfigurationOptionRemoveFailure = (configuration_index: number, option_id: number, error: SystemErrors) => ({
  type: ProductsActions.PRODUCTS_CONFIGS_OPTION_REMOVE_FAILURE,
  configuration_index,
  option_id,
  error
})

// Duplicate
export const actionProductStartDuplicate = (product: IProductDuplicate) => ({
  type: ProductsActions.PRODUCTS_START_DUPLICATE,
  product
})

export const actionProductStopDuplicate = () => ({
  type: ProductsActions.PRODUCTS_STOP_DUPLICATE
})

export const actionProductChangeDuplicate = (product: IProductDuplicate) => ({
  type: ProductsActions.PRODUCTS_CHANGE_DUPLICATE,
  product
})

// Remove product
export const actionProductSaveDuplicateRequest = (product: IProductDuplicate) => ({
  type: ProductsActions.PRODUCTS_SAVE_DUPLICATE_REQUEST,
  product
})

export const actionProductSaveDuplicateSuccess = (product: IProductDuplicateResponse) => ({
  type: ProductsActions.PRODUCTS_SAVE_DUPLICATE_SUCCESS,
  product
})

export const actionProductSaveDuplicateFailure = (error: SystemErrors) => ({
  type: ProductsActions.PRODUCTS_SAVE_DUPLICATE_FAILURE,
  error
})

// rearranging
export const actionProductConfigurationStartRearranging = () => ({
  type: ProductsActions.PRODUCTS_START_REARRANGING
})

export const actionProductConfigurationStopRearranging = () => ({
  type: ProductsActions.PRODUCTS_STOP_REARRANGING
})

export const actionProductConfigurationUpdateRearranging = (data: IProductConfigurationOrderMap[]) => ({
  type: ProductsActions.PRODUCTS_UPDATE_REARRANGING,
  data
})

export const actionProductConfigurationRearrangingSaveRequest = () => ({
  type: ProductsActions.PRODUCTS_REARRANGING_SAVE_REQUEST
})

export const actionProductConfigurationRearrangingSaveSuccess = (configurations: IProductConfiguration[]) => ({
  type: ProductsActions.PRODUCTS_REARRANGING_SAVE_SUCCESS,
  configurations
})

export const actionProductConfigurationRearrangingSaveFailure = (error: SystemErrors) => ({
  type: ProductsActions.PRODUCTS_REARRANGING_SAVE_FAILURE,
  error
})
