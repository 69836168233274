import { all, put, takeLatest } from "@redux-saga/core/effects";
import { AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { actionOpeningHoursDeleteFailure, actionOpeningHoursDeleteSuccess, actionOpeningHoursFailure, actionOpeningHoursSaveFailure, actionOpeningHoursSaveSuccess, actionOpeningHoursSuccess, actionOpeningHoursToggleStatusFailure, actionOpeningHoursToggleStatusSuccess } from "./actions";
import { IOpeningHoursDeleteResponse, IOpeningHoursResponse, IOpeningHoursSaveResponse, IOpeningHoursToggleStatusResponse, OpeningHoursTypes } from "./interfaces";
import { serviceOpeningHoursDeleteRequest, serviceOpeningHoursRequest, serviceOpeningHoursSaveRequest, serviceOpeningHoursToggleStatusRequest } from "./services";

// list request
function* openingHoursSaga() {
    try{
      const openingHoursResponse: AxiosResponse<IOpeningHoursResponse> = yield serviceOpeningHoursRequest();

      const response = openingHoursResponse.data;

      if(response.status){
        yield put(actionOpeningHoursSuccess(response.data));
      }
      else {
        yield put(actionOpeningHoursFailure({
          message: response?.message || "Erro inesperado ao buscar lista de horários de funcionamento",
        }))
      }

    }
    catch(error){
      yield put(actionOpeningHoursSaveFailure({
        message: error?.response?.data?.message || "Erro inesperado ao buscar lista de horários de funcionamento",
      }))
    }
}

function* watchOpeningHoursSaga() {
  yield takeLatest(OpeningHoursTypes.OPENING_HOURS_REQUEST, openingHoursSaga);
}

// toggle status request
function* openingHoursToggleStatusSaga(action: AnyAction) {
  try{
    const openingHoursToggleStatusResponse: AxiosResponse<IOpeningHoursToggleStatusResponse> = yield serviceOpeningHoursToggleStatusRequest(action.id);

    const response = openingHoursToggleStatusResponse.data;

    if(response.status){
      yield put(actionOpeningHoursToggleStatusSuccess(response.data));
    }
    else {
      yield put(actionOpeningHoursToggleStatusFailure(action.id, {
        message: response?.message || "Erro inesperado ao atuaizar status deste horário",
      }))
    }

  }
  catch(error){
    yield put(actionOpeningHoursToggleStatusFailure(action.id, {
      message: error?.response?.data?.message || "Erro inesperado ao atuaizar status deste horário",
    }))
  }
}

function* watchOpeningHoursToggleStatusSaga() {
yield takeLatest(OpeningHoursTypes.OPENING_HOURS_TOGGLING_STATUS_REQUEST, openingHoursToggleStatusSaga);
}

// save
function* openingHoursSaveRequestSaga(action : AnyAction) {
  try{
    const openingHoursSaveResponse: AxiosResponse<IOpeningHoursSaveResponse> = yield serviceOpeningHoursSaveRequest(action.draft);

    const response = openingHoursSaveResponse.data;

    if(response.status){
      yield put(actionOpeningHoursSaveSuccess(response.data));
    }
    else {
      yield put(actionOpeningHoursSaveFailure({
        message: response?.message || "Erro inesperado ao salvar horário de funcionamento",
      }))
    }

  }
  catch(error){
    yield put(actionOpeningHoursSaveFailure({
      message: error?.response?.data?.message || "Erro inesperado ao salvar horário de funcionamento",
    }))
  }
}

function* watchOpeningHoursSaveRequestSaga() {
yield takeLatest(OpeningHoursTypes.OPENING_HOURS_SAVE_REQUEST, openingHoursSaveRequestSaga);
}

// delete
function* openingHoursDeleteRequestSaga(action : AnyAction) {
  try{
    const openingHoursDeleteResponse: AxiosResponse<IOpeningHoursDeleteResponse> = yield serviceOpeningHoursDeleteRequest(action.id);

    const response = openingHoursDeleteResponse.data;

    if(response.status){
      yield put(actionOpeningHoursDeleteSuccess(action.id));
    }
    else {
      yield put(actionOpeningHoursDeleteFailure(action.id, {
        message: response?.message || "Erro inesperado ao remover horário de funcionamento",
      }))
    }

  }
  catch(error){
    yield put(actionOpeningHoursDeleteFailure(action.id, {
      message: error?.response?.data?.message || "Erro inesperado ao remover horário de funcionamento",
    }))
  }
}

function* watchOpeningHoursDeleteRequestSaga() {
  yield takeLatest(OpeningHoursTypes.OPENING_HOURS_DELETE_REQUEST, openingHoursDeleteRequestSaga);
}

export default all([
  watchOpeningHoursSaga(),
  watchOpeningHoursToggleStatusSaga(),
  watchOpeningHoursSaveRequestSaga(),
  watchOpeningHoursDeleteRequestSaga(),
])
