import api from './api';
import auth from './auth'

export default {
    list: () => 
        api.get('/orders', {
            headers: {Authorization: `Bearer ${auth.getToken()}`}
        })
        .catch(err => console.log(err)),
    
    create: (order) => {

    }
    
}