import { ISystemDialogButton } from "./interfaces"

export enum SystemActions {
  SYSTEM_CREATE_DIALOG = "system_create/DIALOG",
  SYSTEM_DIALOG_DISMISS = "system_dialog/DISMISS"
}


export const actionSystemCreateDialogConfirm = (title: string, message: string, buttons: ISystemDialogButton[], priority?:number) => ({
  type: SystemActions.SYSTEM_CREATE_DIALOG,
  title,
  message,
  buttons,
  priority
})

export const actionSystemDialogDismiss = (uuid: string) => ({
  type: SystemActions.SYSTEM_DIALOG_DISMISS,
  uuid
})
