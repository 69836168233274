import api from './api';
import auth from './auth'
import querystring from 'query-string'

export default {
    list: (filter = {}) => 
        api.get(`/users?${querystring.stringify(filter)}`, {
            headers: {Authorization: `Bearer ${auth.getToken()}`}
        }),    
    store: (user) => {
        if(user.id){
            return api.put('/users/store',{
                data: user
            }, {
                headers: {Authorization: `Bearer ${auth.getToken()}`}
            })
        }
        else{
            return api.post('/users/store',{
                data: user
            }, {
                headers: {Authorization: `Bearer ${auth.getToken()}`}
            })
        }
    },
    remove: (id) => {
        return api.delete(`/users/store/${id}`,{
            headers: {Authorization: `Bearer ${auth.getToken()}`}
        })
    }
}