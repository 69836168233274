import * as SettingsActions from '../actions/SettingsActions';

const SettingsReducer = (
    settings = {
        payments: {
            isLoading: false,
            isSaving: false,
            error: false,
            data: []
        },
        delivery: {
            isLoading: false,
            isSaving: false,
            error: false,
            data: []
        },
        opening: {
            isLoading: false,
            isSaving: false,
            error: false,
            data: []
        },
        self_service: {
            isLoading: false,
            isSaving: false,
            error: false,
            data: {}
        },
        appearence: {
            isLoading: false,
            isSaving: false,
            error: false,
            data: []
        },
        styles: {},
        printers: [],
        settings: {}
    },
    action
    ) => {
    switch(action.type){
        // Payments
        case SettingsActions.SETTINGS_PAYMENTS_LIST:
            return {
                ...settings,
                payments: {
                    ...settings.payments,
                    isLoading: true,
                    error: false
                }
            }
        case SettingsActions.SETTINGS_PAYMENTS_LIST_RESPONSE:
            return {
                ...settings,
                payments: {
                    isLoading: false,
                    error: false,
                    data: action.paymentsList.data
                }
            }
        case SettingsActions.SETTINGS_PAYMENTS_ERROR:
            return {
                ...settings,
                payments: {
                    ...settings.payments,
                    isSaving: false,
                    isLoading: false,
                    error: action.error !== undefined ? action.error : false,
                    data: null
                }
            }
        case SettingsActions.SETTINGS_PAYMENTS_STORE:
            return {
                ...settings,
                payments: {
                    ...settings.payments,
                    isSaving: true,
                    error: false
                }
            }
        case SettingsActions.SETTINGS_PAYMENTS_STORE_RESPONSE:
            return {
                ...settings,
                payments: {
                    ...settings.payments,
                    isSaving: false,
                    error: action.error !== undefined ? action.error : false
                }
            }
        // Delivery
        case SettingsActions.SETTINGS_DELIVERY:
            return {
                ...settings,
                delivery: {
                    ...settings.delivery,
                    isLoading: true,
                    error: false
                }
            }
        case SettingsActions.SETTINGS_DELIVERY_RESPONSE:
            return {
                ...settings,
                delivery: {
                    ...settings.delivery,
                    isSaving: false,
                    isLoading: false,
                    error: action.error !== undefined ? action.error : false,
                    data: action.delivery.data
                }
            }
        case SettingsActions.SETTINGS_DELIVERY_ERROR:
            return {
                ...settings,
                delivery: {
                    ...settings.delivery,
                    isSaving: false,
                    isLoading: false,
                    error: action.error !== undefined ? action.error : false,
                    data: null
                }
            }
        case SettingsActions.SETTINGS_DELIVERY_STORE:
            return {
                ...settings,
                delivery: {
                    ...settings.delivery,
                    isSaving: true,
                    error: false
                }
            }
        case SettingsActions.SETTINGS_DELIVERY_STORE_RESPONSE:
            return {
                ...settings,
                delivery: {
                    ...settings.delivery,
                    isSaving: false,
                    error: action.error !== undefined ? action.error : false
                }
        }

        // Opening
        case SettingsActions.SETTINGS_OPENING:
            return {
                ...settings,
                opening: {
                    ...settings.opening,
                    isLoading: true,
                    error: false
                }
            }
        case SettingsActions.SETTINGS_OPENING_RESPONSE:
            return {
                ...settings,
                opening: {
                    ...settings.opening,
                    isSaving: false,
                    isLoading: false,
                    error: action.error !== undefined ? action.error : false,
                    data: action.opening.data
                }
            }
        case SettingsActions.SETTINGS_OPENING_ERROR:
            return {
                ...settings,
                opening: {
                    ...settings.opening,
                    isSaving: false,
                    isLoading: false,
                    error: action.error !== undefined ? action.error : false,
                    data: null
                }
            }
        case SettingsActions.SETTINGS_OPENING_STORE:
            return {
                ...settings,
                opening: {
                    ...settings.opening,
                    isSaving: true,
                    error: false
                }
            }
        case SettingsActions.SETTINGS_OPENING_STORE_RESPONSE:
            return {
                ...settings,
                opening: {
                    ...settings.opening,
                    isSaving: false,
                    error: action.error !== undefined ? action.error : false
                }
        }

        // Self service
        case SettingsActions.SETTINGS_SELF_SERVICE:
            return {
                ...settings,
                self_service: {
                    ...settings.self_service,
                    isLoading: true,
                    error: false
                }
            }
        case SettingsActions.SETTINGS_SELF_SERVICE_RESPONSE:
            return {
                ...settings,
                self_service: {
                    ...settings.self_service,
                    isSaving: false,
                    isLoading: false,
                    error: action.error !== undefined ? action.error : false,
                    data: action.self_service.data
                }
            }
        case SettingsActions.SETTINGS_SELF_SERVICE_ERROR:
            return {
                ...settings,
                self_service: {
                    ...settings.self_service,
                    isSaving: false,
                    isLoading: false,
                    error: action.error !== undefined ? action.error : false
                }
            }
        case SettingsActions.SETTINGS_SELF_SERVICE_STORE:
            return {
                ...settings,
                self_service: {
                    ...settings.self_service,
                    isSaving: true,
                    error: false
                }
            }
        case SettingsActions.SETTINGS_SELF_SERVICE_STORE_RESPONSE:
            let tmp_data = {
                enable_table: action.self_service.enable_table || false,
                enable_delivery: action.self_service.enable_delivery || false,
                enable_take_away: action.self_service.enable_take_away || false,
                wait_time_table: action.self_service.wait_time_table || 0,
                wait_time_delivery: action.self_service.wait_time_delivery || 0,
                wait_time_take_away: action.self_service.wait_time_take_away || 0,
                observations_feature: action.self_service.observations_feature || false,
                delivered_feature: action.self_service.delivered_feature || false,
                finished_feature: action.self_service.finished_feature || false
            }

            console.log('action reducer', action.self_service)
            return {
                ...settings,
                self_service: {
                    ...settings.self_service,
                    isSaving: false,
                    data: {
                        ...tmp_data
                    }
                }
        }

        // Styles
        case SettingsActions.SETTINGS_STYLES_LIST_RESPONSE:
            return {
                ...settings,
                styles: action.styles.data
            }
        // Settings
        case SettingsActions.SETTINGS_LIST_RESPONSE:
            return {
                ...settings,
                settings: action.settings.data
            }
        default: return settings;
    }
}

export default SettingsReducer;
