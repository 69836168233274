import * as CallbacksActions from '../actions/CallbacksActions'

const Callbacks = (callbacks = [], action) => {
    switch(action.type){
        case CallbacksActions.ON_SUCCESS:
            return [
                ...callbacks,
                action.callback
            ];
        case CallbacksActions.ADD_CALLBACK:
            return [
                ...callbacks,
                action.callback
            ];
        case CallbacksActions.ON_CALLBACK:
            return [
                ...callbacks,
                action.callback
            ];

        case CallbacksActions.UPDATE_CALLBACK:
            let newCbkUpd = callbacks.map(cbk => {
                if(cbk.id !== action.callback.id) return cbk;
                else{
                    return action.callback
                }
            })
            return [
                ...newCbkUpd,
                action.callback
            ];    
        case CallbacksActions.REMOVE_CALLBACK:
            let newCbkRm = callbacks.filter(cbk => cbk.id !== action.id)
            return [
                ...newCbkRm
            ];

        default: return callbacks;
    }
}

export default Callbacks;