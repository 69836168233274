import { put, takeLatest, all, takeEvery } from "@redux-saga/core/effects";
import { AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { actionDeleteDeliveryCitiesFailure, actionDeleteDeliveryCitiesSuccess, actionDeleteDeliveryNeighborhoodsFailure, actionDeleteDeliveryNeighborhoodsSuccess, actionListDeliveryCitiesFailure, actionListDeliveryCitiesSuccess, actionListDeliveryNeighborhoodsFailure, actionListDeliveryNeighborhoodsSuccess, actionSaveDeliveryCitiesFailure, actionSaveDeliveryCitiesSuccess, actionSaveDeliveryNeighborhoodsFailure, actionSaveDeliveryNeighborhoodsSuccess, actionToggleStatusDeliveryCitiesFailure, actionToggleStatusDeliveryCitiesSuccess } from "./actions";
import { DeliveryTypes, IDeliveryCitiesDeleteResponse, IDeliveryCitiesListResponse, IDeliveryCitiesSaveResponse, IDeliveryCitiesToggleStatusResponse, IDeliveryNeighborhoodsDeleteResponse, IDeliveryNeighborhoodsListResponse, IDeliveryNeighborhoodsSaveResponse } from "./interfaces";
import { serviceDeliveryCitiesDeleteRequest, serviceDeliveryCitiesListRequest, serviceDeliveryCitiesSaveRequest, serviceDeliveryCitiesToggleStatusRequest, serviceDeliveryNeighborhoodsDeleteRequest, serviceDeliveryNeighborhoodsListRequest, serviceDeliveryNeighborhoodsSaveRequest } from "./services";

// list cities
function* sagaDeliveryCitiesRequest() {
  try{
    const openingHoursResponse: AxiosResponse<IDeliveryCitiesListResponse> = yield serviceDeliveryCitiesListRequest();

    const response = openingHoursResponse.data;

    if(response.status && response.data){
      console.log('responser', response.data);

      yield put(actionListDeliveryCitiesSuccess(response.data));
    }
    else {
      yield put(actionListDeliveryCitiesFailure({
        message: response?.message || "Erro inesperado ao buscar lista de cidades de entrega",
      }))
    }

  }
  catch(error: any){
    yield put(actionListDeliveryCitiesFailure({
      message: error?.response?.data?.message || "Erro inesperado ao buscar lista de cidades de entrega",
    }))
  }
}

function* watchSagaDeliveryCitiesRequest() {
  yield takeLatest(DeliveryTypes.DELIVERY_CITIES_REQUEST, sagaDeliveryCitiesRequest);
}

// save delivery city
function* sagaDeliveryCitiesSaveRequest(action: AnyAction) {
  try{
    const cityResponse: AxiosResponse<IDeliveryCitiesSaveResponse> = yield serviceDeliveryCitiesSaveRequest(action.city);

    const response = cityResponse.data;

    if(response.status){
      yield put(actionSaveDeliveryCitiesSuccess(response.data));
    }
    else {
      yield put(actionSaveDeliveryCitiesFailure({
        message: response.message || "Erro inesperado ao salvar cidade de entrega",
      }))
    }
  }
  catch(error: any){
    console.error(error);

    yield put(actionSaveDeliveryCitiesFailure({
      message: error?.response?.data?.message || "Erro inesperado ao salvar cidade de entrega",
    }))
  }
}

function* watchSagaDeliveryCitiesSaveRequest() {
  yield takeLatest(DeliveryTypes.DELIVERY_CITIES_SAVE_REQUEST, sagaDeliveryCitiesSaveRequest);
}

// delete city
function* sagaDeliveryCitiesDeleteRequest(action: AnyAction) {
  try{
    const citiesDeleteResponse: AxiosResponse<IDeliveryCitiesDeleteResponse> = yield serviceDeliveryCitiesDeleteRequest(action.idCity);

    const response = citiesDeleteResponse.data;

    if(response.status){
      yield put(actionDeleteDeliveryCitiesSuccess(action.idCity));
    }
    else {
      yield put(actionDeleteDeliveryCitiesFailure(action.idCity, {
        message: response?.message || "Erro inesperado ao remover esta cidade",
      }))
    }
  }
  catch(error: any){
    yield put(actionDeleteDeliveryCitiesFailure(action.idCity, {
      message: error?.response?.data?.message || "Erro inesperado ao remover esta cidade",
    }))
  }
}

function* watchSagaDeliveryCitiesDeleteRequest() {
  yield takeEvery(DeliveryTypes.DELIVERY_CITIES_DELETE_REQUEST, sagaDeliveryCitiesDeleteRequest);
}

// toggle city status
function* sagaDeliveryCitiesToggleStatusRequest(action: AnyAction) {
  try{
    const cityResponse: AxiosResponse<IDeliveryCitiesToggleStatusResponse> = yield serviceDeliveryCitiesToggleStatusRequest(action.idCity);

    const response = cityResponse.data;

    if(response.status){
      yield put(actionToggleStatusDeliveryCitiesSuccess(action.idCity, response.data.status));
    }
    else {
      yield put(actionToggleStatusDeliveryCitiesFailure(action.idCity, {
        message: response.message || "Erro inesperado ao alterar status da cidade de entrega",
      }))
    }
  }
  catch(error: any){
    yield put(actionToggleStatusDeliveryCitiesFailure(action.idCity, {
      message: error?.response?.data?.message || "Erro inesperado ao alterar status desta cidade de entrega",
    }))
  }
}

function* watchSagaDeliveryCitiesToggleStatusRequest() {
  yield takeEvery(DeliveryTypes.DELIVERY_CITIES_TOGGLING_STATUS_REQUEST, sagaDeliveryCitiesToggleStatusRequest);
}

// list neighborhoods
function* sagaDeliveryNeighborhoodsRequest(action: AnyAction) {
  try{
    const neighborhoodsResponse: AxiosResponse<IDeliveryNeighborhoodsListResponse> = yield serviceDeliveryNeighborhoodsListRequest(action.idCity);

    const response = neighborhoodsResponse.data;

    if(response.status && response.data){
      yield put(actionListDeliveryNeighborhoodsSuccess(action.idCity, response.data));
    }
    else {
      yield put(actionListDeliveryNeighborhoodsFailure(action.idCity, {
        message: response?.message || "Erro inesperado ao buscar lista de bairros de entrega",
      }))
    }

  }
  catch(error: any){
    yield put(actionListDeliveryNeighborhoodsFailure(action.idCity, {
      message: error?.response?.data?.message || "Erro inesperado ao buscar lista de bairros de entrega",
    }))
  }
}

function* watchSagaDeliveryNeighborhoodsRequest() {
  yield takeEvery(DeliveryTypes.DELIVERY_NEIGHBORHOODS_REQUEST, sagaDeliveryNeighborhoodsRequest);
}

// delete neighborhood
function* sagaDeliveryNeighborhoodsDeleteRequest(action: AnyAction) {
  try{
    const neighborhoodsResponse: AxiosResponse<IDeliveryNeighborhoodsDeleteResponse> = yield serviceDeliveryNeighborhoodsDeleteRequest(action.idNeighborhood);

    const response = neighborhoodsResponse.data;

    if(response.status && response.data){
      console.log(response.data);

      yield put(actionDeleteDeliveryNeighborhoodsSuccess(action.idCity, action.idNeighborhood));
    }
    else {
      yield put(actionDeleteDeliveryNeighborhoodsFailure(action.idCity, action.idNeighborhood, {
        message: response?.message || "Erro inesperado ao remover este bairro de entrega",
      }))
    }
  }
  catch(error: any){
    yield put(actionDeleteDeliveryNeighborhoodsFailure(action.idCity, action.idNeighborhood, {
      message: error?.response?.data?.message || "Erro inesperado ao remover este bairro de entrega",
    }))
  }
}

function* watchSagaDeliveryNeighborhoodsDeleteRequest() {
  yield takeEvery(DeliveryTypes.DELIVERY_NEIGHBORHOODS_DELETE_REQUEST, sagaDeliveryNeighborhoodsDeleteRequest);
}

// save neighborhood
function* sagaDeliveryNeighborhoodsSaveRequest(action: AnyAction) {
  try{
    const neighborhoodsResponse: AxiosResponse<IDeliveryNeighborhoodsSaveResponse> = yield serviceDeliveryNeighborhoodsSaveRequest(action.idCity, action.neighborhoods);

    const response = neighborhoodsResponse.data;

    if(response.status){
      const neighborhoods = response.data.map(neighborhood => ({
        ...neighborhood,
        id_city: action.idCity,
      }))

      yield put(actionSaveDeliveryNeighborhoodsSuccess(action.idCity, neighborhoods));
    }
    else {
      yield put(actionSaveDeliveryNeighborhoodsFailure(action.idCity, {
        message: response.message || "Erro inesperado ao salvar lista de bairros",
      }))
    }
  }
  catch(error: any){
    yield put(actionSaveDeliveryNeighborhoodsFailure(action.idCity, {
      message: error?.response?.data?.message || "Erro inesperado ao salvar lista de bairros",
    }))
  }
}

function* watchSagaDeliveryNeighborhoodsSaveRequest() {
  yield takeEvery(DeliveryTypes.DELIVERY_NEIGHBORHOODS_SAVE_REQUEST, sagaDeliveryNeighborhoodsSaveRequest);
}

export default all([
  watchSagaDeliveryCitiesRequest(),
  watchSagaDeliveryNeighborhoodsRequest(),
  watchSagaDeliveryNeighborhoodsDeleteRequest(),
  watchSagaDeliveryCitiesToggleStatusRequest(),
  watchSagaDeliveryCitiesSaveRequest(),
  watchSagaDeliveryCitiesDeleteRequest(),
  watchSagaDeliveryNeighborhoodsSaveRequest()
])
