import { SystemErrors } from "../..";

export enum PaymentMethodsType {
  PAYMENT_METHODS_REQUEST = "paymentMethods/REQUEST",
  PAYMENT_METHODS_SUCCESS = "paymentMethods/SUCCESS",
  PAYMENT_METHODS_FAILURE = "paymentMethods/FAILURE",

  PAYMENT_METHOD_TOGGLE_STATUS_REQUEST = "paymentMethod/TOGGLE_STATUS_REQUEST",
  PAYMENT_METHOD_TOGGLE_STATUS_SUCCESS = "paymentMethod/TOGGLE_STATUS_SUCCESS",
  PAYMENT_METHOD_TOGGLE_STATUS_FAILURE = "paymentMethod/TOGGLE_STATUS_FAILURE",
}

export interface IPaymentMethodsState {
  data: IPaymentMethod[];
  isLoading: boolean;
  error?: SystemErrors;
}

export interface IPaymentMethod {
  id: string;
  isActive: boolean;
  name: string;
  type: 0 | 10 | 20 | 30;
  moneyChange: boolean;
  icon: string;
  isTogglingStatus?: boolean;
  error?: SystemErrors;
}

export type IPaymentMethodResponse = {
  status: true;
  data: {
    id: string;
    name: string;
    type: 0 | 10 | 20 | 30;
    status: boolean;
    money_change: boolean;
    icon: string;
    date_create: string;
    date_update: string;
    is_active: boolean;
  }[]
} | {
  status: false;
  message: string;
}


export type IPaymentMethodToggleStatusResponse = {
  status: true;
  data: {
    id: string;
    name: string;
    type: 0 | 10 | 20 | 30;
    status: boolean;
    money_change: boolean;
    icon: string;
    date_create: string;
    date_update: string;
    is_active: boolean;
  }
} | {
  status: false;
  message: string;
}
