export const ORDER_LIST                 = "ORDER_LIST";
export const ORDER_LIST_RESPONSE        = "ORDER_LIST_RESPONSE";
export const ORDER_CREATE               = "ORDER_CREATE";

export const list = () => {
    return {
        type: ORDER_LIST
    }
}

export const listResponse = (orderList) => {
    return {
        type: ORDER_LIST_RESPONSE,
        orderList
    }
}

export const create = (order) => {
    return {
        type: ORDER_CREATE,
        order
    }
}