export const USERS_LIST                 = "USERS_LIST";
export const USERS_LIST_RESPONSE        = "USERS_LIST_RESPONSE";
export const USERS_STORE                = "USERS_STORE";
export const USERS_REMOVE               = "USERS_REMOVE";
export const USERS_NAVIGATION           = "USER_NAVIGATION";

export const list = (filter = {}) => {
    return {
        type: USERS_LIST,
        filter
    }
}

export const listResponse = (usersList) => {
    return {
        type: USERS_LIST_RESPONSE,
        usersList
    }
}

export const store = (user, callback = null) => {
    return {
        type: USERS_STORE,
        user,
        callback
    }
}

export const remove = (id) => {
    return {
        type: USERS_REMOVE,
        id
    }
}

export const navigation = () => {
    return {
        type: USERS_NAVIGATION
    }
}