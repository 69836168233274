import {toastr} from 'react-redux-toastr'

import * as SettingsActions from '../actions/SettingsActions';
import {SettingsService} from '../../services/SettingsService';

import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';

//SETTINGS
function* settings(action){
    let data = [];
    yield SettingsService.getSettings().then(
        result => {
            data = result.data
        }
    )
    .catch(err => {
        if(err.response && err.response.data){
            let data = err.response.data;

            if(action.onError){
                if(data.msg) {
                    action.onError(data.msg)
                }
                else action.onError('Não foi posível carregar configurações');
            }
        }
    });
    
    yield put(SettingsActions.settingsResponse(data))
}
function* watchSettings(){
    yield takeLatest(SettingsActions.SETTINGS_LIST, settings);
}

function* setSettings(action){
    // let data = [];
    yield SettingsService.setSettings(action.settings).then(
        result => {
            // data = result.data
        }
    )
    .catch(err => {
        if(err.response && err.response.data){
            let data = err.response.data;

            if(action.onError){
                if(data.msg) {
                    action.onError(data.msg)
                }
                else action.onError('Não foi posível salvar configurações');
            }
        }
    });
}
function* watchSetSettings(){
    yield takeEvery(SettingsActions.SETTINGS_STORE, setSettings);
}

//PAYMENTS
function* listPayments(action){
    let data = null;
    let error = null;
    yield SettingsService.paymentsList().then(
        result => {
            data = result.data
        }
    )
    .catch(err => {
        error = err.response.data || err.response;
    });
    
    if(data) yield put(SettingsActions.paymentsListResponse(data))
    if(error) yield put(SettingsActions.paymentsError(error))
}
function* watchListPayments(){
    yield takeLatest(SettingsActions.SETTINGS_PAYMENTS_LIST, listPayments);
}

//PAYMENTS STORE
function* paymentStore(action){
    let data = null;
    let error = null;
    yield SettingsService.paymentStore(action.payment).then(
        result => {
            error = false;
            data = result.data
        }
    )
    .catch(err => {
        error = err.response.data || err.response;
    });
    
    if(data) yield put(SettingsActions.paymentStoreResponse(data))
    if(error) yield put(SettingsActions.paymentsError(error)) 
}
function* watchPaymentStore(){
    yield takeLatest(SettingsActions.SETTINGS_PAYMENTS_STORE, paymentStore);
}

// STYLES
function* getStyles(action){
    let data = null;
    let error = null;
    yield SettingsService.getStyles().then(
        result => {
            data = result.data
        }
    )
    .catch(err => {
        error = err.response.data || err.response;
    });
    
    if(data) yield put(SettingsActions.stylesResponse(data))
    if(error) yield put(SettingsActions.stylesError(error)) 
}
function* watchGetStyles(){
    yield takeLatest(SettingsActions.SETTINGS_STYLES_LIST, getStyles);
}

function* setStyles(action){
    // let data = [];
    yield SettingsService.setStyles(action.styles).then(
        result => {
            // data = result.data
        }
    )
    .catch(err => {
        if(err.response && err.response.data){
            let data = err.response.data;

            if(action.onError){
                if(data.msg) {
                    action.onError(data.msg)
                }
                else action.onError('Não foi posível salvar personalização');
            }
        }
    });
}
function* watchSetStyles(){
    yield takeEvery(SettingsActions.SETTINGS_STYLES_STORE, setStyles);
}

// Delivery
function* getDeliveryCharges(action){
    let data = null;
    let error = null
    yield SettingsService.getDeliveryCharges().then(
        result => {
            data = result.data
        }
    )
    .catch(err => {
        error = err.response.data || err.response;
    });
    
    if(data) yield put(SettingsActions.getDeliveryChargesResponse(data))
    if(error) yield put(SettingsActions.getDeliveryChargesError(error))
}
function* watchGetDeliveryCharges(){
    yield takeLatest(SettingsActions.SETTINGS_DELIVERY, getDeliveryCharges);
}

function* deliveryStore(action){
    let data = null;
    let error = false;
    yield SettingsService.deliveryChargesStore(action.delivery).then(
        result => {
            data = result.data
        }
    )
    .catch(err => {
        error= true;
        if(err.response && err.response.data){
            let data = err.response.data;

            if(data.msg) {
                toastr.error(data.msg)
            }
        }
    });
    
    yield put(SettingsActions.deliveryStoreResponse(data, error))
}
function* watchDeliveryStore(){
    yield takeLatest(SettingsActions.SETTINGS_DELIVERY_STORE, deliveryStore);
}

// Opening
function* getOpening(action){
    let data = null;
    let error = null
    yield SettingsService.getOpening().then(
        result => {
            data = result.data
        }
    )
    .catch(err => {
        error = err.response.data || err.response;
    });
    
    if(data) yield put(SettingsActions.getOpeningHoursResponse(data))
    if(error) yield put(SettingsActions.getOpeningHoursError(error))
}
function* watchGetOpening(){
    yield takeLatest(SettingsActions.SETTINGS_OPENING, getOpening);
}

function* openingStore(action){
    let data = null;
    let error = null;
    yield SettingsService.openingStore(action.opening).then(
        result => {
            data = result.data
        }
    )
    .catch(err => {
        if(err.response && err.response.data){
            error = err.response.data;

            if(data.msg) {
                toastr.error(data.msg)
            }
        }
        else error = err.response;
    });
    
    if(data) yield put(SettingsActions.openingStoreResponse(data))
    if(error) yield put(SettingsActions.openingError(error))
}
function* watchOpenignStore(){
    yield takeLatest(SettingsActions.SETTINGS_OPENING_STORE, openingStore);
}

// SelfService
function* getSelfService(action){
    let data = null;
    let error = null
    yield SettingsService.getSelfService().then(
        result => {
            data = result.data
        }
    )
    .catch(err => {
        error = err.response.data || err.response;
    });
    
    if(data) yield put(SettingsActions.getSelfServiceResponse(data))
    if(error) yield put(SettingsActions.getSelfServiceError(error))
}
function* watchGetSelfService(){
    yield takeLatest(SettingsActions.SETTINGS_SELF_SERVICE, getSelfService);
}

function* selfServiceStore(action){
    let data = null;
    let error = null;
    yield SettingsService.selfServiceStore(action.self_service).then(
        result => {
            data = result.data

            if(data.status){
                data = data.data;
            }
            else{
                error = data;
                data = null;
            }
        }
    )
    .catch(err => {
        if(err.response && err.response.data){
            error = err.response.data;

            if(data.msg) {
                toastr.error(data.msg)
            }
        }
        else error = err.response;
    });
    
    if(data !== null) yield put(SettingsActions.selfServiceStoreResponse(data))
    if(error !== null) yield put(SettingsActions.selfServiceError(error))
}
function* watchSeldServiceStore(){
    yield takeLatest(SettingsActions.SETTINGS_SELF_SERVICE_STORE, selfServiceStore);
}

export default function* SettingsSaga(){
    yield all([
        watchListPayments(),
        watchPaymentStore(),
        watchGetStyles(),
        watchSettings(),
        watchSetSettings(),
        watchSetStyles(),
        watchGetDeliveryCharges(),
        watchDeliveryStore(),
        watchGetOpening(),
        watchOpenignStore(),
        watchGetSelfService(),
        watchSeldServiceStore()
    ])
}