import { SystemErrors } from "../..";

export enum AppearanceTypes {
  APPEARANCE_REQUEST                 = "appearance/REQUEST",
  APPEARANCE_SUCCESS                 = "appearance/SUCCESS",
  APPEARANCE_FAILURE                 = "appearance/FAILURE",

  APPEARANCE_SAVE_REQUEST            = "appearance/SAVE_REQUEST",
  APPEARANCE_SAVE_SUCCESS            = "appearance/SAVE_SUCCESS",
  APPEARANCE_SAVE_FAILURE            = "appearance/SAVE_FAILURE",

  APPEARANCE_START_UPDATE_LOGO       = "appearance/START_UPDATE_LOGO",
  APPEARANCE_STOP_UPDATE_LOGO        = "appearance/STOP_UPDATE_LOGO",

  APPEARANCE_SAVE_LOGO_REQUEST       = "appearance/SAVE_LOGO_REQUEST",
  APPEARANCE_SAVE_LOGO_SUCCESS       = "appearance/SAVE_LOGO_SUCCESS",
  APPEARANCE_SAVE_LOGO_FAILURE       = "appearance/SAVE_LOGO_FAILURE",

  APPEARANCE_REMOVE_LOGO_REQUEST     = "appearance/REMOVE_LOGO_REQUEST",
  APPEARANCE_REMOVE_LOGO_SUCCESS     = "appearance/REMOVE_LOGO_SUCCESS",
  APPEARANCE_REMOVE_LOGO_FAILURE     = "appearance/REMOVE_LOGO_FAILURE",

  APPEARANCE_SET_TEMP_LOGO           = "appearance/SET_TEMP_LOGO",
}

export interface IAppearanceState {
  data: IAppearance;
  isLoading?: boolean;
  isSaving?: boolean;
  isSavingLogo?: boolean;
  isDeletingLogo?: boolean;
  isUpdatingLogo?: boolean;
  error?: SystemErrors;
  logoError?: SystemErrors;
  saveError?: SystemErrors;
}

export interface IAppearance {
  backgroundColor: string;
  primaryColor: string;
  primaryTextColor: string;
  secondaryTextColor: string;
  accentColor: string;
  accentTextColor: string;
  logo?: string;
  tmpLogo?: string;
}

export type IAppearenceResponse = {
  status: true,
  data: IAppearance
} | {
  status: false,
  message: string;
}

export type IAppearenceSaveResponse = {
  status: true,
  data: IAppearance
} | {
  status: false,
  message: string;
}

export type IAppearenceRemoveLogoResponse = {
  status: true
} | {
  status: false,
  message: string;
}

export type IAppearenceSaveLogoResponse = {
  status: true
} | {
  status: false,
  message: string;
}
