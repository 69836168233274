import { AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { all, put, takeLatest } from "redux-saga/effects";
import { actionGetLinkSettingsFailure, actionGetLinkSettingsSuccess, actionSaveFeaturesFailure, actionSaveFeaturesSuccess, actionSaveOrderTypesFailure, actionSaveOrderTypesSuccess, actionSaveSlugFailure, actionSaveSlugSuccess, actionValidateSlugFailure, actionValidateSlugSuccess } from "./actions";
import { ILinkSettingsResponse, ISaveFeaturesResponse, ISaveOrderTypesResponse, ISaveSlugResponse, IValiditySlugResponse, LinkTypes } from "./interfaces";
import { serviceLinkCheckSlugAvailabilityRequest, serviceLinkSaveFeaturesRequest, serviceLinkSaveOrderTypesRequest, serviceLinkSaveSlugRequest, serviceLinkSettingsRequest } from "./services";

// get link settings
function* sagaGetLinkSettingsRequest() {
  try{
    const linkSettingsResponse: AxiosResponse<ILinkSettingsResponse> = yield serviceLinkSettingsRequest();

    const response = linkSettingsResponse.data;

    if(response.status){
      yield put(actionGetLinkSettingsSuccess(response.data));
    }
    else {
      yield put(actionGetLinkSettingsFailure({
        message: response.message || "Erro inesperado ao buscar configurações do link de pedidos",
      }))
    }

  }
  catch(error: any){
    yield put(actionGetLinkSettingsFailure({
      message: error?.response?.data?.message || "Erro inesperado ao buscar configurações do link de pedidos",
    }))
  }
}

function* watchSagaGetLinkSettingsRequest() {
  yield takeLatest(LinkTypes.LINK_GET_SETTINGS_REQUEST, sagaGetLinkSettingsRequest);
}

// slug availability
function* sagaSlugAvailabilityRequestRequest(action: AnyAction) {
  try{
    const slugAvailabilityResponse: AxiosResponse<IValiditySlugResponse> = yield serviceLinkCheckSlugAvailabilityRequest(action.slug);

    const response = slugAvailabilityResponse.data;

    if(response.status){
      yield put(actionValidateSlugSuccess());
    }
    else {
      yield put(actionValidateSlugFailure({
        message: response.message || "Erro inesperado ao buscar configurações do link de pedidos",
      }))
    }

  }
  catch(error: any){
    yield put(actionValidateSlugFailure({
      message: error?.response?.data?.message || "Erro inesperado ao buscar configurações do link de pedidos",
    }))
  }
}

function* watchSagaSlugAvailabilityRequestRequest() {
  yield takeLatest(LinkTypes.LINK_VALIDITY_SLUG_REQUEST, sagaSlugAvailabilityRequestRequest);
}

// save slug
function* sagaSaveSlugRequest(action: AnyAction) {
  try{
    const slugSaveResponse: AxiosResponse<ISaveSlugResponse> = yield serviceLinkSaveSlugRequest(action.slug);

    const response = slugSaveResponse.data;

    if(response.status){
      yield put(actionSaveSlugSuccess(action.slug));
    }
    else {
      yield put(actionSaveSlugFailure({
        message: response.message || "Erro inesperado ao salvar link personalizado",
      }))
    }

  }
  catch(error: any){
    yield put(actionSaveSlugFailure({
      message: error?.response?.data?.message || "Erro inesperado ao salvar link personalizado",
    }))
  }
}

function* watchSaveSlugRequest() {
  yield takeLatest(LinkTypes.LINK_SAVE_SLUG_REQUEST, sagaSaveSlugRequest);
}

// save features
function* sagaSaveFeaturesRequest(action: AnyAction) {
  try{
    const featuresSaveResponse: AxiosResponse<ISaveFeaturesResponse> = yield serviceLinkSaveFeaturesRequest(action.features);

    const response = featuresSaveResponse.data;

    if(response.status){
      yield put(actionSaveFeaturesSuccess(response.data));
    }
    else {
      yield put(actionSaveFeaturesFailure({
        message: response.message || "Erro inesperado ao salvar recursos",
      }))
    }

  }
  catch(error: any){
    yield put(actionSaveFeaturesFailure({
      message: error?.response?.data?.message || "Erro inesperado ao salvar recursos",
    }))
  }
}

function* watchSaveFeaturesRequest() {
  yield takeLatest(LinkTypes.LINK_SAVE_FEATURES_REQUEST, sagaSaveFeaturesRequest);
}

// save orders types
function* sagaSaveOrdersTypesRequest(action: AnyAction) {
  try{
    const ordersTypesSaveResponse: AxiosResponse<ISaveOrderTypesResponse> = yield serviceLinkSaveOrderTypesRequest(action.orderTypes);

    const response = ordersTypesSaveResponse.data;

    if(response.status){
      yield put(actionSaveOrderTypesSuccess(response.data));
    }
    else {
      yield put(actionSaveOrderTypesFailure({
        message: response.message || "Erro inesperado ao salvar tipos de pedidos",
      }))
    }

  }
  catch(error: any){
    yield put(actionSaveOrderTypesFailure({
      message: error?.response?.data?.message || "Erro inesperado ao salvar tipos de pedidos",
    }))
  }
}

function* watchSaveOrdersTypesRequest() {
  yield takeLatest(LinkTypes.LINK_SAVE_ORDER_TYPES_REQUEST, sagaSaveOrdersTypesRequest);
}

export default all([
  watchSagaGetLinkSettingsRequest(),
  watchSagaSlugAvailabilityRequestRequest(),
  watchSaveSlugRequest(),
  watchSaveFeaturesRequest(),
  watchSaveOrdersTypesRequest()
])
