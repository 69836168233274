import { Reducer } from 'redux'
import { ISystemDialog, ISystemState} from './interfaces'
import { SystemActions } from './actions'
import { v4 as uuidv4 } from 'uuid'

export const SYSTEM_INITIAL_STATE: ISystemState = {
  dialogs: [],
  isLoading: false,
  error: false
}

const reducer: Reducer<ISystemState> = (state = SYSTEM_INITIAL_STATE, action) => {
  switch(action.type) {
    case SystemActions.SYSTEM_CREATE_DIALOG:
      const new_dialog: ISystemDialog = {
        message: action.message,
        title: action.title,
        buttons: action.buttons,
        priority: action.priority || 0,
        uuid: uuidv4()
      }
      return {
        ...state,
        dialogs: [...state.dialogs, new_dialog]
      }

    case SystemActions.SYSTEM_DIALOG_DISMISS:
      return {
        ...state,
        dialogs: state.dialogs.filter(dialog => dialog.uuid !== action.uuid)
      }

    default:
      return state;
  }
}

export default reducer;
